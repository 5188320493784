// src/hooks/useFetchDeals.js

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Custom hook to fetch deals based on the account ID.
 * @param {string} accountID - The account ID to fetch deals for.
 * @param {number} selectedYear - The selected year to filter deals.
 * @param {number} selectedQuarter - The selected quarter to filter deals (optional).
 * @returns {Object} - Contains deals data, loading state, and error state.
 */
export function useFetchDeals(accountID, selectedYear, selectedQuarter) {
  const { getAccessToken } = useAuthData();

  const fetchDeals = async () => {
    if (!accountID) {
      console.log("Missing required accountID");
      return [];
    }

    const accessToken = await getAccessToken();
    const target =
      process.env.REACT_APP_API_BASE_URL ||
      "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

    try {
      console.log("Fetching deals with accountID:", accountID);

      // Calculate start and end dates based on year and quarter
      let startDate, endDate;
      if (selectedYear) {
        if (selectedQuarter) {
          // For specific quarter
          const quarterStartMonth = (selectedQuarter - 1) * 3;
          startDate = `${selectedYear}-${String(quarterStartMonth + 1).padStart(2, '0')}-01`;
          endDate = `${selectedYear}-${String(quarterStartMonth + 3).padStart(2, '0')}-${quarterStartMonth + 3 === 12 ? '31' : '30'}`;
        } else {
          // For entire year
          startDate = `${selectedYear}-01-01`;
          endDate = `${selectedYear}-12-31`;
        }
      }

      const response = await axios.get(
        `${target}/accounts/${accountID}/deals`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        }
      );

      console.log("API Response:", {
        status: response.status,
        dataLength: response.data?.length,
        firstFewDeals: response.data?.slice(0, 2),
      });

      // Ensure we always return an array
      const deals = response.data;
      if (!deals) {
        console.warn("No deals data returned from API");
        return [];
      }
      if (!Array.isArray(deals)) {
        console.error("API returned non-array deals data:", deals);
        return [];
      }

      // Log some stats about the deals
      const wonDeals = deals.filter((d) => d.is_won).length;
      const lostDeals = deals.filter((d) => !d.is_won).length;
      console.log("Deals stats:", {
        total: deals.length,
        won: wonDeals,
        lost: lostDeals,
      });

      return deals;
    } catch (error) {
      console.error("Error fetching deals:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
      return [];
    }
  };

  const {
    data: deals = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["deals", accountID, selectedYear, selectedQuarter],
    queryFn: fetchDeals,
    enabled: !!accountID,
  });

  // Additional safety check
  const safeDeals = Array.isArray(deals) ? deals : [];

  // Log the final deals being returned
  console.log("useFetchDeals returning:", {
    dealsCount: safeDeals.length,
    hasError: !!error,
    isLoading,
  });

  return {
    deals: safeDeals,
    isLoading,
    isError: error,
  };
}

export default useFetchDeals;

// src/views/lysto/Contacts/ContactsTable.jsx

import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { HiUser } from "react-icons/hi";
import { FaExpandAlt } from "react-icons/fa";
import PropTypes from "prop-types";
import DataTable from "../Shared/DataTable";
import { formatDistanceToNow } from "date-fns";
import HighlightText from "../Shared/HighlightText";

const ContactsTable = ({ contacts, onViewDetails, onSort, sortConfig, searchQuery }) => {
  // Define the columns configuration
  const columns = [
    {
      key: "name",
      label: "Name",
      width: "200px",
      sortable: true,
      isFirst: true,
    },
    {
      key: "email",
      label: "Email",
      width: "250px",
      sortable: true,
    },
    {
      key: "crm_account_name",
      label: "Account",
      width: "200px",
      sortable: true,
    },
    {
      key: "source",
      label: "Source",
      width: "150px",
      sortable: true,
    },
    {
      key: "isActive",
      label: "Status",
      width: "150px",
      sortable: true,
      hasDot: true,
      render: (row) => (
        <Box display="flex" alignItems="center" fontSize="md">
          <Box
            height="10px"
            width="10px"
            borderRadius="full"
            bg={row.isActive ? "blue.500" : "gray.500"}
            mr={2}
            transform="translateY(1px)"
          />
          <Text color="black" isTruncated maxWidth="100%">
            <HighlightText
              text={row.isActive ? "Active" : "Inactive"}
              query={searchQuery}
            />
          </Text>
        </Box>
      ),
    },
    {
      key: "last_activity_date",
      label: "Last Activity",
      width: "180px",
      sortable: true,
      format: (value) =>
        value ? formatDistanceToNow(new Date(value), { addSuffix: true }) : "—",
    },
  ];

  // Get color for status indicators
  const getRowColor = (row, columnKey) => {
    if (columnKey === "isActive") {
      return row.isActive ? "blue.500" : "gray.500";
    }
    return "gray.500";
  };

  // Function to get row icon component
  const getRowIcon = () => HiUser;

  return (
    <DataTable
      data={contacts}
      columns={columns}
      onSort={onSort}
      sortConfig={sortConfig}
      searchQuery={searchQuery}
      onRowClick={onViewDetails}
      rowIcon={getRowIcon}
      hoverIcon={FaExpandAlt}
      rowTooltip="View Contact Details"
      getRowColor={getRowColor}
    />
  );
};

ContactsTable.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onViewDetails: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf(["ascending", "descending"]),
  }).isRequired,
  searchQuery: PropTypes.string,
};

ContactsTable.defaultProps = {
  searchQuery: "",
};

export default ContactsTable;
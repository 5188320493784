// src/views/lysto/Dashboard/components/Win Loss Analysis/WinLossAnalysisGrid.js
import React from "react";
import { Box, VStack, SimpleGrid } from "@chakra-ui/react";
import WinLossStats from "./WinLossStats";
import WinRateOverTimeChart from "./WinRateOverTimeChart";
import OpportunityAmountsChart from "./OpportunityAmountsChart";
import OpportunityCountChart from "./OpportunityCountChart";
import WinRateBySalesRep from "./WinRateBySalesRep";
import WinRateByLeadSource from "./WinRateByLeadSource";
import WinRateBySize from "./WinRateBySize";
import WinRateByAge from "./WinRateByAge";
import RecentOpportunities from "./RecentOpportunities";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../constants";

/**
 * WinLossAnalysisGrid Component
 * Main component that aggregates all win/loss analysis sections.
 *
 * @param {Object} props - Component props.
 * @param {string} props.accountID - Account ID for data fetching.
 * @param {number} props.selectedYear - The year selected for filtering data.
 * @param {number} props.selectedQuarter - The quarter selected for filtering data (optional).
 * @param {string} props.selectedSegment - The selected segment for filtering data.
 * @param {Object} props.deals - The deals data from useDashboardData.
 * @returns {JSX.Element} The rendered WinLossAnalysisGrid component.
 */
const WinLossAnalysisGrid = ({ accountID, selectedYear, selectedQuarter, selectedSegment, deals }) => {
  // Ensure deals object exists to prevent errors
  const safeDeals = deals || { currentPeriod: [], prevPeriod: [] };

  return (
    <Box>
      <VStack spacing={3} align="stretch">
        {/* Stats Cards */}
        <WinLossStats
          deals={safeDeals}
          selectedYear={selectedYear}
          selectedQuarter={selectedQuarter}
          selectedSegment={selectedSegment}
        />

        {/* Charts */}
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={4}>
          <WinRateOverTimeChart
            accountID={accountID}
            selectedYear={selectedYear}
            selectedQuarter={selectedQuarter}
            selectedSegment={selectedSegment}
            primaryColor={PRIMARY_COLOR}
            deals={safeDeals}
          />
          <OpportunityCountChart
            accountID={accountID}
            selectedYear={selectedYear}
            selectedQuarter={selectedQuarter}
            selectedSegment={selectedSegment}
            primaryColor={PRIMARY_COLOR}
            secondaryColor={SECONDARY_COLOR}
            deals={safeDeals}
          />
        </SimpleGrid>

        <Box w="100%">
          <OpportunityAmountsChart
            accountID={accountID}
            selectedYear={selectedYear}
            selectedQuarter={selectedQuarter}
            selectedSegment={selectedSegment}
            primaryColor={PRIMARY_COLOR}
            secondaryColor={SECONDARY_COLOR}
            deals={safeDeals}
          />
        </Box>

        <Box w="100%">
          <WinRateBySalesRep
            deals={safeDeals}
            selectedYear={selectedYear}
            selectedQuarter={selectedQuarter}
            selectedSegment={selectedSegment}
            primaryColor={PRIMARY_COLOR}
            secondaryColor={SECONDARY_COLOR}
          />
        </Box>

        {/* Win Rate by Lead Source - Full Width */}
        <Box w="100%">
          <WinRateByLeadSource
            deals={safeDeals}
            selectedYear={selectedYear}
            selectedQuarter={selectedQuarter}
            selectedSegment={selectedSegment}
            primaryColor={PRIMARY_COLOR}
            secondaryColor={SECONDARY_COLOR}
          />
        </Box>

        {/* Recent Opportunities - Full Width */}
        <Box w="100%">
          <RecentOpportunities
            accountID={accountID}
            selectedYear={selectedYear}
            selectedQuarter={selectedQuarter}
            selectedSegment={selectedSegment}
            deals={safeDeals}
            primaryColor={PRIMARY_COLOR}
            secondaryColor={SECONDARY_COLOR}
          />
        </Box>

        {/* Other Analysis Charts */}
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={4}>
          <WinRateBySize
            deals={safeDeals}
            selectedYear={selectedYear}
            selectedQuarter={selectedQuarter}
            selectedSegment={selectedSegment}
            primaryColor={PRIMARY_COLOR}
            secondaryColor={SECONDARY_COLOR}
          />
          <WinRateByAge
            deals={safeDeals}
            selectedYear={selectedYear}
            selectedQuarter={selectedQuarter}
            selectedSegment={selectedSegment}
            primaryColor={PRIMARY_COLOR}
            secondaryColor={SECONDARY_COLOR}
          />
        </SimpleGrid>
      </VStack>
    </Box>
  );
}

export default WinLossAnalysisGrid;

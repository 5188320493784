// PrivateRoute.js
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Flex, Skeleton, useColorModeValue } from "@chakra-ui/react";

const LoadingSkeleton = () => {
  const sidebarBg = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );

  return (
    <Flex h="100vh">
      {/* Sidebar Skeleton */}
      <Box
        display={{ sm: "none", xl: "block" }}
        position="fixed"
        minH="100%"
      >
        <Flex
          bg={sidebarBg}
          w="200px"
          h="100vh"
          boxShadow={shadow}
          flexDirection="column"
          p={4}
        >
          {/* Logo/Brand Skeleton */}
          <Skeleton height="40px" width="120px" mb={8} />
          
          {/* Nav Items Skeleton */}
          {Array(6).fill(0).map((_, i) => (
            <Flex key={i} mb={4} align="center">
              <Skeleton height="20px" width="24px" mr={3} />
              <Skeleton height="20px" width="100px" />
            </Flex>
          ))}
          
          <Box mt="auto" mb={4}>
            {/* Profile Skeleton */}
            <Flex align="center">
              <Skeleton height="40px" width="40px" borderRadius="full" mr={3} />
              <Box>
                <Skeleton height="20px" width="100px" mb={2} />
                <Skeleton height="16px" width="80px" />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>

      {/* Main Content Skeleton */}
      <Box ml={{ xl: "200px" }} w="full" p={8}>
        <Skeleton height="60px" mb={8} />
        <Flex gap={4} mb={8}>
          {Array(3).fill(0).map((_, i) => (
            <Skeleton key={i} height="120px" flex={1} />
          ))}
        </Flex>
        <Skeleton height="400px" />
      </Box>
    </Flex>
  );
};

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const isLocalhost = window.location.hostname === "localhost";

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (!isAuthenticated) {
    console.log("Redirecting to external site");
    window.location.href = isLocalhost
      ? "http://localhost:3000/#/auth/sign-in/"
      : "https://app.lysto.ai";
    return null;
  }

  return children;
};

export default PrivateRoute;

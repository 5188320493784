import React from 'react';
import {
  VStack,
  HStack,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
  Button,
  Box,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';

const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const parseValue = (val) => {
  if (typeof val !== 'string') return 0;
  const numStr = val.replace(/[$,]/g, '');
  const parsed = parseFloat(numStr);
  return isNaN(parsed) ? 0 : parsed;
};

const SalesTargetModal = ({ onClose, salesTargets, updateMonthlyTarget, onSave }) => {
  const toast = useToast();

  const handleSave = () => {
    onSave?.(); // Call the onSave callback if provided
    toast({
      title: 'Sales targets updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    onClose();
  };

  return (
    <PopoverContent width="400px">
      <PopoverHeader fontWeight="semibold">Set Monthly Sales Targets</PopoverHeader>
      <PopoverBody>
        <Box 
          maxH="400px" 
          overflowY="auto"
          sx={{
            '&::-webkit-scrollbar': {
              width: '8px',
              borderRadius: '8px',
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
            },
          }}
        >
          <VStack spacing={4} align="stretch">
            {months.map((month, index) => (
              <HStack key={month} justify="space-between" align="center">
                <Text w="120px" fontSize="sm" color="gray.700">{month}</Text>
                <NumberInput
                  value={salesTargets[index + 1] || 0}
                  min={0}
                  precision={2}
                  step={1000}
                  size="sm"
                  width="180px"
                  onChange={(valueString) => {
                    const value = parseValue(valueString);
                    updateMonthlyTarget(index + 1, value);
                  }}
                >
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.500"
                      fontSize="sm"
                    >
                      $
                    </InputLeftElement>
                    <NumberInputField pl="7" />
                  </InputGroup>
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </HStack>
            ))}
          </VStack>
        </Box>
      </PopoverBody>
      <PopoverFooter>
        <HStack justify="flex-end" spacing={2}>
          <Button size="sm" variant="ghost" onClick={onClose}>Cancel</Button>
          <Button size="sm" colorScheme="blue" onClick={handleSave}>Save</Button>
        </HStack>
      </PopoverFooter>
    </PopoverContent>
  );
};

export default SalesTargetModal; 
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import DealTag from './DealTag';

/**
 * AccountDealsSection Component
 * Displays a list of deals associated with an account.
 */
const AccountDealsSection = ({ deals }) => {
  const navigate = useNavigate();

  const handleViewDetails = (deal) => {
    navigate(`/admin/deals/${deal.id}`);
  };

  return (
    <Box>
      <Text fontSize="md" fontWeight="semibold" mb={3}>
        Deals
      </Text>
      {deals.length === 0 ? (
        <Text color="gray.500">No deals associated with this account.</Text>
      ) : (
        <Wrap spacing={4}>
          {deals.map((deal) => (
            <WrapItem key={deal.id}>
              <DealTag
                dealName={deal.deal_name}
                onClick={() => handleViewDetails(deal)}
              />
            </WrapItem>
          ))}
        </Wrap>
      )}
    </Box>
  );
};

AccountDealsSection.propTypes = {
  deals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      deal_name: PropTypes.string,
      stage_name: PropTypes.string,
      amount: PropTypes.number,
      close_date: PropTypes.string,
    })
  ).isRequired,
};

export default AccountDealsSection; 
// src/components/charts/BarChart.js

import React from "react";
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { formatCurrencyValue } from '../../../lysto/utils/formatters';

/**
 * Custom Tooltip Component
 * Enhances the default tooltip to display only sources with non-zero deals and formats values.
 */
const CustomTooltip = ({ active, payload, label, useCurrencyFormat }) => {
  if (active && payload && payload.length) {
    // Filter out entries with zero value
    const filteredPayload = payload.filter((entry) => entry.value > 0);

    if (filteredPayload.length === 0) return null; // No entries to show

    return (
      <div
        style={{
          backgroundColor: "#f7fafc",
          border: "1px solid #e2e8f0",
          padding: "10px",
          borderRadius: "4px",
        }}
      >
        <p style={{ fontWeight: "bold", marginBottom: "5px" }}>{label}</p>
        {filteredPayload.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "2px",
            }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: entry.color,
                marginRight: "5px",
                borderRadius: "50%",
              }}
            ></div>
            <span style={{ color: "#2d3748", fontSize: "12px" }}>
              {entry.name}: {useCurrencyFormat ? formatCurrencyValue(entry.value) : entry.value.toLocaleString()}
            </span>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

/**
 * BarChart Component
 * Renders a bar chart using Recharts.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.barData - The data for the bar chart.
 * @param {Array} props.colors - Array of colors for the datasets.
 * @param {Function} props.onBarClick - Function to handle bar click events.
 * @param {boolean} props.useCurrencyFormat - Whether to format values as currency.
 * @returns {JSX.Element} The rendered BarChart component.
 */
const BarChart = ({ barData, colors, onBarClick, useCurrencyFormat = false }) => {
  // Validate barData structure
  if (!barData || !barData.labels || !barData.datasets) {
    console.error("Invalid barData structure", barData);
    return <div>No data available</div>;
  }

  // Ensure colors array is valid and has enough colors
  const safeColors =
    Array.isArray(colors) && colors.length >= barData.datasets.length
      ? colors
      : [];

  // Transform barData into Recharts-compatible data
  const chartData = barData.labels.map((label, index) => ({
    name: label,
    ...barData.datasets.reduce((acc, dataset) => {
      if (dataset && dataset.label && Array.isArray(dataset.data)) {
        acc[dataset.label] = dataset.data[index] || 0;
      }
      return acc;
    }, {}),
  }));

  // Handle Bar Click Event
  const handleClick = (data, index) => {
    if (onBarClick) {
      onBarClick({ activeLabelIndex: index });
    }
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <RechartsBarChart data={chartData} barGap={1}>
        <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
        <XAxis
          dataKey="name"
          tick={{ fill: "#718096", fontSize: 12 }}
          axisLine={{ stroke: "#e2e8f0" }}
          tickLine={false}
        />
        <YAxis
          tick={{ fill: "#718096", fontSize: 12 }}
          axisLine={{ stroke: "#e2e8f0" }}
          tickLine={false}
          tickFormatter={(value) => useCurrencyFormat ? formatCurrencyValue(value) : value.toLocaleString()}
        />
        <Tooltip content={<CustomTooltip useCurrencyFormat={useCurrencyFormat} />} />
        {barData.datasets.map((dataset, index) =>
          dataset && dataset.label ? (
            <Bar
              key={dataset.label}
              dataKey={dataset.label}
              fill={safeColors[index]}
              onClick={(data, event) => handleClick(data, event.index)}
              radius={[10, 10, 0, 0]}
            />
          ) : null
        )}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

export default BarChart;

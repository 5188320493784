// src/views/lysto/Spark/hooks/useFetchSparks.js

import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

export function useFetchSparks(
  accountID,
  userID,
  pageNumber = 1,
  itemsPerPage = 10,
  filter = "all"
) {
  const [sparks, setSparks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const { getAccessToken } = useAuthData();

  useEffect(() => {
    const fetchSparks = async () => {
      if (!accountID || !userID) {
        setLoading(false);
        return;
      }

      try {
        const accessToken = await getAccessToken();
        const target =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        const params = {
          page_number: pageNumber,
          items_per_page: itemsPerPage,
          filter: filter.toLowerCase(),
        };

        const response = await axios.get(
          `${target}/accounts/${accountID}/users/${userID}/spark`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params,
          }
        );

        setSparks(response.data.items || []);
        setHasMore(response.data.has_more || false);
        setTotalCount(response.data.total_count || 0);
        setError(null);
      } catch (err) {
        console.error("Error fetching spark data:", err);
        setError("Failed to fetch spark data");
        setSparks([]);
        setHasMore(false);
        setTotalCount(0);
      } finally {
        setLoading(false);
      }
    };

    fetchSparks();
  }, [accountID, userID, getAccessToken, pageNumber, itemsPerPage, filter]);

  const handleAddDeal = async (sparkId) => {
    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      await axios.post(
        `${target}/accounts/${accountID}/users/${userID}/spark/${sparkId}/convert`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Remove the converted spark from the list
      setSparks((currentSparks) =>
        currentSparks.filter((spark) => spark.id !== sparkId)
      );

      return { success: true };
    } catch (err) {
      console.error("Error converting spark to deal:", err);
      throw new Error("Failed to convert spark to deal");
    }
  };

  const handleIgnoreSpark = async (sparkId) => {
    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      await axios.post(
        `${target}/accounts/${accountID}/users/${userID}/spark/${sparkId}/ignore`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Remove the ignored spark from the list
      setSparks((currentSparks) =>
        currentSparks.filter((spark) => spark.id !== sparkId)
      );

      return { success: true };
    } catch (err) {
      console.error("Error ignoring spark:", err);
      throw new Error("Failed to ignore spark");
    }
  };

  const markSparkAsRead = async (sparkId) => {
    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      await axios.post(
        `${target}/accounts/${accountID}/users/${userID}/spark/${sparkId}/read`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Update the spark's read status in the local state
      setSparks((currentSparks) =>
        currentSparks.map((spark) =>
          spark.id === sparkId ? { ...spark, read: true } : spark
        )
      );

      return { success: true };
    } catch (err) {
      console.error("Error marking spark as read:", err);
      throw new Error("Failed to mark spark as read");
    }
  };

  const markAllSparksAsRead = async () => {
    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

      await axios.post(
        `${target}/accounts/${accountID}/users/${userID}/spark/read-all`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Update all sparks' read status in the local state
      setSparks((currentSparks) =>
        currentSparks.map((spark) => ({ ...spark, read: true }))
      );

      return { success: true };
    } catch (err) {
      console.error("Error marking all sparks as read:", err);
      throw new Error("Failed to mark all sparks as read");
    }
  };

  return {
    sparks,
    loading,
    error,
    hasMore,
    totalCount,
    handleAddDeal,
    handleIgnoreSpark,
    markSparkAsRead,
    markAllSparksAsRead,
  };
}

export default useFetchSparks;

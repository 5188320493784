// StatCard.js
import React from "react";
import { Icon } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/card/MiniStatistics";

const StatCard = ({
  icon,
  title,
  value,
  growth,
  timeRange,
  primaryColor,
  tooltip
}) => {
  // Determine the growth display
  let growthDisplay = "N/A";
  
  if (typeof growth === "number") {
    const arrow = growth >= 0 ? "↑" : "↓";
    const absValue = Math.abs(growth).toFixed(1);
    // Combine arrow, percentage, and timeRange suffix
    growthDisplay = `${arrow} ${absValue}% ${timeRange}`;
  }

  const iconBox = (
    <IconBox
      w="56px"
      h="56px"
      bg={primaryColor}
      icon={<Icon as={icon} w="32px" h="32px" color="white" />}
    />
  );

  return (
    <MiniStatistics
      name={title}
      value={value !== undefined && value !== "N/A" ? value : "N/A"}
      growth={growthDisplay}
      startContent={iconBox}
      tooltip={tooltip}
    />
  );
};

export default StatCard;

// src/components/Inbox/CallFollowupDetail.jsx
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  VStack,
  Heading,
  useToast,
  Flex,
  Button,
  Tooltip,
  Spacer, // Import Spacer
} from "@chakra-ui/react";
import OverviewSection from "./OverviewSection";
import MarkdownRenderer from "./MarkdownRenderer";
import AnnotationModal from "./AnnotationModal";
import { FaCheckCircle } from "react-icons/fa";

const CallFollowupDetail = ({
  item,
  onAddAnnotation,
  onTaskDone,
}) => {
  const toast = useToast();
  const [isAnnotationModalOpen, setIsAnnotationModalOpen] = useState(false);

  const toggleAnnotationModal = () => {
    setIsAnnotationModalOpen((prev) => !prev);
  };

  // Extract the call_prep_content from the JSON string
  let guidanceContent = '';
  try {
    const parsedContent = JSON.parse(item.content);
    guidanceContent = parsedContent.call_prep_content || 'No guidance content available.';
  } catch (error) {
    console.error('Error parsing guidance content:', error);
    guidanceContent = 'No guidance content available.';
  }

  const handleMarkAsDone = async () => {
    try {
      await onTaskDone(item.id);
      toast({
        title: "Marked as Done",
        description: "The task has been marked as done successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error marking as done:", error);
      toast({
        title: "Action Failed",
        description: error.message || "Failed to mark the task as done.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      bg="transparent"
      p={0}
      borderRadius="md"
      boxShadow="none"
      mb={4}
      width="100%"
    >
      <VStack spacing={6} align="stretch" width="100%">
        {/* Card 1: Overview */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            <OverviewSection
              account={item.account_name}
              taskType={item.task_subtype === "call" ? "Call Follow-up" : item.task_subtype}
              champion={item.champion || "N/A"}
              lastEngagementDate={item.last_activity_date}
            />
          </VStack>
        </Box>

        {/* Card 2: Suggested Call Agenda */}
        <Box
            bg="white"
            p={6}
            borderRadius="md"
            boxShadow="sm"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <Flex align="center" mb={4}> {/* Flex container for header and button */}
              <Heading as="h2" size="md" color="gray.700">
                Suggested Call Agenda
              </Heading>
              <Spacer /> {/* Spacer to push button to the right */}
              <Tooltip label="Mark as Done" placement="top">
                  <Button
                    leftIcon={<FaCheckCircle />}
                    colorScheme="blue"
                    onClick={handleMarkAsDone}
                    size="sm" // Adjusted size for header
                  >
                    Mark as Done
                  </Button>
                </Tooltip>
            </Flex>
          <VStack spacing={6} align="stretch">
            {/* Guidance Content */}
            <MarkdownRenderer content={guidanceContent} />
          </VStack>
        </Box>

      </VStack>

      {/* Annotation Modal */}
      <AnnotationModal
        isOpen={isAnnotationModalOpen}
        onClose={toggleAnnotationModal}
        onAddAnnotation={async (annotation) => {
          try {
            await onAddAnnotation(item.id, annotation);
            toggleAnnotationModal();
            toast({
              title: "Note added successfully",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Failed to add note",
              description: error.message || "Please try again",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        }}
      />
    </Box>
  );
};

CallFollowupDetail.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    content: PropTypes.string.isRequired, // JSON string containing call_prep_content
    status: PropTypes.string,
    account_name: PropTypes.string,
    task_subtype: PropTypes.string.isRequired,
    last_activity_date: PropTypes.string,
    champion: PropTypes.string,
  }).isRequired,
  onAddAnnotation: PropTypes.func.isRequired,
  onTaskDone: PropTypes.func.isRequired,
};

export default CallFollowupDetail;
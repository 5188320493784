// src/views/lysto/Dashboard/index.jsx
import React, { useMemo } from 'react';
import {
  Box,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorModeValue,
  VStack,
  useToast,
  Skeleton,
  Stack,
  Flex,
  HStack,
  useDisclosure,
  Popover,
  PopoverTrigger,
  Icon,
} from "@chakra-ui/react";
import { useAuthData } from "../../../auth-context";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiSettings } from "react-icons/fi";

// Components
import SalesProcess from "./components/Sales Process";
import SalesPerformanceGrid from "./components/Sales Performance/SalesPerformanceGrid";
import SalesEfficiencyGrid from "./components/Sales Efficiency/SalesEfficiencyGrid";
import SalesTargetGrid from "./components/Sales Target/SalesTargetGrid";
import WinLossAnalysisGrid from "./components/Win Loss Analysis/WinLossAnalysisGrid";
import PipelineAnalysisGrid from "./components/Pipeline Analysis/PipelineAnalysisGrid";
import PageHeader from "../Shared/PageHeader";
import SalesTargetModal from "./components/Sales Target/SalesTargetModal";

// Hooks
import { useDashboardData } from '../hooks/useDashboardData';
import { useDashboardNavigation } from '../hooks/useDashboardNavigation';
import useSalesTargets from '../hooks/useSalesTargets';

const Dashboard = () => {
  const { account_ID: accountID } = useAuthData();
  const toast = useToast();
  const { isOpen: isTargetModalOpen, onOpen: onTargetModalOpen, onClose: onTargetModalClose } = useDisclosure();
  const { salesTargets, updateMonthlyTarget } = useSalesTargets();

  const {
    selectedSection,
    setSelectedSection,
    selectedYear,
    setSelectedYear,
    selectedQuarter,
    setSelectedQuarter,
    navigationOptions,
    availableYears,
    quarters,
    shouldShowYearDropdown,
    selectedSegment,
    setSelectedSegment,
    segmentOptions,
    shouldShowSegmentDropdown
  } = useDashboardNavigation();

  const {
    deals: dealsData,
    users: usersData,
    isLoading: isDataLoading,
    error: dashboardError,
    currentPeriodRange,
    prevPeriodRange
  } = useDashboardData(accountID, selectedYear, selectedQuarter);

  console.log('Dashboard data loaded:', {
    hasDealsData: !!dealsData,
    dealsStructure: {
      currentPeriod: dealsData?.currentPeriod?.length,
      prevPeriod: dealsData?.prevPeriod?.length,
      periodType: dealsData?.periodType
    },
    selectedSection,
    selectedYear,
    selectedQuarter,
    isLoading: isDataLoading,
    error: dashboardError
  });

  // Menu for year
  const YearDropdown = (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        bg={useColorModeValue("white", "navy.900")}
        borderColor={useColorModeValue("gray.300", "gray.500")}
        borderWidth="1px"
        _hover={{ bg: useColorModeValue("gray.50", "gray.400") }}
        _active={{ bg: useColorModeValue("gray.50", "gray.400") }}
        color={useColorModeValue("gray.700", "white")}
        variant="outline"
        borderRadius="md"
      >
        {selectedYear}
      </MenuButton>
      <MenuList>
        {availableYears.map((year) => (
          <MenuItem
            key={year}
            onClick={() => setSelectedYear(year)}
          >
            {year}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );

  // Additional controls for Sales Target section
  const additionalControls = useMemo(() => {
    if (selectedSection === 'Sales to Target') {
      return (
        <Popover
          placement="bottom-end"
          isOpen={isTargetModalOpen}
          onClose={onTargetModalClose}
        >
          <PopoverTrigger>
            <Button
              leftIcon={<Icon as={FiSettings} />}
              rightIcon={<ChevronDownIcon />}
              variant="outline"
              bg="white"
              borderRadius="md"
              borderColor="gray.300"
              _hover={{ bg: "gray.50" }}
              onClick={onTargetModalOpen}
            >
              Set Sales Targets
            </Button>
          </PopoverTrigger>
          <SalesTargetModal
            onClose={onTargetModalClose}
            salesTargets={salesTargets}
            updateMonthlyTarget={updateMonthlyTarget}
            onSave={() => {
              // Force a re-render of all components by temporarily changing the year
              const currentYear = selectedYear;
              const currentQuarter = selectedQuarter;
              
              // Toggle year to force refresh
              setSelectedYear(prev => prev + 1);
              
              // Set it back after a brief delay
              setTimeout(() => {
                setSelectedYear(currentYear);
                if (currentQuarter !== null) {
                  setSelectedQuarter(null);
                  setTimeout(() => setSelectedQuarter(currentQuarter), 0);
                }
              }, 0);
            }}
          />
        </Popover>
      );
    }
    return null;
  }, [selectedSection, isTargetModalOpen, onTargetModalClose, salesTargets, updateMonthlyTarget, selectedYear, selectedQuarter]);

  // Render loading
  if (isDataLoading) {
      return (
          <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
              {/* Loading Header */}
              <Stack spacing={6}>
                <Box>
                  <Skeleton height="36px" width="200px" mb={4} />
                  <Flex gap={4} align="center">
                    <Skeleton height="40px" width="200px" borderRadius="md" />
                    <Skeleton height="40px" width="120px" borderRadius="md" />
                  </Flex>
                </Box>
                
                {/* Loading Content */}
                <Stack spacing={6}>
                  <Box 
                    p={6} 
                    bg={useColorModeValue("white", "navy.900")} 
                    borderRadius="lg" 
                    boxShadow="sm"
                    border="1px solid"
                    borderColor={useColorModeValue("gray.200", "gray.600")}
                  >
                    <Stack spacing={4}>
                      <Skeleton height="20px" width="40%" />
                      <Skeleton height="200px" />
                    </Stack>
                  </Box>
                  
                  <Box 
                    p={6} 
                    bg={useColorModeValue("white", "navy.900")} 
                    borderRadius="lg" 
                    boxShadow="sm"
                    border="1px solid"
                    borderColor={useColorModeValue("gray.200", "gray.600")}
                  >
                    <Stack spacing={4}>
                      <Skeleton height="20px" width="30%" />
                      <Skeleton height="200px" />
                    </Stack>
                  </Box>
                </Stack>
              </Stack>
            </Box>
      );
  }

  if (dashboardError) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
        <VStack align="start" spacing={2}>
          <Text color="red.500">Error loading dashboard data:</Text>
          {dashboardError.deals && <Text color="red.500">• Deals: {dashboardError.deals.message}</Text>}
          {dashboardError.users && <Text color="red.500">• Users: {dashboardError.users.message}</Text>}
          {dashboardError.pipeline && <Text color="red.500">• Pipeline: {dashboardError.pipeline.message}</Text>}
        </VStack>
      </Box>
    );
  }


  return (
    <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
      <PageHeader
        title="Reports"
        segmentOptions={navigationOptions}
        selectedSegment={selectedSection}
        onSegmentChange={setSelectedSection}
        showSearch={false}
        additionalControls={
          <HStack spacing={4} justify="space-between" width="100%">
            <HStack spacing={6}>
              {shouldShowYearDropdown && YearDropdown}
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  variant="outline"
                  bg="white"
                  borderRadius="md"
                  borderColor="gray.300"
                  _hover={{ bg: "gray.50" }}
                >
                  {selectedQuarter ? `Q${selectedQuarter}` : 'Full Year'}
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => setSelectedQuarter(null)}>
                    Full Year
                  </MenuItem>
                  {quarters.map((quarter) => (
                    <MenuItem
                      key={quarter.value}
                      onClick={() => setSelectedQuarter(quarter.value)}
                    >
                      {quarter.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              {shouldShowSegmentDropdown(selectedSection) && (
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    variant="outline"
                    bg="white"
                    borderRadius="md"
                    borderColor="gray.300"
                    _hover={{ bg: "gray.50" }}
                  >
                    {segmentOptions.find(opt => opt.value === selectedSegment)?.label || 'All'}
                  </MenuButton>
                  <MenuList>
                    {segmentOptions.map((segment) => (
                      <MenuItem
                        key={segment.value}
                        onClick={() => setSelectedSegment(segment.value)}
                      >
                        {segment.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              )}
            </HStack>
            {additionalControls}
          </HStack>
        }
      />

      {/* Main Content */}
      <Box>
        {selectedSection === 'Sales to Target' && (
          <SalesTargetGrid
            deals={dealsData}
            selectedYear={selectedYear}
            selectedQuarter={selectedQuarter}
            selectedSegment={selectedSegment}
            salesTargets={salesTargets}
            currentPeriodRange={currentPeriodRange}
            prevPeriodRange={prevPeriodRange}
          />
        )}
        {selectedSection === 'Sales Performance' && (
          <SalesPerformanceGrid
            deals={dealsData}
            selectedYear={selectedYear}
            selectedQuarter={selectedQuarter}
            selectedSegment={selectedSegment}
            currentPeriodRange={currentPeriodRange}
            prevPeriodRange={prevPeriodRange}
            users={usersData}
          />
        )}
        {selectedSection === 'Sales Efficiency' && (
          <SalesEfficiencyGrid
            deals={dealsData}
            selectedYear={selectedYear}
            selectedQuarter={selectedQuarter}
            selectedSegment={selectedSegment}
            currentPeriodRange={currentPeriodRange}
            prevPeriodRange={prevPeriodRange}
            users={usersData}
          />
        )}
        {selectedSection === 'Win-Loss Analysis' && (
          <WinLossAnalysisGrid
            accountID={accountID}
            selectedYear={selectedYear}
            selectedQuarter={selectedQuarter}
            selectedSegment={selectedSegment}
            deals={dealsData}
          />
        )}
        {selectedSection === 'Pipeline Analysis' && (
          <PipelineAnalysisGrid
            accountId={accountID}
            selectedYear={selectedYear}
            selectedQuarter={selectedQuarter}
            selectedSegment={selectedSegment}
            selectedPeriod={selectedQuarter ? 'quarter' : 'year'}
          />
        )}
        {selectedSection === 'Sales Process' && (
          <SalesProcess
            accountID={accountID}
            toast={toast}
            selectedYear={selectedYear}
            selectedQuarter={selectedQuarter}
            selectedSegment={selectedSegment}
            deals={dealsData}
          />
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
import { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

export const useFetchMeetingDeals = (accountId, dealId) => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!dealId) {
        setIsLoading(false);
        return;
      }

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:deals",
          },
        });
        
        const target = process.env.REACT_APP_API_BASE_URL || "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
        const response = await axios.get(
          `${target}/accounts/${accountId}/deals/${dealId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        setData(response.data ? [response.data] : []);
      } catch (error) {
        console.error("Error fetching deals:", error);
        setError("Failed to load deal information");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [accountId, dealId, getAccessTokenSilently]);

  return { data, isLoading, error };
}; 
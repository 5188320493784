// src/components/Sales Target/ClosedWonChart.js

import React from 'react';
import { Box } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import LineFirst from "../LineFirst";
import { formatCurrencyValue } from '../../../utils/formatters';

const getQuarterMonths = (quarter) => {
  const startMonth = (quarter - 1) * 3;
  return Array.from({ length: 3 }, (_, i) => startMonth + i);
};

const ClosedWonChart = ({ 
  deals = { currentPeriod: [], prevPeriod: [] }, 
  selectedYear, 
  selectedQuarter,
  salesTargets,
  selectedSegment
}) => {
  const processMonthlyData = () => {
    // Ensure deals and currentPeriod exist and are arrays
    const currentPeriod = Array.isArray(deals?.currentPeriod) ? deals.currentPeriod : [];
    const prevPeriod = Array.isArray(deals?.prevPeriod) ? deals.prevPeriod : [];
    
    // Filter deals by segment
    const filterDealsBySegment = (dealsArray) => {
      if (!selectedSegment || selectedSegment === "All") return dealsArray;
      
      return dealsArray.filter(deal => {
        const locations = deal.extra_fields?.No_of_Locations__c;
        if (locations === null || locations === undefined) return false;
        
        switch (selectedSegment) {
          case "SMB":
            return locations >= 1 && locations <= 20;
          case "Mid-Market":
            return locations > 20 && locations <= 200;
          case "Enterprise":
            return locations > 200;
          default:
            return true;
        }
      });
    };

    const filteredCurrentPeriod = filterDealsBySegment(currentPeriod);
    const filteredPrevPeriod = filterDealsBySegment(prevPeriod);
    
    // Log filtered deals for debugging
    console.log('Chart Filtered Current Period Deals:', filteredCurrentPeriod.length);
    console.log('Chart Filtered Prev Period Deals:', filteredPrevPeriod.length);
    
    let monthsToProcess;
    
    if (selectedQuarter) {
      monthsToProcess = getQuarterMonths(selectedQuarter);
    } else {
      monthsToProcess = Array.from({ length: 12 }, (_, i) => i);
    }

    const monthlyData = monthsToProcess.map(() => 0);
    const prevYearData = monthsToProcess.map(() => 0);
    // Map to 1-based month indices for salesTargets
    const monthlyTargets = monthsToProcess.map((monthIndex) => salesTargets[monthIndex + 1] || 0);

    // Process current period deals
    filteredCurrentPeriod.forEach(deal => {
      if (!deal || !deal.is_closed || !deal.is_won) return;
      
      try {
        const dealDate = new Date(deal.close_date);
        if (!isNaN(dealDate) && dealDate.getFullYear() === selectedYear) {
          const dealMonth = dealDate.getMonth();
          const monthIndex = monthsToProcess.indexOf(dealMonth);
          if (monthIndex !== -1) {
            monthlyData[monthIndex] += Number(deal.amount) || 0;
          }
        }
      } catch (error) {
        console.error('Error processing current period deal:', error, deal);
      }
    });

    // Process previous period deals
    filteredPrevPeriod.forEach(deal => {
      if (!deal || !deal.is_closed || !deal.is_won) return;
      
      try {
        const dealDate = new Date(deal.close_date);
        if (!isNaN(dealDate) && dealDate.getFullYear() === selectedYear - 1) {
          const dealMonth = dealDate.getMonth();
          const monthIndex = monthsToProcess.indexOf(dealMonth);
          if (monthIndex !== -1) {
            prevYearData[monthIndex] += Number(deal.amount) || 0;
          }
        }
      } catch (error) {
        console.error('Error processing previous period deal:', error, deal);
      }
    });

    // Calculate percentages based on the monthly targets
    const percentages = monthlyData.map((value, index) => {
      const target = monthlyTargets[index];
      if (!target) return '0% of Goal';
      return `${((value / target) * 100).toFixed(0)}% of Goal`;
    });

    return {
      data: [monthlyData, monthlyTargets, prevYearData],
      percentages
    };
  };

  const { data, percentages } = processMonthlyData();

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  let chartMonths;
  if (selectedQuarter) {
    const quarterMonths = getQuarterMonths(selectedQuarter);
    chartMonths = quarterMonths.map(monthIndex => months[monthIndex]);
  } else {
    chartMonths = months;
  }

  const chartData = {
    title: `Closed Won vs Target ${selectedQuarter ? `Q${selectedQuarter} ` : ''}${selectedYear}`,
    labels: [`Closed Won ${selectedYear}`, `Closed Won ${selectedYear - 1}`, "Target"],
    options: chartMonths.map(month => `${month} ${selectedYear}`),
    data: [
      data[0],  // Current year
      data[2],  // Previous year
      data[1]   // Target
    ]
  };

  const maxValue = Math.max(
    ...data[0].map(v => v || 0),  // Current year
    ...data[2].map(v => v || 0),  // Previous year
    ...data[1].map(v => v || 0)   // Target
  ) || 0;

  const customOptions = {
    yaxis: {
      labels: {
        formatter: (value) => {
          return formatCurrencyValue(value).replace(',000', 'k');
        }
      },
      min: 0,
      max: maxValue * 1.1,
      tickAmount: 4
    },
    annotations: {
      yaxis: [{
        y: 0,
        borderColor: '#999',
        borderWidth: 1,
        opacity: 0.1
      }]
    },
    grid: {
      borderColor: '#f1f1f1',
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true,
          opacity: 0.1
        }
      }
    },
    markers: {
      size: 4
    },
    tooltip: {
      y: {
        formatter: (value) => formatCurrencyValue(value)
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, { seriesIndex, dataPointIndex }) {
        return seriesIndex === 0 ? percentages[dataPointIndex] : '';
      },
      offsetY: -10,
      style: {
        fontSize: '12px',
        colors: ['#1A5BF6']
      }
    },
    legend: {
      show: true
    }
  };

  return (
    <Card p="20px" mb={4}>
      <Box height="450px">
        <LineFirst 
          key={`chart-${JSON.stringify(salesTargets)}-${selectedYear}-${selectedQuarter}`}
          lineData={chartData}
          customOptions={customOptions}
          hideChartTitle={true}
          selectedYear={selectedYear}
          timeFilter={selectedQuarter ? `Q${selectedQuarter}` : "all_year"}
          hideToggle={true}
          selectedSegment={selectedSegment}
        />
      </Box>
    </Card>
  );
};

export default ClosedWonChart;
// src/hooks/useFetchDealById.js

import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

/**
 * Custom hook to fetch deal based on the account ID.
 * @param {string} accountID - The account ID to fetch deal for.
 * @param {string} dealID - The deal ID to fetch.
 * @returns {Object} - Contains deal data, loading state, and error state.
 */
export function useFetchDealById(accountID, dealID) {
  const [deal, setDeal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessToken } = useAuthData();

  useEffect(() => {
    const fetchDeal = async () => {
      if (!accountID || !dealID) {
        setLoading(false);
        setError(new Error("Missing required parameters: accountID or dealID"));
        return;
      }

      try {
        const accessToken = await getAccessToken();

        const target =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        const response = await axios.get(
          `${target}/accounts/${accountID}/deals/${dealID}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!response.data) {
          throw new Error(`Deal with ID ${dealID} not found`);
        }

        setDeal(response.data);
        setError(null);
      } catch (error) {
        console.error("Error fetching deal:", error);
        setDeal(null);
        setError(error.response?.data?.message || error.message || "Failed to fetch deal details");
      } finally {
        setLoading(false);
      }
    };

    fetchDeal();
  }, [accountID, dealID, getAccessToken]);

  return { data: deal, loading, error };
}

export default useFetchDealById;

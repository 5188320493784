import React from 'react';
import PropTypes from 'prop-types';
import { Box, VStack, Flex, Text } from '@chakra-ui/react';
import { FaCity, FaFilter, FaFlag } from 'react-icons/fa';
import { Globe, ExternalLink } from 'lucide-react';
import { OverviewTag } from '../Shared/Tags';
import BaseTag from '../Shared/BaseTag';
import AccountContactsSection from './AccountContactsSection';
import AccountDealsSection from './AccountDealsSection';
import AccountActivity from './AccountActivity';

const AccountDetail = ({ account }) => {
  return (
    <Box
      bg="transparent"
      p={0}
      borderRadius="md"
      boxShadow="none"
      mb={4}
      height="100%"
    >
      <VStack spacing={6} align="stretch" height="100%">
        {/* Combined Overview, Contacts, and Deals Section */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            {/* Overview Section */}
            <Box>
              <Text fontSize="md" fontWeight="semibold" mb={3}>
                Overview
              </Text>
              <Flex gap={3} flexWrap="wrap">
                {account.website && (
                  <a 
                    href={account.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                    role="button"
                  >
                    <BaseTag
                      icon={Globe}
                      hoverIcon={ExternalLink}
                      colorScheme="blue"
                      tooltip="Open Website"
                      onClick={(e) => e.preventDefault()}
                    >
                      <Text fontSize="sm" fontWeight="medium">
                        Website
                      </Text>
                    </BaseTag>
                  </a>
                )}
                <OverviewTag
                  icon={FaCity}
                  label="City"
                  value={account.city || 'N/A'}
                  colorScheme="purple"
                />
                <OverviewTag
                  icon={FaFilter}
                  label="Type"
                  value={account.type || 'N/A'}
                  colorScheme="orange"
                />
                <OverviewTag
                  icon={FaFlag}
                  label="Source"
                  value={account.source_system || 'N/A'}
                  colorScheme="teal"
                />
              </Flex>
            </Box>

            {/* Contacts Section */}
            <AccountContactsSection contacts={account.contacts || []} />

            {/* Deals Section */}
            <AccountDealsSection deals={account.deals || []} />
          </VStack>
        </Box>

        {/* Activity Section */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <AccountActivity account={account} />
        </Box>
      </VStack>
    </Box>
  );
};

AccountDetail.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    website: PropTypes.string,
    website_root: PropTypes.string,
    city: PropTypes.string,
    type: PropTypes.string,
    source_system: PropTypes.string,
    last_activity_date: PropTypes.string,
    contacts: PropTypes.array,
    deals: PropTypes.array,
    activities: PropTypes.array,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};

export default AccountDetail; 
// src/views/lysto/Shared/HighlightText.jsx

import React from "react";
import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";

/**
 * Escapes special characters in a string for use in a regular expression.
 * @param {string} string - The string to escape.
 * @returns {string} - The escaped string.
 */
export const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

/**
 * Highlights the search query within the text.
 * @param {string} text - The original text.
 * @param {string} query - The search query to highlight.
 * @param {boolean} truncate - Optional boolean to truncate or not
 * @returns {JSX.Element}
 */
const HighlightText = ({ text, query = "", truncate = true }) => {
  if (!query) {
    return (
      <Box
        as="span"
        display="block"
        overflow={truncate ? "hidden" : "visible"}
        textOverflow={truncate ? "ellipsis" : "clip"}
        whiteSpace={truncate ? "nowrap" : "normal"}
      >
        {text}
      </Box>
    );
  }

  const escapedQuery = escapeRegExp(query);
  const regex = new RegExp(`(${escapedQuery})`, "i"); // No 'g' flag

  const parts = text.split(regex);

  return (
    <Box
      as="span"
      display="block"
      overflow={truncate ? "hidden" : "visible"}
      textOverflow={truncate ? "ellipsis" : "clip"}
      whiteSpace={truncate ? "nowrap" : "normal"}
    >
      {parts.map((part, index) =>
        regex.test(part) ? (
          <Box
            as="span"
            bg="blue.500"
            color="white"
            key={index}
            px={0.5}
            borderRadius="sm"
            display="inline"
            fontWeight="medium"
          >
            {part}
          </Box>
        ) : (
          <Box as="span" key={index} display="inline">
            {part}
          </Box>
        )
      )}
    </Box>
  );
};

HighlightText.propTypes = {
  text: PropTypes.string.isRequired,
  query: PropTypes.string,
  truncate: PropTypes.bool,
};

export default HighlightText;
// src/components/Inbox/MessageInput.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Box, VStack } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const MessageInput = ({ message, setMessage, isSending }) => {
  // Handle paste to strip formatting
  const handlePaste = (e) => {
    if (e.clipboardData) {
      // Get plain text from clipboard
      const text = e.clipboardData.getData('text/plain');
      document.execCommand('insertText', false, text);
      e.preventDefault();
    }
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false // Prevent paste formatting
    }
  };

  const formats = [
    'bold',
    'italic',
    'underline',
    'list',
    'bullet',
    'link'
  ];

  return (
    <VStack spacing={2} align="stretch" flex="1">
      <Box
        borderWidth="1px"
        borderColor="gray.300"
        borderRadius="lg"
        bg="white"
        position="relative"
        height="400px"
        display="flex"
        flexDirection="column"
        sx={{
          '.ql-toolbar.ql-snow': {
            border: 'none',
            borderBottom: '1px solid',
            borderColor: 'gray.200',
            padding: '8px 12px',
            borderTopRadius: 'lg',
            flexShrink: 0,
          },
          '.ql-container.ql-snow': {
            border: 'none',
            borderBottomRadius: 'lg',
            backgroundColor: 'white',
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          },
          '.ql-editor': {
            flex: '1 1 auto',
            overflowY: 'auto',
            fontSize: '1rem',
            lineHeight: '1.5',
            padding: '12px 15px',
            minHeight: '0',
            '&.ql-blank::before': {
              color: 'gray.400',
              fontStyle: 'normal',
              left: '15px',
            },
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'gray.50',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'gray.300',
              borderRadius: '4px',
            },
          },
          '.ql-editor p': {
            marginBottom: '0.5em',
          },
        }}
      >
        <ReactQuill
          value={message}
          onChange={setMessage}
          modules={modules}
          formats={formats}
          placeholder="Type your message here..."
          onPaste={handlePaste}
          readOnly={isSending}
          className={isSending ? 'opacity-50' : ''}
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        />
      </Box>
    </VStack>
  );
};

MessageInput.propTypes = {
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
  isSending: PropTypes.bool,
};

MessageInput.defaultProps = {
  isSending: false,
};

export default MessageInput;

// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Meetings/index.jsx
// MeetingsView.jsx
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Text,
  SimpleGrid,
  Icon,
  HStack,
  Tooltip,
  Avatar,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuthData } from 'auth-context';
import { Video, MapPin, CalendarDays } from 'lucide-react';
import { useDebounce } from 'use-debounce';
import { FaExpandAlt } from 'react-icons/fa';
import { DateTime } from 'luxon';

import PageHeader from '../Shared/PageHeader';
import DataTable from '../Shared/DataTable';
import PaginationControls from '../Shared/PaginationControls';
import HoverBox from '../Shared/HoverBox';
import TableSkeleton from '../Shared/TableSkeleton';
import useFetchMeetings from '../hooks/useFetchMeetings';

const ITEMS_PER_PAGE = 10;

const MeetingsView = () => {
  const navigate = useNavigate();
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const userID = userData?.user_ID;

  // State management
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSegment, setSelectedSegment] = useState('Upcoming');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({
    key: 'startTime',
    direction: 'ascending'
  });

  // Debounce search query
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  // Fetch meetings using the custom hook
  const { meetings: fetchedMeetings, loading: isFetching, error: fetchError } = useFetchMeetings(
    accountID,
    userID,
    selectedSegment,
    currentPage,
    ITEMS_PER_PAGE
  );

  // Effect to update sort direction when segment changes
  useEffect(() => {
    setSortConfig(() => ({
      key: 'startTime',
      direction: selectedSegment === 'Upcoming' ? 'ascending' : 'descending'
    }));
  }, [selectedSegment]);

  // Function to capitalize first letter
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Function to check if meeting is upcoming
  const isUpcoming = (meeting) => {
    const endTime = DateTime.fromISO(meeting.endTime);
    const currentTime = DateTime.now();
    const fiveMinutesAfterEnd = endTime.plus({ minutes: 5 });
    return currentTime < fiveMinutesAfterEnd;
  };

  // Filter meetings based on segment
  const filterMeetingsBySegment = useCallback((meetings, segment) => {
    switch (segment) {
      case 'Upcoming':
        return meetings.filter(meeting => isUpcoming(meeting));
      case 'Recorded':
        return meetings.filter(meeting => meeting.recorded);
      case 'All':
        return meetings;
      default:
        return meetings;
    }
  }, []);

  // Update local state when fetched meetings change
  useEffect(() => {
    if (fetchedMeetings) {
      const filteredMeetings = filterMeetingsBySegment(fetchedMeetings, selectedSegment);
      setMeetings(filteredMeetings);
    }
    setLoading(isFetching);
    setError(fetchError);
  }, [fetchedMeetings, isFetching, fetchError, selectedSegment, filterMeetingsBySegment]);

  // Generate search suggestions
  const searchSuggestions = useMemo(() => {
    const filteredItems = debouncedSearchQuery.trim() !== ""
      ? meetings.filter(item => 
          item.title && item.title.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
        )
      : meetings;

    const uniqueSubjectsMap = new Map();
    filteredItems.forEach((item) => {
      if (item.title && !uniqueSubjectsMap.has(item.title)) {
        uniqueSubjectsMap.set(item.title, item.id);
      }
    });

    return Array.from(uniqueSubjectsMap.entries()).map(([title, id]) => ({
      id,
      label: title,
    }));
  }, [meetings, debouncedSearchQuery]);

  // Filter and sort meetings
  const filteredAndSortedMeetings = useMemo(() => {
    let filtered = [...meetings];

    if (debouncedSearchQuery.trim() !== "") {
      const query = debouncedSearchQuery.toLowerCase();
      filtered = filtered.filter(
        (item) =>
          (item.title && item.title.toLowerCase().includes(query)) ||
          (item.description && item.description.toLowerCase().includes(query)) ||
          (item.attendees && item.attendees.some(att =>
            att.email.toLowerCase().includes(query) ||
            att.name.toLowerCase().includes(query)
          ))
      );
    }

    if (sortConfig.key) {
      filtered.sort((a, b) => {
        let aVal = a[sortConfig.key];
        let bVal = b[sortConfig.key];

        if (sortConfig.key === 'startTime' || sortConfig.key === 'endTime') {
          aVal = new Date(aVal);
          bVal = new Date(bVal);
        }

        if (aVal < bVal) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (aVal > bVal) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }

    return filtered;
  }, [meetings, debouncedSearchQuery, sortConfig]);

  // Get paginated data
  const paginatedMeetings = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredAndSortedMeetings.slice(startIndex, endIndex);
  }, [filteredAndSortedMeetings, currentPage]);

  // Get row icon based on meeting type
  const getRowIcon = useCallback(() => {
    return CalendarDays;
  }, []);

  // Format location with Zoom support
  const renderLocation = (row) => {
    const isZoom = row.location?.toLowerCase().includes('zoom') || 
                  row.description?.toLowerCase().includes('zoom');
    const hasLink = row.location?.includes('http') || row.hangoutLink;

    if (hasLink || isZoom) {
      const meetingLink = row.location?.includes('http') ? 
        row.location : 
        (row.hangoutLink || row.location);
        
      return (
        <HoverBox
          icon={Video}
          hoverIcon={FaExpandAlt}
          onClick={() => window.open(meetingLink, '_blank')}
          tooltipLabel="Join Meeting"
        >
          <Text>{isZoom ? 'Zoom Meeting' : 'Google Meet'}</Text>
        </HoverBox>
      );
    }

    if (row.location) {
      return (
        <Box display="flex" alignItems="center">
          <Icon as={MapPin} color="gray.500" boxSize={4} mr={2} />
          <Text>{row.location}</Text>
        </Box>
      );
    }

    return <Text>No location</Text>;
  };

  // Table columns configuration
  const columns = useMemo(() => [
    {
      key: 'title',
      label: 'Meeting',
      minWidth: '200px',
      maxWidth: '40%',
      sortable: true,
      isFirst: true,
    },
    {
      key: 'location',
      label: 'Location',
      minWidth: '120px',
      maxWidth: '20%',
      render: renderLocation
    },
    {
      key: 'attendees',
      label: 'Attendees',
      minWidth: '150px',
      maxWidth: '25%',
      render: (row) => {
        // Sort attendees: accepted first, then others
        const sortedAttendees = [...(row.attendees || [])].sort((a, b) => {
          const getResponsePriority = (response) => {
            switch (response?.toLowerCase()) {
              case 'accepted': return 0;
              case 'tentative': return 1;
              case 'declined': return 2;
              default: return 3;
            }
          };
          return getResponsePriority(a.response) - getResponsePriority(b.response);
        });

        return (
          <HStack spacing={2} wrap="wrap">
            {sortedAttendees.map((att, index) => {
              const email = att.email;
              const response = att.response?.toLowerCase() || 'needsaction';
              const name = att.name || email?.split('@')[0];
              const displayName = capitalizeFirstLetter(name);
              const role = att.organizer
                ? 'Organizer'
                : att.optional
                ? 'Optional'
                : 'Attendee';
              
              if (!email) return null;

              const formatResponse = (resp) => {
                switch(resp) {
                  case 'needsaction': return 'Needs Action';
                  default: return capitalizeFirstLetter(resp);
                }
              };
              
              return (
                <Tooltip 
                  key={index} 
                  label={`${role} • ${formatResponse(response)} • ${email}`}
                  placement="top"
                >
                  <HStack spacing={2}>
                    <Avatar
                      size="xs"
                      name={displayName}
                      bg={
                        response === 'accepted'
                          ? 'blue.200'
                          : response === 'tentative'
                          ? 'purple.200'
                          : response === 'declined'
                          ? 'red.200'
                          : 'gray.200'
                      }
                      color={useColorModeValue("gray.700", "white")}
                    />
                  </HStack>
                </Tooltip>
              );
            })}
          </HStack>
        );
      }
    },
    {
      key: 'duration',
      label: 'Duration',
      width: '15%',
      render: (row) => {
        const startTime = DateTime.fromISO(row.startTime);
        const endTime = DateTime.fromISO(row.endTime);
        const durationInMinutes = Math.round(endTime.diff(startTime, 'minutes').minutes);
        return <Text>{durationInMinutes} min</Text>;
      },
    },
    {
      key: 'startTime',
      label: 'Start Time',
      width: '15%',
      sortable: true,
      format: (value) => DateTime.fromISO(value).toFormat('MMM dd, yyyy h:mm a')
    },
  ], [capitalizeFirstLetter, renderLocation]);

  // Handle sorting
  const handleSort = useCallback((key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'ascending' 
        ? 'descending' 
        : 'ascending'
    }));
  }, []);

  // Handle row click to view meeting details
  const handleViewDetails = useCallback((meeting) => {
    navigate(`/admin/meetings/${meeting.id}`);
  }, [navigate]);

  // Handle segment change
  const handleSegmentChange = (newSegment) => {
    setSelectedSegment(newSegment);
    setCurrentPage(1);
  };

  // Handle suggestion selection
  const handleSuggestionSelect = useCallback(
    (itemId) => {
      const selectedMeeting = meetings.find((meeting) => meeting.id === itemId);
      if(selectedMeeting) {
        handleViewDetails(selectedMeeting);
      }
    },
    [meetings, handleViewDetails]
  );

  // Segment options
  const segmentOptions = [
    { label: 'Upcoming', value: 'Upcoming' },
    { label: 'Recorded', value: 'Recorded' },
    { label: 'All', value: 'All' }
  ];

  if (loading) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
        <TableSkeleton rowCount={10} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
        <Text color="red.500">Error: {error}</Text>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
      <PageHeader
        title="Meetings"
        segmentOptions={segmentOptions}
        selectedSegment={selectedSegment}
        onSegmentChange={handleSegmentChange}
        searchPlaceholder="Search meetings..."
        onSearchChange={setSearchQuery}
        searchQuery={searchQuery}
        searchSuggestions={searchSuggestions}
        onSuggestionSelect={handleSuggestionSelect}
      />

      {filteredAndSortedMeetings.length === 0 ? (
        <Box p={4}>
          <Text color="gray.900">No {selectedSegment.toLowerCase()} meetings</Text>
        </Box>
      ) : (
        <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
          <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} bg="white">
            <DataTable
              data={paginatedMeetings}
              columns={columns}
              onSort={handleSort}
              sortConfig={sortConfig}
              searchQuery={debouncedSearchQuery}
              onRowClick={handleViewDetails}
              rowIcon={getRowIcon}
              hoverIcon={FaExpandAlt}
              rowTooltip="View Meeting Details"
            />
          </Box>
        </SimpleGrid>
      )}

      <PaginationControls
        currentPage={currentPage}
        totalPages={Math.ceil(filteredAndSortedMeetings.length / ITEMS_PER_PAGE)}
        onPrevious={() => setCurrentPage(prev => Math.max(1, prev - 1))}
        onNext={() => setCurrentPage(prev => Math.min(Math.ceil(filteredAndSortedMeetings.length / ITEMS_PER_PAGE), prev + 1))}
      />
    </Box>
  );
};

export default MeetingsView;
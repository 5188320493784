import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Wrap, WrapItem, Flex, Icon, Tooltip } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaBuilding, FaExpandAlt } from 'react-icons/fa';

/**
 * AccountTag Component
 * Displays an account with hover effects and click functionality.
 */
const AccountTag = ({ accountName, onClick }) => (
  <Tooltip label="View Account Details" placement="top">
    <Flex
      align="center"
      bg="gray.50"
      color="black"
      px={3}
      py={2}
      borderRadius="md"
      borderWidth="1px"
      borderColor="gray.200"
      cursor="pointer"
      onClick={onClick}
      role="group"
      _hover={{
        bg: "gray.100",
      }}
      height="40px"
      minWidth="120px"
    >
      {/* Building Icon - visible by default, hidden on hover */}
      <Icon
        as={FaBuilding}
        mr={2}
        _groupHover={{ display: 'none' }}
        color="gray.500"
        boxSize={4}
      />
      {/* FaExpandAlt Icon - hidden by default, visible on hover */}
      <Icon
        as={FaExpandAlt}
        mr={2}
        display="none"
        _groupHover={{ display: 'block', color: 'blue.500' }}
        boxSize={4}
      />
      <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
        {accountName || 'Unnamed Account'}
      </Text>
    </Flex>
  </Tooltip>
);

AccountTag.propTypes = {
  accountName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

/**
 * DealAccountsSection Component
 * Displays CRM account information associated with a deal.
 */
const DealAccountsSection = ({ deal }) => {
  const navigate = useNavigate();

  const handleViewDetails = (accountId) => {
    navigate(`/admin/accounts/${accountId}`);
  };

  if (!deal?.crm_account_id || !deal?.crm_account_name) {
    return null;
  }

  const account = {
    id: deal.crm_account_id,
    name: deal.crm_account_name,
  };

  return (
    <Box>
      <Text fontSize="md" fontWeight="semibold" mb={3}>
        Accounts
      </Text>
      <Wrap spacing={4}>
        <WrapItem>
          <AccountTag
            accountName={account.name}
            onClick={() => handleViewDetails(account.id)}
          />
        </WrapItem>
      </Wrap>
    </Box>
  );
};

DealAccountsSection.propTypes = {
  deal: PropTypes.shape({
    crm_account_id: PropTypes.number,
    crm_account_name: PropTypes.string,
  }),
};

DealAccountsSection.defaultProps = {
  deal: null,
};

export default DealAccountsSection; 
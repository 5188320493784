import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuthData } from "../../../auth-context";

export function useFetchAccountById(accountID, accountId) {
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessToken } = useAuthData();
  const { user } = useAuth0();

  useEffect(() => {
    const fetchAccount = async () => {
      if (!accountID || !accountId) {
        setLoading(false);
        return;
      }

      try {
        const accessToken = await getAccessToken();

        const target =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        // Updated endpoint to include user_id from Auth0
        const response = await axios.get(
          `${target}/accounts/${accountID}/users/${user.sub}/crm-accounts/${accountId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            }
          }
        );

        setAccount(response.data);
        setError(null);
      } catch (error) {
        console.error("Error fetching account:", error);
        setError(error);
        setAccount(null);
      } finally {
        setLoading(false);
      }
    };

    fetchAccount();
  }, [accountID, accountId, user?.sub, getAccessToken]);

  return { account, loading, error };
}

export default useFetchAccountById;
// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Accounts/index.jsx
import React, { useCallback, useState, useMemo, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { useAuthData } from "auth-context";
import AccountsTable from "./AccountsTable";
import PaginationControls from "../Shared/PaginationControls";
import PageHeader from "../Shared/PageHeader";
import TableSkeleton from "../Shared/TableSkeleton";
import useFetchAccounts from "../hooks/useFetchAccounts";
import { useDebounce } from 'use-debounce';

const ITEMS_PER_PAGE = 10;

const Accounts = () => {
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const navigate = useNavigate();
  const { accounts, isLoading, isError } = useFetchAccounts(accountID, "Lead");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  // Sorting state - Default sort by 'last_activity_date' descending
  const [sortConfig, setSortConfig] = useState({ 
    key: 'last_activity_date', 
    direction: 'descending' 
  });

  // Filter states
  const [currentStatusFilter, setCurrentStatusFilter] = useState('active');
  const [searchQuery, setSearchQuery] = useState('');

  // Debounce the search query
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  // Generate search suggestions based on account names mapped to account IDs
  const searchSuggestions = useMemo(() => {
    return accounts
      .filter(account => 
        account.name && 
        account.name.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
      )
      .map(account => ({
        id: account.id,
        label: account.name
      }));
  }, [accounts, debouncedSearchQuery]);

  // Handle sorting
  const handleSort = (key) => {
    setSortConfig((prevState) => {
      if (prevState.key === key) {
        return {
          key,
          direction: prevState.direction === 'ascending' ? 'descending' : 'ascending',
        };
      }
      return { key, direction: 'ascending' };
    });
  };

  // Helper function to determine if an account is active (has activity within last two weeks)
  const isActive = (dateString) => {
    if (!dateString) return false;
    const lastActivityDate = new Date(dateString);
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    return lastActivityDate >= twoWeeksAgo;
  };

  // Enhance accounts with isActive flag
  const enhancedAccounts = useMemo(() => {
    return accounts.map(account => ({
      ...account,
      isActive: isActive(account.last_activity_date),
    }));
  }, [accounts]);

  // Filter accounts
  const filteredAccounts = useMemo(() => {
    let filtered = enhancedAccounts;

    // Status Filter based on last_activity_date
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

    if (currentStatusFilter === 'active') {
      filtered = filtered.filter((account) => {
        if (!account.last_activity_date) return false;
        const lastActivity = new Date(account.last_activity_date);
        return lastActivity >= twoWeeksAgo;
      });
    } else if (currentStatusFilter === 'inactive') {
      filtered = filtered.filter((account) => {
        // Accounts with no last_activity_date are considered inactive
        if (!account.last_activity_date) return true;
        const lastActivity = new Date(account.last_activity_date);
        return lastActivity < twoWeeksAgo;
      });
    }
    // 'all' shows all accounts, no filtering needed

    if (debouncedSearchQuery.trim() !== "") {
      const query = debouncedSearchQuery.toLowerCase();
      filtered = filtered.filter(account =>
        (account.name && account.name.toLowerCase().includes(query)) ||
        (account.website && account.website.toLowerCase().includes(query)) ||
        (account.city && account.city.toLowerCase().includes(query))
      );
    }

    return filtered;
  }, [enhancedAccounts, currentStatusFilter, debouncedSearchQuery]);

  // Sort accounts
  const sortedAccounts = useMemo(() => {
    if (!sortConfig.key) return filteredAccounts;

    const sorted = [...filteredAccounts].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Special handling for last_activity_date
      if (sortConfig.key === 'last_activity_date') {
        // Accounts with no last_activity_date should be sorted last
        if (!aValue && !bValue) return 0;
        if (!aValue) return 1;
        if (!bValue) return -1;
        return new Date(bValue) - new Date(aValue); // Newest first by default
      }

      // Handle different data types
      if (typeof aValue === 'string') aValue = aValue.toLowerCase();
      if (typeof bValue === 'string') bValue = bValue.toLowerCase();

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return aValue - bValue;
      }
      if (Date.parse(aValue) && Date.parse(bValue)) {
        return new Date(aValue) - new Date(bValue);
      }
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue);
      }
      return 0;
    });

    if (sortConfig.direction === 'ascending') {
      sorted.reverse(); // Reverse only if ascending is requested
    }
    return sorted;
  }, [filteredAccounts, sortConfig]);

  // Calculate total pages
  const totalPages = useMemo(() => {
    return Math.ceil(sortedAccounts.length / ITEMS_PER_PAGE) || 1;
  }, [sortedAccounts.length]);

  // Get current page's accounts
  const currentAccounts = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return sortedAccounts.slice(startIndex, endIndex);
  }, [currentPage, sortedAccounts]);

  // Navigation handlers
  const handleViewDetails = useCallback((account) => {
    if (account && account.id) {
      navigate(`/admin/accounts/${account.id}`);
    }
  }, [navigate]);

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  // Reset to first page when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredAccounts, sortConfig, debouncedSearchQuery]);

  // Move loading check before any data processing
  if (isLoading) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
        <TableSkeleton rowCount={10} />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} p={4} color="red.500">
        <Text>Failed to load accounts. Please try again later.</Text>
      </Box>
    );
  }

  if (!accounts || accounts.length === 0) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} p={4}>
        <Text>No accounts available.</Text>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
      <PageHeader
        title="Accounts"
        segmentOptions={[
          { label: "Active", value: "active" },
          { label: "Inactive", value: "inactive" },
          { label: "All", value: "all" }
        ]}
        selectedSegment={currentStatusFilter}
        onSegmentChange={setCurrentStatusFilter}
        searchPlaceholder="Search accounts..."
        onSearchChange={setSearchQuery}
        searchSuggestions={searchSuggestions}
        searchQuery={searchQuery}
        onSuggestionSelect={(accountId) => {
          const selectedAccount = accounts.find(account => account.id === accountId);
          if (selectedAccount) {
            handleViewDetails(selectedAccount);
          }
        }}
      />

      {/* Accounts Table */}
      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
          <AccountsTable
            accounts={currentAccounts}
            onSort={handleSort}
            sortConfig={sortConfig}
            onViewDetails={handleViewDetails}
            searchQuery={debouncedSearchQuery}
          />
        </Box>
      </SimpleGrid>

      {/* Pagination Controls */}
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPrevious={handlePreviousPage}
        onNext={handleNextPage}
      />
    </Box>
  );
};

export default Accounts;
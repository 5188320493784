// auth-context.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } =
    useAuth0();
  const [userData, setUserData] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const getUserAndOrgMetadata = async () => {
      if (isAuthenticated) {
        try {
          const [claims, token] = await Promise.all([
            getIdTokenClaims(),
            getAccessTokenSilently()
          ]);
          
          const { org_id, account_ID, flow_ID } = claims;
          setUserData({
            ...user,
            org_id,
            account_ID,
            flow_ID,
          });
          setAccessToken(token);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    getUserAndOrgMetadata();
  }, [isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims]);

  const getAccessToken = async () => {
    if (accessToken) return accessToken;
    
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      return token;
    } catch (error) {
      console.error('Error getting access token:', error);
      throw error;
    }
  };

  const contextValue = {
    ...userData,
    getAccessToken,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuthData = () => useContext(AuthContext);

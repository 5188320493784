// src/components/WinLossStats.js
import React, { useMemo } from 'react';
import { SimpleGrid, Box } from "@chakra-ui/react";
import StatCard from "../StatCard";
import { 
  MdAttachMoney,
  MdTrendingDown,
  MdPeopleAlt,
  MdPersonOff,
  MdStars,
  MdWarning
} from "react-icons/md";

// Define colors
const PRIMARY_COLOR = "#1A5BF6";

const filterDealsBySegment = (deals, segment) => {
  if (!segment || segment === "All") return deals;

  return deals.filter(deal => {
    const locations = deal.extra_fields?.No_of_Locations__c;
    if (locations === null || locations === undefined) return false;
    
    switch (segment) {
      case "SMB":
        return locations >= 1 && locations <= 20;
      case "Mid-Market":
        return locations > 20 && locations <= 200;
      case "Enterprise":
        return locations > 200;
      default:
        return true;
    }
  });
};

/**
 * WinLossStats Component
 * Renders statistic cards for wins, losses, win rates, etc.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.deals - The deals data from parent.
 * @param {number} props.selectedQuarter - Selected quarter for filtering (optional).
 * @param {string} props.selectedSegment - Selected segment for filtering.
 * @returns {JSX.Element} The rendered WinLossStats component.
 */
const WinLossStats = ({ deals, selectedQuarter, selectedSegment }) => {
  const metrics = useMemo(() => {
    if (!deals?.currentPeriod?.length || !deals?.prevPeriod?.length) {
      return {
        wonAmount: { value: 0, growth: 0 },
        lostAmount: { value: 0, growth: 0 },
        wonCount: { value: 0, growth: 0 },
        lostCount: { value: 0, growth: 0 },
        winRate: { value: 0, growth: 0 },
        lossRate: { value: 0, growth: 0 }
      };
    }

    const calculateMetrics = (periodDeals) => {
      // Filter deals by segment first
      const filteredDeals = filterDealsBySegment(periodDeals, selectedSegment);
      
      const wonDeals = filteredDeals.filter(deal => deal.is_won === true);
      const lostDeals = filteredDeals.filter(deal => deal.is_won === false && deal.is_closed === true);
      
      const wonAmount = wonDeals.reduce((sum, deal) => sum + (Number(deal.amount) || 0), 0);
      const lostAmount = lostDeals.reduce((sum, deal) => sum + (Number(deal.amount) || 0), 0);
      const wonCount = wonDeals.length;
      const lostCount = lostDeals.length;
      const totalDeals = wonCount + lostCount;
      const winRate = totalDeals > 0 ? (wonCount / totalDeals) * 100 : 0;
      const lossRate = totalDeals > 0 ? (lostCount / totalDeals) * 100 : 0;

      return {
        wonAmount,
        lostAmount,
        wonCount,
        lostCount,
        winRate,
        lossRate
      };
    };

    const currentMetrics = calculateMetrics(deals.currentPeriod);
    const prevMetrics = calculateMetrics(deals.prevPeriod);

    const calculateGrowth = (current, previous) => {
      if (previous === 0) return current > 0 ? 100 : 0;
      return ((current - previous) / previous) * 100;
    };

    return {
      wonAmount: {
        value: currentMetrics.wonAmount,
        growth: calculateGrowth(currentMetrics.wonAmount, prevMetrics.wonAmount)
      },
      lostAmount: {
        value: currentMetrics.lostAmount,
        growth: calculateGrowth(currentMetrics.lostAmount, prevMetrics.lostAmount)
      },
      wonCount: {
        value: currentMetrics.wonCount,
        growth: calculateGrowth(currentMetrics.wonCount, prevMetrics.wonCount)
      },
      lostCount: {
        value: currentMetrics.lostCount,
        growth: calculateGrowth(currentMetrics.lostCount, prevMetrics.lostCount)
      },
      winRate: {
        value: currentMetrics.winRate,
        growth: calculateGrowth(currentMetrics.winRate, prevMetrics.winRate)
      },
      lossRate: {
        value: currentMetrics.lossRate,
        growth: calculateGrowth(currentMetrics.lossRate, prevMetrics.lossRate)
      }
    };
  }, [deals, selectedSegment]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const segmentLabel = selectedSegment === "All" ? "" : ` (${selectedSegment})`;

  return (
    <Box p={0}>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} gap="20px">
        <StatCard
          icon={MdAttachMoney}
          title={`Won${segmentLabel}`}
          value={formatCurrency(metrics.wonAmount.value)}
          growth={metrics.wonAmount.growth}
          timeRange={selectedQuarter ? 'QoQ' : 'YoY'}
          tooltip={`Won Amount ${selectedQuarter ? 'This Quarter' : 'This Year'}: ${formatCurrency(metrics.wonAmount.value)} vs ${selectedQuarter ? 'Last Quarter' : 'Last Year'}: ${formatCurrency(metrics.wonAmount.value - (metrics.wonAmount.growth * metrics.wonAmount.value) / 100)}`}
          primaryColor={PRIMARY_COLOR}
        />
        <StatCard
          icon={MdPeopleAlt}
          title={`Won Count${segmentLabel}`}
          value={metrics.wonCount.value}
          growth={metrics.wonCount.growth}
          timeRange={selectedQuarter ? 'QoQ' : 'YoY'}
          tooltip={`Won Deals ${selectedQuarter ? 'This Quarter' : 'This Year'}: ${metrics.wonCount.value} vs ${selectedQuarter ? 'Last Quarter' : 'Last Year'}: ${metrics.wonCount.value - metrics.wonCount.growth * metrics.wonCount.value / 100}`}
          primaryColor={PRIMARY_COLOR}
        />
        <StatCard
          icon={MdStars}
          title={`Win Rate${segmentLabel}`}
          value={`${metrics.winRate.value.toFixed(1)}%`}
          growth={metrics.winRate.growth}
          timeRange={selectedQuarter ? 'QoQ' : 'YoY'}
          tooltip={`Win Rate ${selectedQuarter ? 'This Quarter' : 'This Year'}: ${metrics.winRate.value.toFixed(1)}% vs ${selectedQuarter ? 'Last Quarter' : 'Last Year'}: ${(metrics.winRate.value - metrics.winRate.growth).toFixed(1)}%`}
          primaryColor={PRIMARY_COLOR}
        />
        <StatCard
          icon={MdTrendingDown}
          title={`Lost${segmentLabel}`}
          value={formatCurrency(metrics.lostAmount.value)}
          growth={metrics.lostAmount.growth}
          timeRange={selectedQuarter ? 'QoQ' : 'YoY'}
          tooltip={`Lost Amount ${selectedQuarter ? 'This Quarter' : 'This Year'}: ${formatCurrency(metrics.lostAmount.value)} vs ${selectedQuarter ? 'Last Quarter' : 'Last Year'}: ${formatCurrency(metrics.lostAmount.value - (metrics.lostAmount.growth * metrics.lostAmount.value) / 100)}`}
          primaryColor={PRIMARY_COLOR}
        />
        <StatCard
          icon={MdPersonOff}
          title={`Lost Count${segmentLabel}`}
          value={metrics.lostCount.value}
          growth={metrics.lostCount.growth}
          timeRange={selectedQuarter ? 'QoQ' : 'YoY'}
          tooltip={`Lost Deals ${selectedQuarter ? 'This Quarter' : 'This Year'}: ${metrics.lostCount.value} vs ${selectedQuarter ? 'Last Quarter' : 'Last Year'}: ${metrics.lostCount.value - metrics.lostCount.growth * metrics.lostCount.value / 100}`}
          primaryColor={PRIMARY_COLOR}
        />
        <StatCard
          icon={MdWarning}
          title={`Loss Rate${segmentLabel}`}
          value={`${metrics.lossRate.value.toFixed(1)}%`}
          growth={metrics.lossRate.growth}
          timeRange={selectedQuarter ? 'QoQ' : 'YoY'}
          tooltip={`Loss Rate ${selectedQuarter ? 'This Quarter' : 'This Year'}: ${metrics.lossRate.value.toFixed(1)}% vs ${selectedQuarter ? 'Last Quarter' : 'Last Year'}: ${(metrics.lossRate.value - metrics.lossRate.growth).toFixed(1)}%`}
          primaryColor={PRIMARY_COLOR}
        />
      </SimpleGrid>
    </Box>
  );
};

export default WinLossStats;

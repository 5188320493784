// src/components/Inbox/InboxDetailsPage.jsx
import React, { useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Spinner, Center, Text, useToast } from "@chakra-ui/react";
import { useAuthData } from "auth-context";
import EmailFollowupDetail from "./EmailFollowupDetail";
import CallFollowupDetail from "./CallFollowupDetail";
import GoBackHeader from "../Shared/GoBackHeader";
import { useInboxItem } from "../hooks/useInboxItem";

// Mapping of task_subtype to corresponding detail components
const DETAIL_VIEW_COMPONENTS = {
  email: EmailFollowupDetail,
  call: CallFollowupDetail,
  // Add more mappings here as needed
};

const InboxDetailsPage = () => {
  const { inboxId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const userID = userData?.user_ID || "";
  const target =
    process.env.REACT_APP_NODE_ENV === "local"
      ? "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net"
      : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

  const {
    data: item,
    loading,
    error,
    handleAddAnnotation,
    handleArchiveToggle,
    handleSendMessage,
    handleMarkAsDone,
  } = useInboxItem(inboxId, accountID, userID, target);

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSend = async (messageData) => {
    try {
      console.log("Initiating send with data:", messageData); // Debug
      await handleSendMessage(messageData);
      // Toast is handled in EmailFollowupDetail.jsx
    } catch (error) {
      console.error(
        "Error in handleSend:",
        error.response ? error.response.data : error.message
      );
      toast({
        title: "Failed to send message",
        description:
          error.response?.data?.message ||
          error.message ||
          "An error occurred while sending the message.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAnnotation = async (annotation) => {
    try {
      await handleAddAnnotation(annotation);
      // Toast is handled in EmailFollowupDetail.jsx
    } catch (error) {
      console.error(
        "Error adding annotation:",
        error.response ? error.response.data : error.message
      );
      toast({
        title: "Failed to add note",
        description:
          error.response?.data?.message ||
          error.message ||
          "An error occurred while adding the note.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleArchive = async () => {
    try {
      await handleArchiveToggle();
      toast({
        title: `Thread ${
          item.status === "archived" ? "unarchived" : "archived"
        } successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(
        "Error toggling archive status:",
        error.response ? error.response.data : error.message
      );
      toast({
        title: "Failed to update archive status",
        description:
          error.response?.data?.message ||
          error.message ||
          "An error occurred while updating the archive status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const handleTaskDone = async (id) => {
    try {
      console.log("Initiating marking as done with data:", id); // Debug
      await handleMarkAsDone(id);
      // Toast is handled in EmailFollowupDetail.jsx
    } catch (error) {
      console.error(
        "Error in handleMarkAsDone:",
        error.response ? error.response.data : error.message
      );
      toast({
        title: "Failed to mark as done",
        description:
          error.response?.data?.message ||
          error.message ||
          "An error occurred while marking as done.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center h="100vh">
        <Text color="red.500">Error: {error}</Text>
      </Center>
    );
  }

  if (!item) {
    return (
      <Center h="100vh">
        <Text>No inbox item found.</Text>
      </Center>
    );
  }

  // Determine the title based on task type and subject
  const getTitle = () => {
    if (item.task_subtype === "call") {
      return "Call Follow-up Details";
    }
    return item.emailSubject || "Email Follow-up Details";
  };

  // Select the appropriate detail component
  const DetailComponent = DETAIL_VIEW_COMPONENTS[item.task_subtype];

  return (
    <Box pt={10} px={4}>
      {/* Go Back Header with Dynamic Title */}
      <GoBackHeader onGoBack={handleGoBack} title={getTitle()} />

      {/* Render the appropriate detail view based on task_subtype */}
      {DetailComponent ? (
        <DetailComponent
          item={item}
          onArchiveToggle={handleArchive}
          onSendMessage={handleSend}
          onAddAnnotation={handleAnnotation}
          onTaskDone={handleTaskDone}
        />
      ) : (
        <Text color="red.500">
          Unsupported task type: {item.task_subtype || "unknown"}
        </Text>
      )}
    </Box>
  );
};

export default InboxDetailsPage;

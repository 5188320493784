import { useMutation } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { useAuthData } from '../../../auth-context';

const useCreateDealFromSpark = () => {
  const { getAccessToken } = useAuthData();

  const createDeal = async ({ accountId, userId, formData, sparkData }) => {
    try {
      // Validate required data
      if (!sparkData?.crm_account_id) {
        console.error('Missing crm_account_id:', sparkData);
        throw new Error('No CRM Account ID found in spark data');
      }

      if (!sparkData?.thread_id) {
        console.error('Missing thread_id:', sparkData);
        throw new Error('No Thread ID found in spark data');
      }

      const crmAccountId = sparkData.crm_account_id;

      const accessToken = await getAccessToken();

      const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net';

      console.log('Making API call with:', {
        accountId,
        userId,
        crmAccountId,
        sparkData,
        formData
      });

      const response = await fetch(`${baseUrl}/accounts/${accountId}/users/${userId}/crm-accounts/${crmAccountId}/spark/add-deal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          // Company/Account information
          account: {
            name: formData.accountName,
            domain: formData.accountDomain,
            description: formData.accountDescription,
            industry: formData.industry,
            city: formData.city,
            state: formData.state,
            country: formData.country,
            address: formData.address,
            linkedin_url: formData.linkedIn,
          },
          
          // List of contacts associated with the deal
          contacts: formData.contacts.map(contact => ({
            name: contact.firstName || '',
            last_name: contact.lastName || '',
            email: contact.email || '',
            title: contact.title || '',
            department: contact.department || '',
            phone: contact.phone || '',
            linkedin_url: contact.linkedIn || '',
          })),
          
          // Deal information
          deal: {
            name: formData.dealName,
            source: formData.dealSource,
            stage_name: formData.stage || 'new_lead',
            description: formData.dealDescription,
            priority: formData.dealPriority,
            thread_id: sparkData.thread_id,
          }
        }),
      });

      let responseData;
      try {
        responseData = await response.json();
      } catch (e) {
        // If JSON parsing fails, try to get the text
        const text = await response.text();
        throw new Error(text || 'Failed to parse response');
      }

      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to create deal');
      }

      return responseData;
    } catch (error) {
      console.error('Error in useCreateDealFromSpark:', error);
      throw error;
    }
  };

  const mutation = useMutation({
    mutationFn: createDeal,
    onSuccess: () => {
      // Invalidate relevant queries to refetch data
      queryClient.invalidateQueries(['deals']);
      queryClient.invalidateQueries(['sparks']);
    },
  });

  return mutation;
};

export default useCreateDealFromSpark; 
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  HStack,
  Text,
  Progress,
  useToast,
  Box,
  Collapse,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import useCreateDealFromSpark from '../hooks/useCreateDealFromSpark';
import { useAuthData } from 'auth-context';

const AddDealModal = ({ isOpen, onClose, onSubmit, data, isLoading: isLoadingProp }) => {
  const userData = useAuthData();
  const createDealMutation = useCreateDealFromSpark();
  const isLoading = isLoadingProp || createDealMutation.isLoading;

  const [currentStep, setCurrentStep] = useState(0);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [formData, setFormData] = useState({
    // Account fields
    accountName: '',
    accountDomain: '',
    // Additional account fields
    accountDescription: '',
    industry: '',
    city: '',
    state: '',
    country: '',
    address: '',
    linkedIn: '',
    
    // Contact fields will be dynamically generated
    contacts: [],
    
    // Deal fields
    dealName: '',
    dealSource: '',
    // Additional deal fields
    stage: '',
    dealDescription: '',
    numProperties: '',
    dealPriority: '',
  });

  const toast = useToast();

  // Helper function to extract domain from email
  const getDomainFromEmail = (email) => {
    return email?.split('@')[1] || '';
  };

  // Helper function to get external contacts from spark data
  const getExternalContacts = () => {
    if (!data?.spark?.user_domain) return [];
    const userDomain = data.spark.user_domain;
    const contacts = new Set();

    // Check recipients
    if (data.spark.recipients) {
      data.spark.recipients.forEach(recipient => {
        const recipientDomain = getDomainFromEmail(recipient.email);
        if (recipientDomain !== userDomain) {
          contacts.add(JSON.stringify({
            email: recipient.email,
            name: recipient.name,
            domain: recipientDomain
          }));
        }
      });
    }

    // Check email activities
    if (data.spark?.activities) {
      data.spark.activities.forEach(activity => {
        if (activity.activity_data?.activity_type === 'email') {
          const senderEmail = activity.activity_data?.data?.sender_email;
          const senderName = activity.activity_data?.data?.sender_name;
          const senderDomain = getDomainFromEmail(senderEmail);

          if (senderDomain && senderDomain !== userDomain) {
            contacts.add(JSON.stringify({
              email: senderEmail,
              name: senderName,
              domain: senderDomain
            }));
          }

          // Check recipients in the email activity
          const recipients = activity.activity_data?.data?.recipients || [];
          recipients.forEach(recipient => {
            const recipientDomain = getDomainFromEmail(recipient.email);
            if (recipientDomain && recipientDomain !== userDomain) {
              contacts.add(JSON.stringify({
                email: recipient.email,
                name: recipient.name,
                domain: recipientDomain
              }));
            }
          });
        }
      });
    }

    return Array.from(contacts).map(contact => JSON.parse(contact));
  };

  // Initialize form data when API data is received
  useEffect(() => {
    if (!data?.spark) return;

    const contacts = getExternalContacts();

    // Get the most common external domain for company domain
    const domainCounts = contacts.reduce((acc, contact) => {
      acc[contact.domain] = (acc[contact.domain] || 0) + 1;
      return acc;
    }, {});
    const companyDomain = Object.entries(domainCounts)
      .sort(([,a], [,b]) => b - a)[0]?.[0] || '';

    setFormData(prev => ({
      ...prev,
      // Account fields
      accountName: data.spark.company_name || '',
      accountDomain: companyDomain,
      
      // Initialize contacts array with external contacts
      contacts: contacts.map(contact => {
        const [firstName = '', ...lastNameParts] = (contact.name || '').split(' ');
        return {
          firstName,
          lastName: lastNameParts.join(' '),
          email: contact.email,
          skipped: false,
        };
      }),
      
      // Deal fields
      dealName: data.spark.company_name || '',
      dealSource: 'email',
    }));
  }, [data]);

  const handleInputChange = (e, contactIndex = null) => {
    const { name, value } = e.target;
    
    if (contactIndex !== null) {
      setFormData(prev => ({
        ...prev,
        contacts: prev.contacts.map((contact, idx) => 
          idx === contactIndex ? { ...contact, [name]: value } : contact
        ),
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const shouldShowSkipButton = () => {
    const currentStepData = getCurrentStepData();
    if (currentStepData.type !== 'contact') return false;
    
    const currentIndex = currentStepData.index;
    const isLastContact = currentIndex === formData.contacts.length - 1;
    
    // Count how many non-skipped contacts we have excluding the current one
    const nonSkippedContactsCount = formData.contacts.filter((contact, idx) => 
      !contact.skipped && idx !== currentIndex
    ).length;

    // Allow skip if:
    // 1. Not the last contact, OR
    // 2. Is the last contact but we have other non-skipped contacts
    return !isLastContact || nonSkippedContactsCount > 0;
  };

  const validateStep = () => {
    const currentStepData = getCurrentStepData();
    const missingFields = [];

    if (currentStepData.type === 'account') {
      if (!formData.accountName) missingFields.push('Company Name');
      if (!formData.accountDomain) missingFields.push('Company Domain');
    } else if (currentStepData.type === 'contact') {
      const contact = formData.contacts[currentStepData.index];
      // Always validate required fields for contacts, regardless of skip button visibility
      if (!contact.firstName) missingFields.push('First Name');
      if (!contact.lastName) missingFields.push('Last Name');
      if (!contact.email) missingFields.push('Email');
    } else if (currentStepData.type === 'deal') {
      if (!formData.dealName) missingFields.push('Deal Name');
      if (!formData.dealSource) missingFields.push('Deal Source');
      
      // Validate that at least one contact is not skipped before allowing deal creation
      const hasAtLeastOneContact = formData.contacts.some(contact => !contact.skipped);
      if (!hasAtLeastOneContact) {
        toast({
          title: 'Contact Required',
          description: 'At least one contact must be added to create a deal',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    }

    if (missingFields.length > 0) {
      toast({
        title: 'Required Fields Missing',
        description: `Please fill in: ${missingFields.join(', ')}`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  const getCurrentStepData = () => {
    if (currentStep === 0) return { type: 'account' };
    if (currentStep === formData.contacts.length + 1) return { type: 'deal' };
    return { type: 'contact', index: currentStep - 1 };
  };

  const getTotalSteps = () => {
    return formData.contacts.length + 2; // Account + Contacts + Deal
  };

  const handleNext = () => {
    if (validateStep()) {
      setCurrentStep(prev => prev + 1);
      setShowAdditionalFields(false);
    }
  };

  const handleBack = () => {
    setCurrentStep(prev => prev - 1);
    setShowAdditionalFields(false);
  };

  const handleSkipContact = () => {
    const currentContactIndex = currentStep - 1;
    setFormData(prev => ({
      ...prev,
      contacts: prev.contacts.map((contact, idx) => 
        idx === currentContactIndex ? { ...contact, skipped: true } : contact
      ),
    }));
    // Skip validation and move to next step
    setCurrentStep(prev => prev + 1);
    setShowAdditionalFields(false);
  };

  const handleSubmit = async () => {
    if (!validateStep()) return;

    try {
      // Filter out skipped contacts
      const filteredFormData = {
        ...formData,
        contacts: formData.contacts.filter(contact => !contact.skipped),
        dealPriority: formData.dealPriority,
      };

      // Validate required spark data
      if (!data?.crm_account_id) {
        throw new Error('No CRM Account ID found in spark data');
      }

      if (!data?.spark?.thread_id) {
        throw new Error('No Thread ID found in spark data');
      }

      // Call the mutation
      await createDealMutation.mutateAsync({
        accountId: userData.account_ID,
        userId: userData.user_ID,
        formData: filteredFormData,
        sparkData: {
          thread_id: data.spark.thread_id,
          crm_account_id: data.crm_account_id,
        },
      });

      // Call the original onSubmit if provided
      if (onSubmit) {
        onSubmit(filteredFormData);
      }

      // Show success message
      toast({
        title: 'Deal Created',
        description: 'The deal has been successfully created',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      console.error('Submit error:', error, 'Data:', data); // Add debug logging
      toast({
        title: 'Error Creating Deal',
        description: error.message || 'Failed to create deal. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const renderAccountForm = () => (
    <VStack spacing={4} align="stretch">
      <Text fontSize="sm" color="gray.600" mb={2}>
        Enter the company or organization&apos;s details that this deal is associated with.
      </Text>
      
      <FormControl isRequired>
        <FormLabel>Company Name</FormLabel>
        <Input
          name="accountName"
          value={formData.accountName}
          onChange={handleInputChange}
          placeholder="Enter company name"
          isDisabled={isLoading}
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Company Domain</FormLabel>
        <Input
          name="accountDomain"
          value={formData.accountDomain}
          onChange={handleInputChange}
          placeholder="example.com"
          isDisabled={isLoading}
        />
      </FormControl>

      <Button
        rightIcon={showAdditionalFields ? <ChevronUpIcon /> : <ChevronDownIcon />}
        onClick={() => setShowAdditionalFields(!showAdditionalFields)}
        variant="ghost"
        size="sm"
        width="fit-content"
      >
        {showAdditionalFields ? 'Hide' : 'Show'} Additional Fields
      </Button>

      <Collapse in={showAdditionalFields}>
        <VStack spacing={4} align="stretch" mt={4}>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Input
              name="accountDescription"
              value={formData.accountDescription}
              onChange={handleInputChange}
              placeholder="Brief description of the company"
              isDisabled={isLoading}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Industry</FormLabel>
            <Select
              name="industry"
              value={formData.industry}
              onChange={handleInputChange}
              placeholder="Select industry"
              isDisabled={isLoading}
            >
              <option value="technology">Technology</option>
              <option value="healthcare">Healthcare</option>
              <option value="finance">Finance</option>
              <option value="retail">Retail</option>
              <option value="manufacturing">Manufacturing</option>
              <option value="other">Other</option>
            </Select>
          </FormControl>

          <HStack spacing={4}>
            <FormControl>
              <FormLabel>City</FormLabel>
              <Input
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                placeholder="City"
                isDisabled={isLoading}
              />
            </FormControl>

            <FormControl>
              <FormLabel>State</FormLabel>
              <Input
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                placeholder="State"
                isDisabled={isLoading}
              />
            </FormControl>
          </HStack>

          <FormControl>
            <FormLabel>Country</FormLabel>
            <Input
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              placeholder="Country"
              isDisabled={isLoading}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Address</FormLabel>
            <Input
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              placeholder="Full address"
              isDisabled={isLoading}
            />
          </FormControl>

          <FormControl>
            <FormLabel>LinkedIn Profile</FormLabel>
            <Input
              name="linkedIn"
              value={formData.linkedIn}
              onChange={handleInputChange}
              placeholder="LinkedIn company profile URL"
              isDisabled={isLoading}
            />
          </FormControl>
        </VStack>
      </Collapse>
    </VStack>
  );

  const renderContactForm = (contactIndex) => {
    const contact = formData.contacts[contactIndex];
    return (
      <VStack spacing={4} align="stretch">
        <Text fontSize="sm" color="gray.600" mb={2}>
          Enter contact information for {contact.email}
        </Text>
        
        <FormControl isRequired>
          <FormLabel>First Name</FormLabel>
          <Input
            name="firstName"
            value={contact.firstName}
            onChange={(e) => handleInputChange(e, contactIndex)}
            placeholder="First name"
            isDisabled={isLoading}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Last Name</FormLabel>
          <Input
            name="lastName"
            value={contact.lastName}
            onChange={(e) => handleInputChange(e, contactIndex)}
            placeholder="Last name"
            isDisabled={isLoading}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            name="email"
            type="email"
            value={contact.email}
            onChange={(e) => handleInputChange(e, contactIndex)}
            placeholder="contact@example.com"
            isDisabled={isLoading}
          />
        </FormControl>

        <Button
          rightIcon={showAdditionalFields ? <ChevronUpIcon /> : <ChevronDownIcon />}
          onClick={() => setShowAdditionalFields(!showAdditionalFields)}
          variant="ghost"
          size="sm"
          width="fit-content"
        >
          {showAdditionalFields ? 'Hide' : 'Show'} Additional Fields
        </Button>

        <Collapse in={showAdditionalFields}>
          <VStack spacing={4} align="stretch" mt={4}>
            <FormControl>
              <FormLabel>Phone</FormLabel>
              <Input
                name="phone"
                value={contact.phone || ''}
                onChange={(e) => handleInputChange(e, contactIndex)}
                placeholder="Phone number"
                isDisabled={isLoading}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input
                name="title"
                value={contact.title || ''}
                onChange={(e) => handleInputChange(e, contactIndex)}
                placeholder="Job title"
                isDisabled={isLoading}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Department</FormLabel>
              <Select
                name="department"
                value={contact.department || ''}
                onChange={(e) => handleInputChange(e, contactIndex)}
                placeholder="Select department"
                isDisabled={isLoading}
              >
                <option value="sales">Sales</option>
                <option value="marketing">Marketing</option>
                <option value="engineering">Engineering</option>
                <option value="finance">Finance</option>
                <option value="operations">Operations</option>
                <option value="other">Other</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>LinkedIn Profile</FormLabel>
              <Input
                name="linkedIn"
                value={contact.linkedIn || ''}
                onChange={(e) => handleInputChange(e, contactIndex)}
                placeholder="LinkedIn profile URL"
                isDisabled={isLoading}
              />
            </FormControl>
          </VStack>
        </Collapse>
      </VStack>
    );
  };

  const renderDealForm = () => (
    <VStack spacing={4} align="stretch">
      <Text fontSize="sm" color="gray.600" mb={2}>
        Enter the specific details about this deal opportunity.
      </Text>
      
      <FormControl isRequired>
        <FormLabel>Deal Name</FormLabel>
        <Input
          name="dealName"
          value={formData.dealName}
          onChange={handleInputChange}
          placeholder="Enter deal name"
          isDisabled={isLoading}
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Deal Source</FormLabel>
        <Select
          name="dealSource"
          value={formData.dealSource}
          onChange={handleInputChange}
          placeholder="Select deal source"
          isDisabled={isLoading}
        >
          <option value="linkedin">LinkedIn</option>
          <option value="instagram">Instagram</option>
          <option value="email">Email Campaign</option>
          <option value="website">Website</option>
          <option value="referral">Referral</option>
          <option value="other">Other</option>
        </Select>
      </FormControl>

      <FormControl isRequired>
            <FormLabel>Deal Priority</FormLabel>
            <Select
              name="dealPriority"
              value={formData.dealPriority}
              onChange={handleInputChange}
              placeholder="Select deal priority"
              isDisabled={isLoading}
            >
              <option value="P0">P0 (PMS)</option>
              <option value="P1">P1 (PM)</option>
              <option value="P2">P2 (Ad-hoc/Single Venue)</option>
            </Select>
          </FormControl>

      <Button
        rightIcon={showAdditionalFields ? <ChevronUpIcon /> : <ChevronDownIcon />}
        onClick={() => setShowAdditionalFields(!showAdditionalFields)}
        variant="ghost"
        size="sm"
        width="fit-content"
      >
        {showAdditionalFields ? 'Hide' : 'Show'} Additional Fields
      </Button>

      <Collapse in={showAdditionalFields}>
        <VStack spacing={4} align="stretch" mt={4}>
          <FormControl>
            <FormLabel>Stage</FormLabel>
            <Select
              name="stage"
              value={formData.stage}
              onChange={handleInputChange}
              placeholder="Select deal stage"
              isDisabled={isLoading}
            >
              <option value="new_lead">New Lead</option>
              <option value="qualifying">Qualifying</option>
              <option value="meeting_phase">Meeting Phase</option>
              <option value="negotiating">Negotiating</option>
              <option value="closing">Closing</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Description</FormLabel>
            <Input
              name="dealDescription"
              value={formData.dealDescription}
              onChange={handleInputChange}
              placeholder="Describe the deal opportunity"
              isDisabled={isLoading}
            />
          </FormControl>
        </VStack>
      </Collapse>
    </VStack>
  );

  const renderStepContent = () => {
    const stepData = getCurrentStepData();
    switch (stepData.type) {
      case 'account':
        return renderAccountForm();
      case 'contact':
        return renderContactForm(stepData.index);
      case 'deal':
        return renderDealForm();
      default:
        return null;
    }
  };

  const getStepTitle = () => {
    const stepData = getCurrentStepData();
    switch (stepData.type) {
      case 'account':
        return 'Account Details';
      case 'contact':
        return `Contact Information (${stepData.index + 1}/${formData.contacts.length})`;
      case 'deal':
        return 'Deal Information';
      default:
        return '';
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxH="90vh">
        <ModalHeader p={0}>
          <Box px={6} pt={6} pb={4}>
            <Text mb={4}>{getStepTitle()}</Text>
            <Progress
              value={(currentStep + 1) * (100 / getTotalSteps())}
              size="sm"
              colorScheme="blue"
              borderRadius="full"
              width="100%"
              isIndeterminate={isLoading}
            />
          </Box>
        </ModalHeader>
        
        <ModalBody overflowY="auto">
          {isLoading ? (
            <VStack spacing={4} align="stretch" py={4}>
              <Text textAlign="center" color="gray.500">Loading deal information...</Text>
            </VStack>
          ) : (
            renderStepContent()
          )}
        </ModalBody>

        <ModalFooter>
          <HStack spacing={4}>
            <Button variant="ghost" onClick={onClose} isDisabled={isLoading}>
              Cancel
            </Button>
            {currentStep > 0 && (
              <Button onClick={handleBack} isDisabled={isLoading}>
                Back
              </Button>
            )}
            {shouldShowSkipButton() && (
              <Button 
                onClick={handleSkipContact}
                colorScheme="red"
                variant="outline"
                isDisabled={isLoading}
              >
                Skip Contact
              </Button>
            )}
            {currentStep < getTotalSteps() - 1 ? (
              <Button colorScheme="blue" onClick={handleNext} isDisabled={isLoading}>
                Next
              </Button>
            ) : (
              <Button 
                colorScheme="blue" 
                onClick={handleSubmit} 
                isDisabled={isLoading}
                isLoading={createDealMutation.isLoading}
              >
                Create Deal
              </Button>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

AddDealModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  data: PropTypes.shape({
    crm_account_id: PropTypes.number.isRequired,
    spark: PropTypes.shape({
      thread_id: PropTypes.string.isRequired,
      user_domain: PropTypes.string.isRequired,
      company_name: PropTypes.string,
      recipients: PropTypes.arrayOf(PropTypes.shape({
        email: PropTypes.string.isRequired,
        name: PropTypes.string,
      })),
      activities: PropTypes.arrayOf(PropTypes.shape({
        activity_data: PropTypes.shape({
          activity_type: PropTypes.string.isRequired,
          data: PropTypes.shape({
            sender_email: PropTypes.string,
            sender_name: PropTypes.string,
            recipients: PropTypes.arrayOf(PropTypes.shape({
              email: PropTypes.string.isRequired,
              name: PropTypes.string,
            })),
          }),
        }),
      })),
    }).isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
};

export default AddDealModal; 
// src/components/Shared/PageHeader.jsx

import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  useColorModeValue,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  ButtonGroup,
} from "@chakra-ui/react";
import { Search } from "lucide-react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import PropTypes from "prop-types";

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const PageHeader = ({
  title,
  segmentOptions,
  selectedSegment,
  onSegmentChange,
  searchPlaceholder = "Search...",
  onSearchChange,
  searchSuggestions = [],
  searchQuery = "",
  onSuggestionSelect = null,
  showSearch = true,
  additionalControls = null,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);

  // Theme Colors
  const searchBg = useColorModeValue("white", "gray.600");
  const searchBorderColor = useColorModeValue("gray.300", "gray.500");
  const suggestionBg = useColorModeValue("white", "gray.700");
  const suggestionBorderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.100", "gray.600");
  const highlightBg = "blue.500";
  const highlightColor = "white";
  
  // Button group colors
  const selectedBg = useColorModeValue("blue.500", "blue.300");
  const selectedTextColor = useColorModeValue("white", "gray.800");
  const unselectedBg = useColorModeValue("white", "gray.600");
  const unselectedTextColor = useColorModeValue("gray.700", "gray.200");
  const buttonHoverBg = useColorModeValue("gray.200", "gray.500");

  const renderHighlightText = (text, query) => {
    if (!query) return text;
    const escapedQuery = escapeRegExp(query);
    const regex = new RegExp(`(${escapedQuery})`, "gi");
    const parts = text.split(regex);

    return (
      <Box as="span">
        {parts.map((part, index) =>
          regex.test(part) ? (
            <Box
              as="mark"
              bg={highlightBg}
              color={highlightColor}
              key={index}
              px={1}
              borderRadius="sm"
              whiteSpace="nowrap"
              display="inline"
            >
              {part}
            </Box>
          ) : (
            part
          )
        )}
      </Box>
    );
  };

  const handleSuggestionClick = (itemId) => {
    if (onSuggestionSelect) {
      onSuggestionSelect(itemId);
      setShowSuggestions(false);
    }
  };

  const handleBlur = () => {
    // Delay hiding suggestions to allow click events to register
    setTimeout(() => setShowSuggestions(false), 200);
  };

  const handleFocus = () => {
    if (searchQuery && searchSuggestions.length > 0) {
      setShowSuggestions(true);
    }
  };

  return (
    <Box w="100%" mb={6}>
      {/* Header */}
      <Flex
        flexDirection="column"
        mb="4"
        align={{ base: "flex-start", md: "stretch" }}
      >
        <Heading
          size="lg"
          color={useColorModeValue("gray.700", "white")}
          mb="4px"
        >
          {title}
        </Heading>
      </Flex>

      {/* Controls Container */}
      <Flex
        direction={{ base: "column", md: "row" }}
        gap={6}
        justify="space-between"
        align={{ base: "stretch", md: "center" }}
        w="100%"
      >
        {/* Left Controls: Segment Controls */}
        <Box>
          {segmentOptions && segmentOptions.length > 0 && (
            segmentOptions.length <= 3 ? (
              <ButtonGroup isAttached variant="outline" spacing={0}>
                {segmentOptions.map((option) => {
                  const isSelected = selectedSegment === option.value;
                  return (
                    <Button
                      key={option.value}
                      onClick={() => onSegmentChange(option.value)}
                      bg={isSelected ? selectedBg : unselectedBg}
                      color={isSelected ? selectedTextColor : unselectedTextColor}
                      _hover={{
                        bg: isSelected ? selectedBg : buttonHoverBg,
                        color: isSelected ? selectedTextColor : "inherit",
                      }}
                      _active={{
                        bg: isSelected ? selectedBg : buttonHoverBg,
                        color: isSelected ? selectedTextColor : "inherit",
                      }}
                      borderRadius="md"
                      minW="100px"
                      borderWidth="1px"
                      borderColor={useColorModeValue("gray.300", "gray.500")}
                    >
                      {option.label}
                    </Button>
                  );
                })}
              </ButtonGroup>
            ) : (
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  variant="outline"
                  bg={unselectedBg}
                  borderRadius="md"
                  borderColor={useColorModeValue("gray.300", "gray.500")}
                  _hover={{ bg: buttonHoverBg }}
                  color={unselectedTextColor}
                >
                  {segmentOptions.find(opt => opt.value === selectedSegment)?.label || "Select Option"}
                </MenuButton>
                <MenuList>
                  {segmentOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={() => onSegmentChange(option.value)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            )
          )}
        </Box>

        {/* Right Controls */}
        <Box flex="1">
          {additionalControls}
        </Box>

        {/* Search Input */}
        {showSearch && (
          <Box
            width={{ base: "100%", md: "300px" }}
            position="relative"
            flexBasis="auto"
          >
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon as={Search} color="gray.500" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder={searchPlaceholder}
                value={searchQuery}
                onChange={(e) => onSearchChange(e.target.value)}
                bg={searchBg}
                borderColor={searchBorderColor}
                onFocus={handleFocus}
                onBlur={handleBlur}
                size="md"
                fontWeight="400"
              />
            </InputGroup>
            {showSuggestions && searchQuery && (
              <Box
                position="absolute"
                top="100%"
                left="0"
                right="0"
                bg={suggestionBg}
                border="1px solid"
                borderColor={suggestionBorderColor}
                borderRadius="md"
                mt={1}
                zIndex={10}
                maxHeight="200px"
                overflowY="auto"
              >
                {searchSuggestions.length > 0 ? (
                  <List spacing={0}>
                    {searchSuggestions.map((suggestion) => (
                      <ListItem
                        key={suggestion.id}
                        px={4}
                        py={2}
                        cursor="pointer"
                        _hover={{
                          bg: hoverBg,
                        }}
                        onClick={() => handleSuggestionClick(suggestion.id)}
                      >
                        {renderHighlightText(suggestion.label, searchQuery)}
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Box px={4} py={2} color="gray.500">
                    No matching results found.
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
      </Flex>
    </Box>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  segmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedSegment: PropTypes.string.isRequired,
  onSegmentChange: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
  searchSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  searchQuery: PropTypes.string,
  onSuggestionSelect: PropTypes.func,
  showSearch: PropTypes.bool,
  additionalControls: PropTypes.node,
};

export default PageHeader;

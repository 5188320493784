// src/components/Contacts/hooks/useFetchContactById.js

import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthData } from "../../../../auth-context";

/**
 * Custom hook to fetch a contact by ID.
 * @param {string} accountID - The account ID.
 * @param {string} contactID - The contact ID.
 * @returns {Object} { contact, isLoading, isError }
 */
const useFetchContactById = (accountID, contactID) => {
  const [contact, setContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessToken } = useAuthData();

  useEffect(() => {
    const fetchContact = async () => {
      if (!accountID || !contactID) {
        setLoading(false);
        return;
      }

      try {
        const accessToken = await getAccessToken();

        const target =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        const response = await axios.get(
          `${target}/accounts/${accountID}/contacts/${contactID}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        setContact(response.data || null);
        setError(null);
      } catch (error) {
        console.error("Error fetching contact:", error);
        setContact(null);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchContact();
  }, [accountID, contactID, getAccessToken]);

  return { contact, isLoading: loading, isError: error };
};

export default useFetchContactById;

// src/components/LineFirst.js
import React, { useMemo, useState } from 'react';
import { Box, Flex, Text, Button, Icon, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import BarChart from "components/charts/BarChart";
import { MdBarChart, MdShowChart } from "react-icons/md";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../constants";
import { getDateRange } from "../../utils/getDateRange";

const LineFirst = ({ 
  lineData, 
  customOptions = {}, 
  selectedYear, 
  timeFilter,
  selectedSegment,
  hideToggle = false,
  ...rest 
}) => {
  const textColor = useColorModeValue("b_gray.300", "white");
  const iconColor = useColorModeValue("b_gray.300", "white");
  const bgButton = useColorModeValue("b_gray.100", "whiteAlpha.100");
  const bgHover = useColorModeValue({ bg: "b_gray.200" }, { bg: "whiteAlpha.50" });
  const bgFocus = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.100" });

  const [showLineChart, setShowLineChart] = useState(true);

  const handleButtonClick = () => {
    setShowLineChart(!showLineChart);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  // Define month names and their corresponding numbers
  const MONTHS = useMemo(() => [
    { label: "Jan", value: "Jan", number: 0 },
    { label: "Feb", value: "Feb", number: 1 },
    { label: "Mar", value: "Mar", number: 2 },
    { label: "Apr", value: "Apr", number: 3 },
    { label: "May", value: "May", number: 4 },
    { label: "Jun", value: "Jun", number: 5 },
    { label: "Jul", value: "Jul", number: 6 },
    { label: "Aug", value: "Aug", number: 7 },
    { label: "Sep", value: "Sep", number: 8 },
    { label: "Oct", value: "Oct", number: 9 },
    { label: "Nov", value: "Nov", number: 10 },
    { label: "Dec", value: "Dec", number: 11 },
  ], []);

  // Determine the date range based on the selected time filter
  useMemo(() => {
    // If the filter is a specific month, extract the month index
    const month = MONTHS.find(m => m.value === timeFilter);
    const monthIndex = month ? month.number : null;

    return getDateRange(timeFilter, selectedYear, monthIndex, MONTHS);
  }, [timeFilter, selectedYear, MONTHS]);

  // Force re-render when selectedYear, timeFilter, or selectedSegment changes
  const chartKey = useMemo(() => `${selectedYear}-${timeFilter}-${showLineChart}-${selectedSegment}`, [selectedYear, timeFilter, showLineChart, selectedSegment]);

  // Prepare chart series data with current and previous year
  const series = useMemo(() => {
    if (!lineData?.labels?.length || !Array.isArray(lineData?.data) || !lineData.data[0]) {
      return [{
        name: "No Data",
        data: []
      }];
    }

    // Add segment information to the label if a segment is selected
    const labels = lineData.labels.map(label => {
      if (selectedSegment && selectedSegment !== "All") {
        return `${label} (${selectedSegment})`;
      }
      return label;
    });
    
    // Create series for each year's data
    return lineData.data.map((yearData, index) => ({
      name: labels[index],
      data: yearData.map(value => Number(value) || 0)  // Ensure values are numbers
    }));
  }, [lineData, timeFilter, selectedSegment]);

  // Prepare chart configurations with merged custom options
  const defaultChartOptions = useMemo(() => ({
    chart: {
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: false,
      },
      height: '100%',
      parentHeightOffset: 0,
    },
    colors: [PRIMARY_COLOR, SECONDARY_COLOR, "#A0AEC0"],
    markers: {
      size: 5,
      colors: ["white"],
      strokeColors: [PRIMARY_COLOR, SECONDARY_COLOR, "#A0AEC0"],
      strokeWidth: 3,
      strokeOpacity: 1,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: (value) => formatCurrency(value)
      }
    },
    dataLabels: {
      enabled: false,
      background: {
        enabled: true,
        foreColor: '#000',
        padding: 4,
        borderRadius: 4,
        borderWidth: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
      },
      style: {
        colors: ['#000']
      }
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      type: "category",
      categories: Array.isArray(lineData?.options) ? lineData.options : [],
      labels: {
        style: {
          colors: "#A0AEC0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      labels: {
        formatter: (value) => formatCurrency(value),
        style: {
          colors: "#A0AEC0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
    },
    legend: {
      show: true,
      labels: {
        colors: "#A0AEC0",
      },
    },
    grid: {
      show: true,
      borderColor: "#E2E8F0",
      strokeDashArray: 3,
      yaxis: {
        lines: {
          show: true
        }
      },
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        horizontal: false,
      },
    },
  }), [lineData?.options, timeFilter]);

  const chartOptions = useMemo(() => ({
    ...defaultChartOptions,
    ...customOptions,
  }), [defaultChartOptions, customOptions, timeFilter]);

  // Check that `lineData` has the necessary properties and data is valid
  if (!lineData?.labels?.length || !Array.isArray(lineData?.data) || !lineData.data[0]) {
    return (
      <Card key={chartKey} justifyContent='center' align='center' direction='column' w='100%' h='100%' mb='0px' {...rest}>
        <Flex justify='space-between' ps='0px' pe='20px' pt='5px'>
          <Flex align='center' w='100%'>
            <Text me='auto' color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%'>
              {lineData?.title || "Chart Title"}
              {selectedSegment && selectedSegment !== "All" && ` (${selectedSegment})`}
            </Text>
            {!hideToggle && (
              <Button
                ms='auto'
                align='center'
                justifyContent='center'
                variant='active'
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                w='37px'
                h='37px'
                lineHeight='100%'
                borderRadius='10px'
                onClick={handleButtonClick}
                {...rest}
                aria-label="Toggle Chart Type"
              >
                <Icon as={showLineChart ? MdBarChart : MdShowChart} color={iconColor} w='24px' h='24px' />
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex w='100%' h='100%' flexDirection={{ base: "column", lg: "row" }}>
          <Box minH='260px' width='100%' mt='auto'>
            <Text color={textColor} fontSize='md' textAlign='center' p={4}>No chart data available</Text>
          </Box>
        </Flex>
      </Card>
    );
  }

  return (
    <Card key={chartKey} justifyContent='center' align='center' direction='column' w='100%' h='100%' mb='0px' {...rest}>
      <Flex justify='space-between' ps='0px' pe='20px' pt='5px'>
        <Flex align='center' w='100%'>
          <Text me='auto' color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%'>
            {lineData.title}
            {selectedSegment && selectedSegment !== "All" && ` (${selectedSegment})`}
          </Text>
          {!hideToggle && (
            <Button
              ms='auto'
              align='center'
              justifyContent='center'
              variant='active'
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              w='37px'
              h='37px'
              lineHeight='100%'
              borderRadius='10px'
              onClick={handleButtonClick}
              {...rest}
              aria-label="Toggle Chart Type"
            >
              <Icon as={showLineChart ? MdBarChart : MdShowChart} color={iconColor} w='24px' h='24px' />
            </Button>
          )}
        </Flex>
      </Flex>
      <Flex w='100%' h='100%' flexDirection={{ base: "column", lg: "row" }}>
        <Box width='100%' height='100%' mt='auto'>
          {showLineChart ? (
            <LineChart 
              key={`line-${chartKey}`}
              chartData={series}
              chartOptions={chartOptions} 
            />
          ) : (
            <BarChart 
              key={`bar-${chartKey}`}
              chartData={series}
              chartOptions={chartOptions} 
            />
          )}
        </Box>
      </Flex>
    </Card>
  );
};

export default LineFirst;
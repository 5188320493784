import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuthData } from '../../../auth-context';
import { CHART_COLORS } from '../Dashboard/constants';

const getPipelineId = (accountID) => {
  const pipelineMap = {
    "1": "1635",
    "2": "4",
    "4": "943",
    "3": "1832"
  };
  return pipelineMap[accountID] || "";
};

export const useDealCountSinceLastActivity = (accountID, selectedYear, selectedQuarter, selectedSegment, toast) => {
  const { getAccessToken } = useAuthData();

  const fetchData = async () => {
    if (!accountID) return null;

    const accessToken = await getAccessToken();
    const target = process.env.REACT_APP_API_URL || "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
    const url = `${target}/accounts/${accountID}/deal/deals-since-last-activity`;
    const pipeline_id = getPipelineId(accountID);

    const params = {
      pipeline_id,
      year: selectedYear,
      period: selectedQuarter ? 'quarter' : 'year',
      quarter: selectedQuarter,
      segment: selectedSegment !== 'All' ? selectedSegment : undefined
    };

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const response = await axios.get(url, { headers, params });
    return response.data;
  };

  const { data: rawData, isLoading, error } = useQuery({
    queryKey: ['dealCountSinceLastActivity', accountID, selectedYear, selectedQuarter, selectedSegment],
    queryFn: fetchData,
    enabled: !!accountID,
  });

  const processedData = useMemo(() => {
    if (!rawData || !Array.isArray(rawData)) return null;

    try {
      // Process the data
      const dealsByWeeks = {};

      rawData.forEach((deal) => {
        const days = deal.days_since_last_activity;

        if (days !== null && days !== undefined) {
          const weeks = Math.floor(days / 7); // Calculate weeks since last activity

          if (!dealsByWeeks[weeks]) {
            dealsByWeeks[weeks] = {
              count: 0,
              deals: [], // Store full deal info
            };
          }
          dealsByWeeks[weeks].count += 1;
          dealsByWeeks[weeks].deals.push(deal); // Store deal
        }
      });

      // Sort the week numbers
      const weekNumbers = Object.keys(dealsByWeeks)
        .map((week) => parseInt(week))
        .sort((a, b) => a - b)
        .slice(0, 20); // Limit to 20 weeks if necessary

      if (weekNumbers.length === 0) return null;

      // Prepare labels and data for the chart
      const labels = weekNumbers.map((week) => `${week + 1}`);
      const counts = weekNumbers.map((week) => dealsByWeeks[week].count);
      const dealsArray = weekNumbers.map((week) => dealsByWeeks[week].deals);

      // Prepare bar data
      return {
        labels: labels,
        datasets: [
          {
            label: "Number of Deals",
            data: counts,
            backgroundColor: CHART_COLORS.slice(0, labels.length)
          },
        ],
        title: "Deals by Weeks Since Last Activity",
        dealData: dealsArray,
      };
    } catch (err) {
      console.error("Error processing deal data:", err);
      toast({
        title: "Error",
        description: "Failed to process deal data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return null;
    }
  }, [rawData, toast]);

  return {
    data: processedData,
    loading: isLoading,
    error
  };
}; 
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  VStack,
  Text,
  Button,
  Flex,
  ButtonGroup,
  IconButton,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaChevronDown, FaChevronUp, FaPlus, FaTimes, FaFire, FaCalendar, FaUser, FaThermometerHalf } from 'react-icons/fa';
import { ExternalLink } from 'lucide-react';
import { OverviewTag } from '../Shared/Tags';
import { format } from 'date-fns';
import { ActivityItem } from '../Activities';
import AddDealModal from './AddDealModal';
import { useAuthData } from 'auth-context';
import { useDealCreationModal } from '../hooks/useDealCreationModal';

const SparkCard = ({ spark, onAddDeal, onIgnore }) => {
  const [isActivitiesExpanded, setIsActivitiesExpanded] = useState(false);
  const [isAddDealModalOpen, setIsAddDealModalOpen] = useState(false);
  const userData = useAuthData();
  const { isLoading, fetchModalData } = useDealCreationModal();
  
  const getSparkDetails = (score) => {
    if (score >= 0.9) {
      return { colorScheme: "red", label: "Hot", icon: FaFire };
    } else if (score >= 0.7) {
      return { colorScheme: "orange", label: "Warm", icon: FaThermometerHalf };
    }
    return { colorScheme: "blue", label: "Cool", icon: FaThermometerHalf };
  };
  
  const getExternalCrmAccountId = () => {
    if (!spark.recipients || !Array.isArray(spark.recipients)) {
      return null;
    }
    const externalRecipient = spark.recipients.find(recipient => {
      const recipientDomain = recipient.email.split('@')[1];
      return recipientDomain !== spark.user_domain;
    });
    return externalRecipient?.crm_account_id;
  };

  const handleOpenModal = async () => {
    
    // Try to get CRM account ID but don't require it
    const crmAccountId = getExternalCrmAccountId();
    
    try {
      // Only try to fetch modal data if we have a CRM account ID
      if (crmAccountId) {
        await fetchModalData(userData.account_ID, userData.user_ID, crmAccountId);
      }
      
      // Always open the modal
      setIsAddDealModalOpen(true);
      
    } catch (err) {
      console.error('Error fetching deal creation data:', err);
      // Still open the modal
      setIsAddDealModalOpen(true);
    }
  };
  
  const getFormattedDate = (timestamp) => {
    try {
      if (!timestamp) return 'No date';
      const date = new Date(timestamp);
      // Check if date is valid
      if (isNaN(date.getTime())) return 'Invalid date';
      return format(date, 'MMM d, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  const getDomainFromParticipants = (spark) => {
    try {
      // Get the user's domain to exclude it
      const userDomain = spark.user_domain?.toLowerCase();
      
      // First check recipients array
      const recipientDomain = spark.recipients?.find(recipient => {
        const domain = recipient.email.split('@')[1]?.toLowerCase();
        return domain && domain !== userDomain;
      })?.email.split('@')[1]?.split('.')[0];

      if (recipientDomain) {
        return recipientDomain.charAt(0).toUpperCase() + recipientDomain.slice(1);
      }

      // If no domain found in recipients, check all email activities
      const externalDomain = spark.activities?.reduce((foundDomain, activity) => {
        if (foundDomain) return foundDomain;
        if (activity.activity_data?.activity_type !== 'email') return foundDomain;
        
        // Check sender email
        const senderDomain = activity.activity_data?.data?.sender_email?.split('@')[1]?.toLowerCase();
        if (senderDomain && senderDomain !== userDomain) {
          return senderDomain.split('.')[0];
        }

        // Check recipients in the email activity
        const activityRecipientDomain = activity.activity_data?.data?.recipients?.find(recipient => {
          const domain = recipient.email.split('@')[1]?.toLowerCase();
          return domain && domain !== userDomain;
        })?.email.split('@')[1]?.split('.')[0];

        return activityRecipientDomain;
      }, null);

      if (externalDomain) {
        return externalDomain.charAt(0).toUpperCase() + externalDomain.slice(1);
      }

      // As a last resort, try to extract domain from the subject if it contains a company name
      const subjectMatch = spark.activities?.[0]?.activity_data?.data?.subject?.match(/([^<>]+)\s*<>\s*([^<>]+)/);
      if (subjectMatch) {
        const [firstCompany, secondCompany] = subjectMatch;
        return firstCompany.trim() !== 'Lysto' ? firstCompany.trim() : secondCompany.trim();
      }

      return 'Unknown';
    } catch (error) {
      console.error('Error getting domain from participants:', error);
      return 'Unknown';
    }
  };

  const handleAddDeal = (formData) => {
    onAddDeal(formData);
    setIsAddDealModalOpen(false);
  };

  // Transform activities into the format expected by ActivityItem
  const transformedActivities = spark.activities?.map(activity => ({
    id: activity.id,
    account_id: activity.account_id,
    user_id: activity.user_id,
    owner_id: activity.user_id,
    user_name: activity.user_name,
    user_last_name: activity.user_last_name,
    user_email: activity.user_email,
    deal_id: activity.deal_id,
    activity_id: activity.activity_id,
    activity_type: activity.activity_data.activity_type,
    activity_date: activity.created_at,
    completed_date_time: activity.activity_data.data.received_at,
    status: "completed",
    title: activity.activity_data.data.subject,
    description: activity.activity_data.data.body,
    activity_data: {
      activity_type: activity.activity_data.activity_type,
      data: {
        body: activity.activity_data.data.body,
        received_at: activity.activity_data.data.received_at,
        recipients: activity.activity_data.data.recipients?.map(recipient => ({
          email: recipient.email,
          recipient_type: recipient.recipient_type
        })) || [],
        sender_name: activity.activity_data.data.sender_name,
        sender_email: activity.activity_data.data.sender_email,
        subject: activity.activity_data.data.subject
      },
      version: activity.activity_data.version
    },
    source_system: "email",
    timestamp: activity.activity_data.data.received_at,
    created_at: activity.created_at || activity.activity_data.data.received_at,
    updated_at: activity.updated_at || activity.activity_data.data.received_at,
    is_internal: activity.is_internal,
    classification: activity.classification,
    email_link: spark.email_link,
  })) || [];

  // Get the most recent email activity
  const lastEmailActivity = spark.activities?.find(activity => 
    activity.activity_data?.activity_type === 'email'
  );

  const sparkDetails = getSparkDetails(spark.spark_score);
  const formattedDate = getFormattedDate(lastEmailActivity?.activity_data?.data?.received_at);
  const senderEmail = lastEmailActivity?.activity_data?.data?.sender_email;
  const senderName = lastEmailActivity?.activity_data?.data?.sender_name;
  const companyName = getDomainFromParticipants(spark);

  const visibleActivities = isActivitiesExpanded ? transformedActivities : transformedActivities.slice(0, 3);
  const hasMoreActivities = transformedActivities.length > 3;

  // Get the thread_id from the first activity
  const getThreadId = (spark) => {
    if (!spark?.activities?.length) return null;
    return spark.activities[0]?.activity_data?.data?.client_thread_id;
  };

  return (
    <>
      <Box
        bg="white"
        p={6}
        borderRadius="md"
        boxShadow="sm"
        borderWidth="1px"
        borderColor="gray.200"
        _hover={{
          borderColor: "gray.300",
          boxShadow: "md",
        }}
        transition="all 0.2s"
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <VStack spacing={3} align="stretch" flex={1}>
          {/* Title Bar */}
          <Flex justify="space-between" align="center" mb={1}>
            <Flex align="center" gap={2}>
              <Text fontSize="lg" fontWeight="semibold" color="gray.700">
                {companyName} Deal
              </Text>
              {spark.email_link && (
                <Tooltip 
                  label="View email thread in Gmail" 
                  placement="top"
                  hasArrow
                >
                  <IconButton
                    as="a"
                    href={spark.email_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    icon={<ExternalLink size={18} />}
                    variant="ghost"
                    size="xs"
                    padding={0}
                    minW="auto"
                    height="auto"
                    color="gray.500"
                    _hover={{ color: "blue.500" }}
                    aria-label="View in Gmail"
                  />
                </Tooltip>
              )}
            </Flex>
            <ButtonGroup size="sm" spacing={2}>
              <Button
                leftIcon={<FaTimes />}
                variant="outline"
                colorScheme="gray"
                onClick={onIgnore}
                size="sm"
              >
                Ignore
              </Button>
              <Button
                leftIcon={<FaPlus />}
                variant="solid"
                colorScheme="blue"
                onClick={handleOpenModal}
                size="sm"
              >
                Add Deal
              </Button>
            </ButtonGroup>
          </Flex>

          {/* Subject */}
          {spark?.subject && (
            <Text fontSize="md" color="gray.700" fontWeight="medium" mt={-2}>
              Subject: {spark.subject}
            </Text>
          )}

          {/* Overview Tags */}
          <Flex gap={3} flexWrap="wrap">
            <OverviewTag
              icon={sparkDetails.icon}
              label="Confidence"
              value={`${Math.round(spark.spark_score * 100)}%`}
              colorScheme={sparkDetails.colorScheme}
            />
            {senderEmail && (
              <OverviewTag
                icon={FaUser}
                label="From"
                value={senderName || senderEmail.split('@')[0]}
                colorScheme="purple"
                tooltip={`${senderName || senderEmail.split('@')[0]} <${senderEmail}>`}
              />
            )}
            <OverviewTag
              icon={FaCalendar}
              label="Last Activity"
              value={formattedDate}
              colorScheme="green"
            />
          </Flex>

          {/* AI Recommendation Section */}
          <VStack spacing={2} align="stretch">
            <Text fontSize="md" fontWeight="semibold" color="gray.700">
              AI Recommendation
            </Text>
            <Box
              bg={useColorModeValue("gray.50", "gray.700")}
              p={4}
              borderRadius="md"
            >
              <Text fontSize="sm" color="gray.600" lineHeight="tall">
                {spark.reason}
              </Text>
            </Box>
          </VStack>

          {/* Activities Section */}
          <VStack spacing={2} align="stretch">
            <Text fontSize="md" fontWeight="semibold" color="gray.700">
              Previous Messages
            </Text>
            <Box>
              {visibleActivities.map((activity) => (
                <Box key={activity.id} mb={2}>
                  <ActivityItem activity={activity} />
                </Box>
              ))}
            </Box>
            {hasMoreActivities && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setIsActivitiesExpanded(!isActivitiesExpanded)}
                rightIcon={isActivitiesExpanded ? <FaChevronUp /> : <FaChevronDown />}
                alignSelf="center"
                mt={2}
              >
                {isActivitiesExpanded ? "Show Less" : `Show ${transformedActivities.length - 3} More`}
              </Button>
            )}
          </VStack>
        </VStack>
      </Box>

      <AddDealModal
        isOpen={isAddDealModalOpen}
        onClose={() => setIsAddDealModalOpen(false)}
        onSubmit={handleAddDeal}
        data={{
          crm_account_id: spark.crm_account_id,
          spark: {
            company_name: getDomainFromParticipants(spark),
            activities: spark.activities,
            user_domain: spark.user_domain,
            recipients: spark.recipients,
            thread_id: getThreadId(spark),
            sender: {
              email: lastEmailActivity?.activity_data?.data?.sender_email,
              name: lastEmailActivity?.activity_data?.data?.sender_name
            },
            subject: spark.subject,
            email_link: spark.email_link
          }
        }}
        isLoading={isLoading}
      />
    </>
  );
};

SparkCard.propTypes = {
  spark: PropTypes.shape({
    thread_id: PropTypes.string.isRequired,
    spark_score: PropTypes.number.isRequired,
    user_domain: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    email_link: PropTypes.string.isRequired,
    recipients: PropTypes.arrayOf(PropTypes.shape({
      crm_account_id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      recipient_type: PropTypes.oneOf(['to', 'cc', 'bcc']).isRequired,
    })).isRequired,
    activities: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      account_id: PropTypes.number.isRequired,
      user_id: PropTypes.number.isRequired,
      user_name: PropTypes.string.isRequired,
      user_last_name: PropTypes.string.isRequired,
      user_email: PropTypes.string.isRequired,
      deal_id: PropTypes.number.isRequired,
      activity_id: PropTypes.number.isRequired,
      activity_type: PropTypes.string.isRequired,
      activity_data: PropTypes.shape({
        activity_type: PropTypes.string.isRequired,
        data: PropTypes.shape({
          body: PropTypes.string.isRequired,
          client_thread_id: PropTypes.string.isRequired,
          received_at: PropTypes.string.isRequired,
          sender_email: PropTypes.string.isRequired,
          sender_name: PropTypes.string.isRequired,
          subject: PropTypes.string.isRequired,
        }).isRequired,
        version: PropTypes.string.isRequired,
      }).isRequired,
      classification: PropTypes.shape({
        activity_purpose: PropTypes.string.isRequired,
        communication_medium: PropTypes.string.isRequired,
        deal_stage: PropTypes.string.isRequired,
        key_action: PropTypes.string.isRequired,
        entities_involved: PropTypes.oneOfType([
          PropTypes.arrayOf(PropTypes.any),
          PropTypes.null,
        ]).isRequired,
        status: PropTypes.string.isRequired,
      }).isRequired,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
      is_internal: PropTypes.bool.isRequired,
    })).isRequired,
  }).isRequired,
  onAddDeal: PropTypes.func.isRequired,
  onIgnore: PropTypes.func.isRequired,
};

export default SparkCard;
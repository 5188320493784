// src/hooks/useFetchInbox.js

import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

// Define the default statuses outside the hook to ensure a stable reference
const DEFAULT_STATUSES = ["to do", "completed", "all"];

export function useFetchInbox(
  accountID,
  userID,
  role,
  statuses = DEFAULT_STATUSES,
  pageNumber = 1,
  itemsPerPage = 10
) {
  const [combinedItems, setCombinedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const { getAccessToken } = useAuthData();

  useEffect(() => {
    const fetchInbox = async () => {
      if (!accountID || !userID || !statuses || !pageNumber || !itemsPerPage) {
        setLoading(false);
        return;
      }

      try {
        const accessToken = await getAccessToken();
        setLoading(true);
        setError(null);

        const target =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        const response = await axios.get(
          `${target}/accounts/${accountID}/users/${userID}/inbox`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              statuses: statuses[0],
              role: role,
              page_number: pageNumber,
              items_per_page: itemsPerPage,
            },
          }
        );

        setCombinedItems(response.data?.items || []);
        setHasMore(response.data?.has_more || false);
        setTotalCount(response.data?.total_count || 0);
        setError(null);
      } catch (err) {
        console.error("Error fetching inbox:", err);
        setError(err);
        setCombinedItems([]);
        setHasMore(false);
        setTotalCount(0);
      } finally {
        setLoading(false);
      }
    };

    fetchInbox();
  }, [accountID, userID, role, getAccessToken, pageNumber, statuses]);

  return { combinedItems, loading, error, hasMore, totalCount };
}

export default useFetchInbox;

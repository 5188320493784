import React from "react";
import PropTypes from "prop-types";
import {
  VStack,
  Box,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  Flex,
} from '@chakra-ui/react';
import { ActivityItem } from "../Activities";
import useFetchDealActivities from "../hooks/useFetchDealActivities";
import PaginationControls from '../Shared/PaginationControls';

const ITEMS_PER_PAGE = 5;

const DealActivity = ({ accountID, dealID }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  
  // Set start date to January 1, 2022
  const startDate = '2022-01-01';
  // Set end date to current date in YYYY-MM-DD format
  const endDate = new Date().toISOString().split('T')[0];
  
  const { activities, isLoading, isError } = useFetchDealActivities(
    accountID,
    dealID,
    startDate,
    endDate
  );

  // Sort activities in reverse chronological order
  const sortedActivities = React.useMemo(() => {
    return [...activities].sort((a, b) => {
      const dateA = new Date(a.activity_date);
      const dateB = new Date(b.activity_date);
      return dateB - dateA; // Most recent first
    });
  }, [activities]);

  // Calculate total pages
  const totalPages = React.useMemo(() => {
    return Math.ceil(sortedActivities.length / ITEMS_PER_PAGE) || 1;
  }, [sortedActivities.length]);

  // Get current page's activities
  const currentActivities = React.useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return sortedActivities.slice(startIndex, endIndex);
  }, [sortedActivities, currentPage]);

  // Pagination handlers
  const handlePreviousPage = () => {
    setCurrentPage(prev => Math.max(1, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(totalPages, prev + 1));
  };

  if (isLoading) {
    return (
      <Box>
        <Text fontSize="md" fontWeight="bold" mb={4}>Deal Activities</Text>
        <Flex justify="center" align="center" minH="200px">
          <Spinner size="lg" />
        </Flex>
      </Box>
    );
  }

  if (isError) {
    return (
      <Box>
        <Text fontSize="md" fontWeight="bold" mb={4}>Deal Activities</Text>
        <Alert status="error">
          <AlertIcon />
          Error loading activities.
        </Alert>
      </Box>
    );
  }

  if (!activities.length) {
    return (
      <Box>
        <Text fontSize="md" fontWeight="bold" mb={4}>Deal Activities</Text>
        <Text color="gray.500" textAlign="center" py={8}>
          No activities found for this deal.
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <Text fontSize="md" fontWeight="bold" mb={4}>Deal Activities</Text>
      
      {/* Activities List */}
      <VStack spacing={4} align="stretch" mb={4}>
        {currentActivities.map((activity) => (
          <ActivityItem key={activity.id} activity={activity} />
        ))}
      </VStack>

      {/* Pagination Controls */}
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPrevious={handlePreviousPage}
        onNext={handleNextPage}
      />
    </Box>
  );
};

DealActivity.propTypes = {
  accountID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dealID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default DealActivity;
import React from 'react';
import { Box, Flex, Skeleton, HStack } from '@chakra-ui/react';

const TableSkeleton = ({ rowCount = 10 }) => {
  return (
    <Box w="100%" overflowX="auto">
      {/* Header Section */}
      <Flex mb={4} gap={4}>
        <Skeleton height="40px" width="80px" borderRadius="md" />
        <Skeleton height="40px" width="80px" borderRadius="md" />
        <Skeleton height="40px" width="80px" borderRadius="md" />
      </Flex>

      {/* Search Bar */}
      <Skeleton height="40px" width="300px" mb={6} borderRadius="md" />

      {/* Table Header */}
      <Flex mb={4} px={4} py={2} bg="gray.50" borderRadius="md">
        <Flex flex={1.5}>
          <Skeleton height="20px" width="80px" />
        </Flex>
        <Flex flex={2}>
          <Skeleton height="20px" width="120px" />
        </Flex>
        <Flex flex={1.5}>
          <Skeleton height="20px" width="100px" />
        </Flex>
        <Flex flex={1}>
          <Skeleton height="20px" width="80px" />
        </Flex>
        <Flex flex={1}>
          <Skeleton height="20px" width="80px" />
        </Flex>
        <Flex flex={1}>
          <Skeleton height="20px" width="100px" />
        </Flex>
      </Flex>

      {/* Table Rows */}
      {Array(rowCount).fill(0).map((_, index) => (
        <Flex 
          key={index} 
          mb={4} 
          px={4} 
          py={2} 
          borderRadius="md"
          align="center"
        >
          <Flex flex={1.5} align="center">
            <HStack spacing={3}>
              <Skeleton height="24px" width="24px" borderRadius="full" />
              <Skeleton height="20px" width="80px" />
            </HStack>
          </Flex>
          <Flex flex={2}>
            <Skeleton height="20px" width="180px" />
          </Flex>
          <Flex flex={1.5}>
            <Skeleton height="20px" width="100px" />
          </Flex>
          <Flex flex={1}>
            <Skeleton height="20px" width="80px" />
          </Flex>
          <Flex flex={1}>
            <Skeleton height="20px" width="60px" />
          </Flex>
          <Flex flex={1}>
            <Skeleton height="20px" width="100px" />
          </Flex>
        </Flex>
      ))}

      {/* Pagination */}
      <Flex justify="space-between" align="center" mt={4} px={4}>
        <Skeleton height="30px" width="80px" />
        <Skeleton height="30px" width="120px" />
        <Skeleton height="30px" width="80px" />
      </Flex>
    </Box>
  );
};

export default TableSkeleton; 
// src/views/lysto/hooks/useFetchMeetingAnalysis.js
import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

const useFetchMeetingAnalysis = (accountID, meetingID) => {
  const { getAccessToken } = useAuthData();
  const [meetingAnalysis, setMeetingAnalysis] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMeetingData = async () => {
      if (!accountID || !meetingID) {
        setLoading(false);
        setError("Account ID or Meeting ID missing.");
        return;
      }
      try {
        setLoading(true);
        setError(null);
        const accessToken = await getAccessToken();

        const target =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        const response = await axios.get(
          `${target}/accounts/${accountID}/events/${meetingID}/analysis`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setMeetingAnalysis(response.data.eventAnalysis);
      } catch (err) {
        console.error("Error fetching meeting data:", err);
        setError(
          err.response?.data?.message ||
            err.message ||
            "Failed to fetch meeting data."
        );
        setMeetingAnalysis(null); // Ensure meetingAnalysis is null on error
      } finally {
        setLoading(false);
      }
    };

    fetchMeetingData();
  }, [accountID, meetingID, getAccessToken]);

  return { meetingAnalysis, loading, error };
};

export default useFetchMeetingAnalysis;

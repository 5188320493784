import React, { useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Icon,
  Collapse,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChevronDown, ChevronUp } from "lucide-react";
import ActivityContent from "./ActivityContent";
import ActivityTitle from "./ActivityTitle";
import { getActivityConfig, formatDate, formatTime } from "./utils";
import { ActivityPropType } from "./types";

const ActivityItem = ({ activity }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { icon, color } = getActivityConfig(activity);

  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.50", "gray.700");

  return (
    <Box width="100%">
      <Box
        width="100%"
        p={3}
        bg={bgColor}
        boxShadow="sm"
        borderRadius="md"
        borderWidth="1px"
        borderColor={borderColor}
        transition="all 0.2s"
        cursor="pointer"
        onClick={() => setIsExpanded(!isExpanded)}
        _hover={{
          borderColor: "gray.300",
          bg: hoverBg,
        }}
      >
        <HStack spacing={4} width="100%" justify="space-between">
          <Icon as={icon} color={color} boxSize={6} />

          <VStack align="start" flex="1" spacing={0}>
            <ActivityTitle activity={activity} />
            <Text fontSize="xs" color="gray.500">
              {formatDate(activity.completed_date_time)} at {formatTime(activity.completed_date_time)}
            </Text>
          </VStack>

          <Icon
            as={isExpanded ? ChevronUp : ChevronDown}
            boxSize={5}
            color="gray.500"
          />
        </HStack>
      </Box>

      <Collapse in={isExpanded} animateOpacity>
        <Box mt={2}>
          <ActivityContent activity={activity} />
        </Box>
      </Collapse>
    </Box>
  );
};

ActivityItem.propTypes = {
  activity: ActivityPropType,
};

export default ActivityItem;
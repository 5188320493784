import { useState } from "react";
import { useAuthData } from "../../../auth-context";
import axios from "axios";

export function useDealCreationModal() {
  const [modalData, setModalData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessToken } = useAuthData();

  const fetchModalData = async (accountId, userId, crmAccountId) => {
    if (!accountId || !userId || !crmAccountId) {
      setError(new Error("Missing required parameters."));
      return;
    }

    setIsLoading(true);
    try {
      const accessToken = await getAccessToken();
      const target =
        process.env.REACT_APP_API_BASE_URL ||
        "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
      const response = await axios.get(
        `${target}/accounts/${accountId}/users/${userId}/crm-accounts/${crmAccountId}/spark/deal-creation-modal`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.data) {
        throw new Error("No data received from API.");
      }

      setModalData(response.data);
      setError(null);
      return response.data;
    } catch (err) {
      console.error(
        "Error fetching deal creation modal data:",
        err.response ? err.response.data : err.message
      );
      setError(
        err.response?.data?.message ||
          err.message ||
          "Failed to fetch deal creation modal data."
      );
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    modalData,
    isLoading,
    error,
    fetchModalData,
  };
}

// MeetingDisplay.jsx
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  VStack,
  Text,
  useColorModeValue,
  HStack,
  Spacer,
} from '@chakra-ui/react';
import { formatTime } from './utils';
import { ActivityPropType } from './types';
import { Video, ExternalLink, ArrowRight } from 'lucide-react';
import BaseTag from '../Shared/BaseTag';

const MeetingDisplay = ({ activity }) => {
  const navigate = useNavigate();

  // Add null check for activity_data with informative fallback
  if (!activity?.activity_data?.data) {
    return (
      <Box p={4} bg="gray.50" borderRadius="md" borderWidth="1px" borderColor="gray.200">
        <Text color="gray.500">Meeting data not available</Text>
      </Box>
    );
  }

  const meetingData = activity.activity_data.data;

  // Format times without timezone transformation
  const startTime = formatTime(meetingData.start_time);
  const endTime = formatTime(meetingData.end_time);

  // Format date
  const formattedDate = useMemo(() => {
    const date = new Date(activity.activity_date || activity.created_at);
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }).format(date);
  }, [activity.activity_date, activity.created_at]);

  // Deduplicate and sort participants
  const participants = useMemo(() => {
    const uniqueParticipants = Array.from(new Set(
      meetingData.participants.map(p => p.email)
    )).sort();
    return uniqueParticipants;
  }, [meetingData.participants]);

  // Style variables
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const labelColor = useColorModeValue('gray.600', 'gray.400');
  const contentBgColor = useColorModeValue('white', 'gray.800');
  const dateColor = useColorModeValue('gray.500', 'gray.400');
  const tagBg = useColorModeValue('white', 'gray.800');
  const tagColor = useColorModeValue('black', 'white');
  const tagHoverColor = useColorModeValue('blue.500', 'blue.300');

  return (
    <Box p={4} bg={bgColor} borderRadius="md" borderWidth="1px" borderColor={borderColor}>
      <VStack align="stretch" spacing={4}>
        {/* Meeting Header */}
        <VStack align="stretch" spacing={2}>
          {/* Title and Date */}
          <HStack align="center" spacing={2}>
            <Text fontWeight="semibold">
              {activity.title || 'Untitled Meeting'}
            </Text>
            <Spacer />
            <Text fontSize="sm" color={dateColor}>
              {formattedDate}
            </Text>
          </HStack>

          {/* Organizer */}
          <Text>
            <Text as="span" fontWeight="semibold" color={labelColor}>
              Organizer:
            </Text>{' '}
            {meetingData.organizer_display_name || meetingData.organizer_email || 'Unknown Organizer'}
          </Text>

          {/* Time */}
          <Text>
            <Text as="span" fontWeight="semibold" color={labelColor}>
              Time:
            </Text>{' '}
            {startTime} - {endTime}
          </Text>

          {/* Location */}
          {meetingData.location && (
            <Text>
              <Text as="span" fontWeight="semibold" color={labelColor}>
                Location:
              </Text>{' '}
              {meetingData.location}
            </Text>
          )}

          {/* Participants Section */}
          <Box>
            <Text>
              <Text as="span" fontWeight="semibold" color={labelColor}>
                Participants ({participants.length}):
              </Text>{' '}
              {participants.join(', ')}
            </Text>
          </Box>

          {/* Meeting Controls */}
          <HStack spacing={4} pt={1}>
            {meetingData.is_recorded && meetingData.meeting_url && (
              <a 
                href={meetingData.meeting_url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
                role="button"
              >
                <BaseTag
                  icon={Video}
                  hoverIcon={ExternalLink}
                  colorScheme="gray"
                  tooltip="View Recording"
                  bg={tagBg}
                  color={tagColor}
                  _hover={{
                    color: tagHoverColor,
                  }}
                >
                  <Text fontSize="sm" fontWeight="medium">
                    Recording
                  </Text>
                </BaseTag>
              </a>
            )}
            {activity.activity_id && (
              <BaseTag
                icon={ArrowRight}
                colorScheme="gray"
                tooltip="View Meeting Details"
                onClick={() => navigate(`/admin/meetings/${activity.activity_id}`)}
                bg={tagBg}
                color={tagColor}
                cursor="pointer"
                _hover={{
                  color: tagHoverColor,
                  bg: 'gray.100',
                  transform: 'translateY(-1px)',
                  transition: 'all 0.2s ease-in-out'
                }}
              >
                <Text fontSize="sm" fontWeight="medium">
                  View Details
                </Text>
              </BaseTag>
            )}
          </HStack>
        </VStack>

        {/* Notes Section */}
        {activity.description && (
          <Box 
            p={4}
            borderRadius="md"
            borderWidth="1px"
            borderColor={borderColor}
            bg={contentBgColor}
          >
            <Text fontWeight="semibold" color={labelColor} mb={3}>
              Notes:
            </Text>
            <Box 
              dangerouslySetInnerHTML={{ __html: activity.description }}
              sx={{
                'p, div': {
                  marginBottom: '0.5rem',
                  '&:last-child': {
                    marginBottom: 0
                  }
                },
                'a': {
                  color: 'blue.500',
                  textDecoration: 'underline'
                }
              }}
            />
          </Box>
        )}
      </VStack>
    </Box>
  );
};

MeetingDisplay.propTypes = {
  activity: ActivityPropType,
};

export default MeetingDisplay;
// CallDisplay.jsx
import React from 'react';
import {
  Box,
  VStack,
  Text,
  useColorModeValue,
  Divider,
} from '@chakra-ui/react';
import { ActivityPropType } from './types';

const CallDisplay = ({ activity }) => {
  const callData = activity?.activity_data?.data || {};
  const { summary, transcript } = callData;

  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const labelColor = useColorModeValue('gray.600', 'gray.400');

  return (
    <Box
      p={4}
      bg={bgColor}
      borderRadius="md"
      borderWidth="1px"
      borderColor={borderColor}
    >
      <VStack align="stretch" spacing={3}>
        {/* Caller */}
        <Box>
          <Text>
            <Text as="span" fontWeight="semibold" color={labelColor}>
              From:
            </Text>{' '}
            {callData.caller_name || callData.caller_email || 'Unknown Caller'}
          </Text>
        </Box>

        {/* Summary */}
        {summary ? (
          <Box>
            <Text fontWeight="semibold" color={labelColor} mb={2}>
              Summary:
            </Text>
            <Text>{summary}</Text>
          </Box>
        ) : (
          <Box>
            <Text fontWeight="semibold" color={labelColor} mb={2}>
              Summary:
            </Text>
            <Text color="gray.500">No summary available.</Text>
          </Box>
        )}

        {/* Transcript */}
        {transcript ? (
          <>
            <Divider />
            <Box>
              <Text fontWeight="semibold" color={labelColor} mb={2}>
                Transcript:
              </Text>
              <Box
                dangerouslySetInnerHTML={{ __html: transcript }}
                sx={{
                  'p, div': { marginBottom: '0.5rem' },
                  a: {
                    color: 'blue.500',
                    textDecoration: 'underline',
                  },
                }}
              />
            </Box>
          </>
        ) : (
          <>
            <Divider />
            <Box>
              <Text fontWeight="semibold" color={labelColor} mb={2}>
                Transcript:
              </Text>
              <Text color="gray.500">No transcript available.</Text>
            </Box>
          </>
        )}
      </VStack>
    </Box>
  );
};

CallDisplay.propTypes = {
  activity: ActivityPropType,
};

export default CallDisplay;
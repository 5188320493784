// src/views/lysto/Dashboard/components/Sales Efficiency/SalesEfficiencyGrid.js

import React, { useMemo } from 'react';
import { Box, SimpleGrid, VStack } from "@chakra-ui/react";
import StatCard from "../StatCard";
import LineFirst from "../LineFirst";
import { MdShowChart, MdTrendingUp, MdSpeed } from "react-icons/md";
import Card from "components/card/Card.js";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { getDateRange } from "../../../Shared/dateUtils";

// Constants
const PRIMARY_COLOR = "#1A5BF6";
const MONTHS = [
  { value: "Jan", number: 0 },
  { value: "Feb", number: 1 },
  { value: "Mar", number: 2 },
  { value: "Apr", number: 3 },
  { value: "May", number: 4 },
  { value: "Jun", number: 5 },
  { value: "Jul", number: 6 },
  { value: "Aug", number: 7 },
  { value: "Sep", number: 8 },
  { value: "Oct", number: 9 },
  { value: "Nov", number: 10 },
  { value: "Dec", number: 11 }
];

const defaultLineChartData = {
  title: "",
  labels: ["No Data"],
  options: [],
  data: [[0]],
  comparisonData: [0]
};

const getQuarterMonths = (quarter) => {
  const startMonth = (quarter - 1) * 3;
  return MONTHS.slice(startMonth, startMonth + 3);
};

const filterDealsBySegment = (deals, segment) => {
  if (!segment || segment === "All") return deals;

  return deals.filter(deal => {
    const locations = deal.extra_fields?.No_of_Locations__c;
    if (locations === null || locations === undefined) return false;
    
    switch (segment) {
      case "SMB":
        return locations >= 1 && locations <= 20;
      case "Mid-Market":
        return locations > 20 && locations <= 200;
      case "Enterprise":
        return locations > 200;
      default:
        return true;
    }
  });
};

const SalesEfficiencyGrid = ({ 
  deals, 
  selectedYear, 
  selectedQuarter,
  selectedSegment 
}) => {
  console.log('SalesEfficiencyGrid Props:', {
    deals,
    selectedYear,
    selectedQuarter,
    selectedSegment,
    hasCurrentYear: deals?.currentYear?.length,
    hasPrevYear: deals?.prevYear?.length
  });

  // Get the appropriate deals array based on the year and apply segment filter
  const getDealsForYear = (year) => {
    if (!deals) return [];
    const yearDeals = year === selectedYear ? (deals.currentPeriod || []) : (deals.prevPeriod || []);
    return filterDealsBySegment(yearDeals, selectedSegment);
  };

  const calculateMonthlyMetrics = (year, monthIndex) => {
    if (!year || monthIndex === undefined) {
      return {
        createdAmount: 0,
        revenueAmount: 0,
        wonCount: 0,
        lostCount: 0,
        winRate: 0,
        salesCycle: 0,
        avgDealValue: 0,
        createdCount: 0,
        velocity: 0
      };
    }

    const timeFilter = MONTHS[monthIndex].value;
    const { startDate, endDate } = getDateRange(timeFilter, year);
    const yearDeals = getDealsForYear(year);

    // Filter deals by created_date for creation metrics
    const monthDeals = yearDeals.filter(deal => {
      const dealDate = new Date(deal.created_date);
      return dealDate >= startDate && dealDate <= endDate;
    });

    // Filter deals by close_date for won/lost metrics
    const closedDealsInPeriod = yearDeals.filter(deal => {
      if (!deal.is_closed || !deal.close_date) return false;
      const closeDate = new Date(deal.close_date);
      return closeDate >= startDate && closeDate <= endDate;
    });

    // Filter deals by Date_Time_Closed_Paid__c for paid metrics
    const paidDealsInPeriod = yearDeals.filter(deal => {
      if (!deal.extra_fields?.Date_Time_Closed_Paid__c) return false;
      const paidDate = new Date(deal.extra_fields.Date_Time_Closed_Paid__c);
      return paidDate >= startDate && paidDate <= endDate;
    });

    const wonDeals = closedDealsInPeriod.filter(deal => deal.is_closed && deal.is_won);
    const lostDeals = closedDealsInPeriod.filter(deal => deal.is_closed && !deal.is_won);
    const closedDeals = wonDeals.length + lostDeals.length;
    const createdAmount = monthDeals.reduce((sum, deal) => sum + (Number(deal.amount) || 0), 0);
    const revenueAmount = paidDealsInPeriod.reduce((sum, deal) => sum + (Number(deal.amount) || 0), 0);
    
    // Fixed win rate calculation to only consider closed deals
    const winRate = closedDeals > 0 ? (wonDeals.length / closedDeals) * 100 : 0;

    // Calculate sales cycle only for paid deals
    const salesCycleDays = paidDealsInPeriod.map(deal => {
      const createDate = new Date(deal.created_date);
      const paidDate = new Date(deal.extra_fields.Date_Time_Closed_Paid__c);
      return Math.ceil((paidDate - createDate) / (1000 * 60 * 60 * 24));
    }).filter(days => days > 0);

    const avgSalesCycle = salesCycleDays.length > 0
      ? salesCycleDays.reduce((sum, days) => sum + days, 0) / salesCycleDays.length
      : 0;

    // Fixed average deal value to only consider paid deals
    const avgDealValue = paidDealsInPeriod.length > 0
      ? revenueAmount / paidDealsInPeriod.length
      : 0;

    // Updated velocity calculation to use paid deals
    const velocity = avgSalesCycle > 0
      ? (paidDealsInPeriod.length * avgDealValue) / (avgSalesCycle / 30)
      : 0;

    return {
      createdAmount,
      revenueAmount,
      wonCount: wonDeals.length,
      lostCount: lostDeals.length,
      paidCount: paidDealsInPeriod.length,
      winRate,
      paidRate: wonDeals.length > 0 ? (paidDealsInPeriod.length / wonDeals.length) * 100 : 0,
      salesCycle: avgSalesCycle,
      avgDealValue,
      createdCount: monthDeals.length,
      velocity
    };
  };

  const calculatePeriodMetrics = (year, quarter = null) => {
    const timeRange = quarter ? `Q${quarter}` : "all_year";
    const { startDate, endDate } = getDateRange(timeRange, year);
    const yearDeals = getDealsForYear(year);

    // Filter deals closed won in period
    const wonDealsInPeriod = yearDeals.filter(deal => {
      if (!deal.is_closed || !deal.is_won || !deal.extra_fields?.Date_Time_Closed_Won__c) return false;
      const wonDate = new Date(deal.extra_fields.Date_Time_Closed_Won__c);
      return wonDate >= startDate && wonDate <= endDate;
    });

    // Filter deals closed paid in period
    const paidDealsInPeriod = yearDeals.filter(deal => {
      if (!deal.extra_fields?.Date_Time_Closed_Paid__c) return false;
      const paidDate = new Date(deal.extra_fields.Date_Time_Closed_Paid__c);
      return paidDate >= startDate && paidDate <= endDate;
    });

    // Calculate total won value
    const totalWonValue = wonDealsInPeriod.reduce((sum, deal) => sum + (Number(deal.amount) || 0), 0);

    // Calculate total paid value
    const totalPaidValue = paidDealsInPeriod.reduce((sum, deal) => sum + (Number(deal.amount) || 0), 0);

    // Calculate average sales cycle for paid deals
    const salesCycleDays = paidDealsInPeriod.map(deal => {
      const createDate = new Date(deal.created_date);
      const paidDate = new Date(deal.extra_fields.Date_Time_Closed_Paid__c);
      return Math.ceil((paidDate - createDate) / (1000 * 60 * 60 * 24));
    }).filter(days => days > 0);

    const avgSalesCycleDays = salesCycleDays.length > 0
      ? salesCycleDays.reduce((sum, days) => sum + days, 0) / salesCycleDays.length
      : 0;

    return {
      totalWonValue,
      totalPaidValue,
      avgSalesCycleDays
    };
  };

  const currentPeriodMetrics = calculatePeriodMetrics(selectedYear, selectedQuarter);
  const previousPeriodMetrics = selectedQuarter
    ? calculatePeriodMetrics(
        selectedQuarter === 1 ? selectedYear - 1 : selectedYear,
        selectedQuarter === 1 ? 4 : selectedQuarter - 1
      )
    : calculatePeriodMetrics(selectedYear - 1);

  const wonValueGrowth = previousPeriodMetrics.totalWonValue > 0 
    ? ((currentPeriodMetrics.totalWonValue - previousPeriodMetrics.totalWonValue) / previousPeriodMetrics.totalWonValue) * 100 
    : 0;

  const paidValueGrowth = previousPeriodMetrics.totalPaidValue > 0 
    ? ((currentPeriodMetrics.totalPaidValue - previousPeriodMetrics.totalPaidValue) / previousPeriodMetrics.totalPaidValue) * 100 
    : 0;

  const salesCycleGrowth = previousPeriodMetrics.avgSalesCycleDays > 0 
    ? ((previousPeriodMetrics.avgSalesCycleDays - currentPeriodMetrics.avgSalesCycleDays) / previousPeriodMetrics.avgSalesCycleDays) * 100 
    : 0;

  const lineChartsData = useMemo(() => {
    if (!deals?.currentPeriod || !deals?.prevPeriod || !selectedYear) {
      return {
        createdBusiness: defaultLineChartData,
        convertedBusiness: defaultLineChartData,
        velocity: defaultLineChartData
      };
    }

    let monthsToShow;
    let monthNames;

    if (selectedQuarter) {
      monthsToShow = getQuarterMonths(selectedQuarter);
      monthNames = monthsToShow.map(month => month.value);
    } else {
      monthsToShow = MONTHS;
      monthNames = MONTHS.map(month => month.value);
    }

    // Current Period Data
    const currentPeriodData = monthsToShow.map((month) => {
      const currentMetrics = calculateMonthlyMetrics(selectedYear, month.number);
      return currentMetrics;
    });

    // Previous Year Data (always compare with same period last year)
    const prevPeriodData = monthsToShow.map((month) => {
      // Always use same months but from previous year
      const prevMetrics = calculateMonthlyMetrics(selectedYear - 1, month.number);
      return prevMetrics;
    });

    return {
      createdBusiness: {
        title: "Created Business",
        labels: [`Created Value ${selectedYear}`, `Created Value ${selectedYear - 1}`],
        options: monthNames,
        data: [
          currentPeriodData.map(d => d.createdAmount),
          prevPeriodData.map(d => d.createdAmount)
        ],
        comparisonData: prevPeriodData.map(d => d.createdAmount)
      },
      convertedBusiness: {
        title: "Converted Business",
        labels: [`Revenue ${selectedYear}`, `Revenue ${selectedYear - 1}`],
        options: monthNames,
        data: [
          currentPeriodData.map(d => d.revenueAmount),
          prevPeriodData.map(d => d.revenueAmount)
        ],
        comparisonData: prevPeriodData.map(d => d.revenueAmount)
      },
      velocity: {
        title: "Deal Velocity",
        labels: [`Velocity ${selectedYear}`, `Velocity ${selectedYear - 1}`],
        options: monthNames,
        data: [
          currentPeriodData.map(d => d.velocity),
          prevPeriodData.map(d => d.velocity)
        ],
        comparisonData: prevPeriodData.map(d => d.velocity)
      }
    };
  }, [deals, selectedYear, selectedQuarter]);

  const months = useMemo(() => {
    const monthsToProcess = selectedQuarter
      ? getQuarterMonths(selectedQuarter)
      : MONTHS;

    return monthsToProcess.map((month) => {
      const currentMetrics = calculateMonthlyMetrics(selectedYear, month.number);
      const previousMetrics = calculateMonthlyMetrics(selectedYear - 1, month.number);
      return {
        name: new Date(selectedYear, month.number).toLocaleString('default', { month: 'short' }),
        ...currentMetrics,
        previousCreatedAmount: previousMetrics.createdAmount,
        previousRevenueAmount: previousMetrics.revenueAmount
      };
    });
  }, [deals, selectedYear, selectedQuarter]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const periodLabel = selectedQuarter ? `Q${selectedQuarter}` : 'Annual';
  const comparisonLabel = selectedQuarter
    ? `vs Q${selectedQuarter === 1 ? 4 : selectedQuarter - 1}`
    : 'YoY';

  return (
    <VStack spacing={6} align="stretch">
      <Box pb={4}>
        <SimpleGrid columns={{ base: 1, sm: 1, md: 3 }} gap="20px">
          <StatCard
            icon={MdShowChart}
            title={`${periodLabel} Closed Won Value ${selectedYear}`}
            value={formatCurrency(currentPeriodMetrics.totalWonValue)}
            growth={wonValueGrowth}
            timeRange={comparisonLabel}
            primaryColor={PRIMARY_COLOR}
          />
          <StatCard
            icon={MdTrendingUp}
            title={`${periodLabel} Closed Paid Value ${selectedYear}`}
            value={formatCurrency(currentPeriodMetrics.totalPaidValue)}
            growth={paidValueGrowth}
            timeRange={comparisonLabel}
            primaryColor={PRIMARY_COLOR}
          />
          <StatCard
            icon={MdSpeed}
            title={`${periodLabel} Sales Cycle ${selectedYear}`}
            value={`${Math.round(currentPeriodMetrics.avgSalesCycleDays)} days`}
            growth={salesCycleGrowth}
            timeRange={comparisonLabel}
            primaryColor={PRIMARY_COLOR}
            inverse={true}
          />
        </SimpleGrid>
      </Box>

      <SimpleGrid columns={{ base: 1, md: 1 }} gap="20px" pb={4}>
        {Object.entries(lineChartsData).map(([key, chartData]) => (
          <Box key={`${key}-container`} height="350px">
            <LineFirst 
              key={`${key}-${selectedYear}`}
              lineData={chartData}
              selectedYear={selectedYear}
              timeFilter={selectedQuarter ? `Q${selectedQuarter}` : "all_year"}
              selectedSegment={selectedSegment}
              comparisonData={chartData.comparisonData}
            />
          </Box>
        ))}
      </SimpleGrid>

      <Card p="20px">
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Metric</Th>
                {months.map(month => (
                  <Th key={month.name}>{month.name}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td fontWeight="bold"># Created</Td>
                {months.map(month => (
                  <Td key={month.name}>{month.createdCount || 0}</Td>
                ))}
              </Tr>
              <Tr>
                <Td fontWeight="bold">Created $</Td>
                {months.map(month => (
                  <Td key={month.name}>{formatCurrency(month.createdAmount)}</Td>
                ))}
              </Tr>
              <Tr>
                <Td fontWeight="bold">Created Last Year</Td>
                {months.map(month => (
                  <Td key={month.name}>{formatCurrency(month.previousCreatedAmount)}</Td>
                ))}
              </Tr>
              <Tr>
                <Td fontWeight="bold"># Won</Td>
                {months.map(month => (
                  <Td key={month.name}>{month.wonCount || 0}</Td>
                ))}
              </Tr>
              <Tr>
                <Td fontWeight="bold"># Paid</Td>
                {months.map(month => (
                  <Td key={month.name}>{month.paidCount || 0}</Td>
                ))}
              </Tr>
              <Tr>
                <Td fontWeight="bold"># Lost</Td>
                {months.map(month => (
                  <Td key={month.name}>{month.lostCount || 0}</Td>
                ))}
              </Tr>
              <Tr>
                <Td fontWeight="bold">Win Rate</Td>
                {months.map(month => (
                  <Td key={month.name}>{(month.winRate || 0).toFixed(1)}%</Td>
                ))}
              </Tr>
              <Tr>
                <Td fontWeight="bold">Paid Rate</Td>
                {months.map(month => (
                  <Td key={month.name}>{(month.paidRate || 0).toFixed(1)}%</Td>
                ))}
              </Tr>
              <Tr>
                <Td fontWeight="bold">Revenue $</Td>
                {months.map(month => (
                  <Td key={month.name}>{formatCurrency(month.revenueAmount)}</Td>
                ))}
              </Tr>
              <Tr>
                <Td fontWeight="bold">Revenue Last Year</Td>
                {months.map(month => (
                  <Td key={month.name}>{formatCurrency(month.previousRevenueAmount)}</Td>
                ))}
              </Tr>
              <Tr>
                <Td fontWeight="bold">Sales Cycle (Days)</Td>
                {months.map(month => (
                  <Td key={month.name}>{Math.round(month.salesCycle || 0)}</Td>
                ))}
              </Tr>
              <Tr>
                <Td fontWeight="bold">Avg. Deal Value</Td>
                {months.map(month => (
                  <Td key={month.name}>{formatCurrency(month.avgDealValue || 0)}</Td>
                ))}
              </Tr>
              <Tr>
                <Td fontWeight="bold">Velocity</Td>
                {months.map(month => (
                  <Td key={month.name}>{formatCurrency(month.velocity || 0)}</Td>
                ))}
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Card>
    </VStack>
  );
};

export default SalesEfficiencyGrid;
import { startOfYear, endOfYear, startOfQuarter, endOfQuarter, startOfMonth, endOfMonth } from 'date-fns';

/**
 * Get start and end dates based on time filter and year
 * @param {string} timeFilter - Time filter (e.g., "all_year", "Q1", "Jan")
 * @param {number} year - Year to calculate dates for
 * @returns {{startDate: Date, endDate: Date}} Object containing start and end dates
 */
export const getDateRange = (timeFilter, year) => {
  const baseDate = new Date(year, 0); // January 1st of the given year

  switch (timeFilter) {
    case 'all_year':
      return {
        startDate: startOfYear(baseDate),
        endDate: endOfYear(baseDate)
      };
    case 'Q1':
      return {
        startDate: startOfQuarter(new Date(year, 0)),
        endDate: endOfQuarter(new Date(year, 2))
      };
    case 'Q2':
      return {
        startDate: startOfQuarter(new Date(year, 3)),
        endDate: endOfQuarter(new Date(year, 5))
      };
    case 'Q3':
      return {
        startDate: startOfQuarter(new Date(year, 6)),
        endDate: endOfQuarter(new Date(year, 8))
      };
    case 'Q4':
      return {
        startDate: startOfQuarter(new Date(year, 9)),
        endDate: endOfQuarter(new Date(year, 11))
      };
    case 'Jan':
      return {
        startDate: startOfMonth(new Date(year, 0)),
        endDate: endOfMonth(new Date(year, 0))
      };
    case 'Feb':
      return {
        startDate: startOfMonth(new Date(year, 1)),
        endDate: endOfMonth(new Date(year, 1))
      };
    case 'Mar':
      return {
        startDate: startOfMonth(new Date(year, 2)),
        endDate: endOfMonth(new Date(year, 2))
      };
    case 'Apr':
      return {
        startDate: startOfMonth(new Date(year, 3)),
        endDate: endOfMonth(new Date(year, 3))
      };
    case 'May':
      return {
        startDate: startOfMonth(new Date(year, 4)),
        endDate: endOfMonth(new Date(year, 4))
      };
    case 'Jun':
      return {
        startDate: startOfMonth(new Date(year, 5)),
        endDate: endOfMonth(new Date(year, 5))
      };
    case 'Jul':
      return {
        startDate: startOfMonth(new Date(year, 6)),
        endDate: endOfMonth(new Date(year, 6))
      };
    case 'Aug':
      return {
        startDate: startOfMonth(new Date(year, 7)),
        endDate: endOfMonth(new Date(year, 7))
      };
    case 'Sep':
      return {
        startDate: startOfMonth(new Date(year, 8)),
        endDate: endOfMonth(new Date(year, 8))
      };
    case 'Oct':
      return {
        startDate: startOfMonth(new Date(year, 9)),
        endDate: endOfMonth(new Date(year, 9))
      };
    case 'Nov':
      return {
        startDate: startOfMonth(new Date(year, 10)),
        endDate: endOfMonth(new Date(year, 10))
      };
    case 'Dec':
      return {
        startDate: startOfMonth(new Date(year, 11)),
        endDate: endOfMonth(new Date(year, 11))
      };
    default:
      throw new Error(`Invalid time filter: ${timeFilter}`);
  }
}; 
// src/components/WinRateOverTimeChart.js

import React, { useMemo } from "react";
import { Box, Text } from "@chakra-ui/react";
import Chart from "react-apexcharts";

const filterDealsBySegment = (deals, segment) => {
  if (!segment || segment === "All") return deals;

  return deals.filter(deal => {
    const locations = deal.extra_fields?.No_of_Locations__c;
    if (locations === null || locations === undefined) return false;
    
    switch (segment) {
      case "SMB":
        return locations >= 1 && locations <= 20;
      case "Mid-Market":
        return locations > 20 && locations <= 200;
      case "Enterprise":
        return locations > 200;
      default:
        return true;
    }
  });
};

/**
 * WinRateOverTimeChart Component
 * Displays a line chart showing win rates over time.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.deals - The deals data from the parent.
 * @param {string} props.primaryColor - Primary color for the chart.
 * @param {number} props.selectedYear - Selected year for filtering.
 * @param {number} props.selectedQuarter - Selected quarter for filtering (optional).
 * @param {string} props.selectedSegment - Selected segment for filtering.
 * @returns {JSX.Element} The rendered WinRateOverTimeChart component.
 */
const WinRateOverTimeChart = ({ deals, selectedYear, selectedQuarter, selectedSegment, primaryColor }) => {
  const chartData = useMemo(() => {
    // Validate deals data structure
    if (!deals?.currentPeriod || !Array.isArray(deals.currentPeriod) || deals.currentPeriod.length === 0) {
      return {
        title: `Win Rate Over Time ${selectedQuarter ? `(Q${selectedQuarter} ${selectedYear})` : `(${selectedYear})`}${selectedSegment !== "All" ? ` - ${selectedSegment}` : ""}`,
        options: ['No Data'],
        data: [0],
        annotations: ['0%']
      };
    }

    // Filter deals by segment first
    const filteredDeals = filterDealsBySegment(deals.currentPeriod, selectedSegment);

    // Filter deals by year and quarter
    const periodDeals = filteredDeals.filter(deal => {
      if (!deal?.close_date) return false;
      const date = new Date(deal.close_date);
      if (isNaN(date.getTime())) return false;

      const dealYear = date.getFullYear();
      if (dealYear !== selectedYear) return false;

      if (selectedQuarter) {
        const dealQuarter = Math.floor(date.getMonth() / 3) + 1;
        return dealQuarter === selectedQuarter;
      }
      return true;
    });

    // Group deals by month
    const monthlyDeals = periodDeals.reduce((acc, deal) => {
      const date = new Date(deal.close_date);
      const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      const monthLabel = date.toLocaleString('default', { month: 'short' });
      
      if (!acc[monthKey]) {
        acc[monthKey] = {
          monthLabel,
          deals: []
        };
      }
      acc[monthKey].deals.push(deal);
      return acc;
    }, {});

    // Sort months and process data
    const processedData = Object.entries(monthlyDeals)
      .sort(([a], [b]) => a.localeCompare(b))
      .reduce((acc, [, month]) => {
        // Filter for closed deals only
        const closedDeals = month.deals.filter(deal => deal.is_closed === true);
        const wonDeals = closedDeals.filter(deal => deal.is_won === true).length;
        const totalClosedDeals = closedDeals.length;
        
        // Calculate win rate only considering closed deals
        const winRate = totalClosedDeals > 0 ? (wonDeals / totalClosedDeals) * 100 : 0;
        
        acc.data.push(Math.round(winRate));
        acc.options.push(month.monthLabel);
        acc.annotations.push(`${Math.round(winRate)}%`);
        
        return acc;
      }, {
        title: `Win Rate Over Time ${selectedQuarter ? `(Q${selectedQuarter} ${selectedYear})` : `(${selectedYear})`}${selectedSegment !== "All" ? ` - ${selectedSegment}` : ""}`,
        options: [],
        data: [],
        annotations: []
      });

    // Ensure we have at least one data point
    if (processedData.options.length === 0) {
      processedData.options = ['No Data'];
      processedData.data = [0];
      processedData.annotations = ['0%'];
    }

    return processedData;
  }, [deals, selectedYear, selectedQuarter, selectedSegment]);

  // Early return for no data
  if (!chartData) {
    return (
      <Box
        bg="white"
        borderRadius="lg"
        p={6}
        boxShadow="sm"
        border="1px solid"
        borderColor="gray.100"
        height="450px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text>No data available for the selected period</Text>
      </Box>
    );
  }

  const chartOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
      },
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    markers: {
      size: 4,
      colors: [primaryColor],
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    xaxis: {
      categories: chartData.options || ['No Data'],
      labels: {
        style: {
          colors: Array(chartData.options?.length || 1).fill("#718096"),
          fontSize: "12px",
        },
        rotate: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#718096",
          fontSize: "12px",
        },
        formatter: (value) => `${value}%`
      },
      min: 0,
      max: 100,
    },
    colors: [primaryColor],
    grid: {
      borderColor: "#E2E8F0",
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    tooltip: {
      theme: "light",
      y: {
        formatter: function (val) {
          return `${val}%`;
        },
      },
    },
    annotations: {
      position: 'front',
      points: (chartData.annotations || ['0%']).map((value, index) => ({
        x: chartData.options?.[index] || 'No Data',
        y: chartData.data?.[index] || 0,
        marker: {
          size: 0,
        },
        label: {
          borderColor: "transparent",
          offsetY: -15,
          style: {
            color: "#718096",
            background: "transparent",
          },
          text: value,
        },
      })),
    },
  };

  const series = [{
    name: "Win Rate",
    data: chartData.data || [0],
  }];

  return (
    <Box
      bg="white"
      borderRadius="lg"
      p={4}
      boxShadow="sm"
      border="1px solid"
      borderColor="gray.100"
    >
      <Text fontSize="xl" fontWeight="700" mb={3} color="gray.700">
        {chartData.title || 'Win Rate Over Time'}
      </Text>
      <Chart
        options={chartOptions}
        series={series}
        type="line"
        height={350}
      />
    </Box>
  );
};

export default WinRateOverTimeChart;

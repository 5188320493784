import React from 'react';
import PropTypes from 'prop-types';
import { Box, VStack, Flex, Text } from '@chakra-ui/react';
import { FaEnvelope, FaBriefcase, FaFilter, FaFlag } from 'react-icons/fa';
import { OverviewTag } from '../Shared/Tags';
import ContactDealsSection from './ContactDealsSection';
import ContactActivity from './ContactActivity';
import ContactAccountsSection from './ContactAccountsSection';

const ContactDetail = ({ contact }) => {
  return (
    <Box
      bg="transparent"
      p={0}
      borderRadius="md"
      boxShadow="none"
      mb={4}
      height="100%"
    >
      <VStack spacing={6} align="stretch" height="100%">
        {/* Combined Overview, Deals, and Accounts Section */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            {/* Overview Section */}
            <Box>
              <Text fontSize="md" fontWeight="semibold" mb={3}>
                Overview
              </Text>
              <Flex gap={3} flexWrap="wrap">
                <OverviewTag
                  icon={FaEnvelope}
                  label="Email"
                  value={contact.email || 'N/A'}
                  colorScheme="green"
                />
                <OverviewTag
                  icon={FaBriefcase}
                  label="Title"
                  value={contact.title || 'N/A'}
                  colorScheme="purple"
                />
                <OverviewTag
                  icon={FaFilter}
                  label="Source"
                  value={contact.source || 'N/A'}
                  colorScheme="orange"
                />
                <OverviewTag
                  icon={FaFlag}
                  label="Status"
                  value={contact.status || 'N/A'}
                  colorScheme="teal"
                />
              </Flex>
            </Box>
            <ContactDealsSection deals={contact.deals || []} />
            <ContactAccountsSection contact={contact} />
          </VStack>
        </Box>

        {/* Activity Section */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <ContactActivity accountId={contact.account_id} contactId={contact.id} />
        </Box>
      </VStack>
    </Box>
  );
};

ContactDetail.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number.isRequired,
    account_id: PropTypes.number.isRequired,
    crm_account_id: PropTypes.number,
    crm_account_name: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    source: PropTypes.string,
    status: PropTypes.string,
    deals: PropTypes.array,
    accounts: PropTypes.array,
    created_date: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};

export default ContactDetail;
// src/views/lysto/Meetings/MeetingAnalysis.js
import React from "react";
import {
  Box,
  Text,
  VStack,
  Flex,
  IconButton,
  Center,
  Spinner
} from "@chakra-ui/react";
import { ArrowLeft } from "lucide-react";
import { useAuthData } from "auth-context";
import { useParams, useNavigate } from "react-router-dom";
import { GetUserTimezone } from "./utils";
import { DateTime } from "luxon";
import useFetchMeetingAnalysis from "../hooks/useFetchMeetingAnalysis";
import MeetingDetail from "./MeetingDetail";


const MeetingAnalysis = () => {
  const navigate = useNavigate(); // Replace useHistory with useNavigate
  const { meetingID } = useParams();
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const { meetingAnalysis, loading, error } = useFetchMeetingAnalysis(accountID, meetingID);


  const handleBack = () => {
    navigate(-1);
  };

   if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center h="100vh">
        <Text color="red.500">Error: {error}</Text>
      </Center>
    );
  }

  if (!meetingAnalysis) {
      return <Text>Meeting analysis not found.</Text>;
    }


  // Parse the meeting date directly in the event timezone
  const eventMeetingDate = DateTime.fromISO(meetingAnalysis.date, {
    zone: meetingAnalysis.timezone,
  });

  // Only convert if user timezone is different
  const displayDate = eventMeetingDate.setZone(GetUserTimezone());

  // Format the date string
  const formattedDate = displayDate.toFormat("MMM dd, yyyy h:mm a");

  return (
    <VStack align="stretch" spacing={8} pt={20} px={4}>
        <Flex align="center">
            <IconButton
                icon={<ArrowLeft />}
                aria-label="Go back"
                onClick={handleBack}
                variant="ghost"
                size="lg"
                mr={4}
            />
            <VStack align="start" spacing={2}>
                <Text fontSize="lg" fontWeight="bold" color="#57595E">
                    {meetingAnalysis.title}
                </Text>
                <Text color="#57595E">
                    {formattedDate} • {meetingAnalysis.attendees.length} attendees
                </Text>
            </VStack>
        </Flex>

        <Flex>
            <Box flex={1} mr={8}>
              <MeetingDetail meetingAnalysis={meetingAnalysis} />
            </Box>
        </Flex>
    </VStack>
  );
};

export default MeetingAnalysis;
import React from "react";
import { createRoot } from "react-dom/client";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { AuthProvider } from "./auth-context";
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './views/lysto/hooks/queryClient';
import AutoLogin from "./views/auth/signIn/login";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import PrivateRoute from "components/PrivateRoutes";
import "assets/css/App.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import CallbackComponent from "components/CallbackComponent";

// Stored in Github Secrets
const auth0_domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0_client_id = process.env.REACT_APP_AUTH0_CLIENT_ID;
const target =
  process.env.REACT_APP_AUTH_CALLBACK_URL ||
  "https://localhost:3000/#/callback";

const root = createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={auth0_domain}
    clientId={auth0_client_id}
    authorizationParams={{
      audience: "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net",
      redirect_uri: target,
      scope:
        "openid profile email read:current_user read:user_idp_tokens read:calendar",
    }}
  >
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <React.StrictMode>
            <HashRouter>
              <Routes>
                <Route path="/" element={<AutoLogin />} />
                <Route path="/callback" element={<CallbackComponent />} />
                <Route path="/auth/*" element={<AuthLayout />} />

                {/* Redirect /admin to /admin/inbox */}
                <Route
                  path="/admin"
                  element={<Navigate to="/admin/inbox" replace />}
                />

                <Route
                  path="/admin/*"
                  element={
                    <PrivateRoute>
                      <AdminLayout />
                    </PrivateRoute>
                  }
                />

                {/* Optionally, redirect all unknown routes to /admin/inbox */}
                <Route
                  path="*"
                  element={<Navigate to="/admin/inbox" replace />}
                />
              </Routes>
            </HashRouter>
          </React.StrictMode>
        </ChakraProvider>
      </QueryClientProvider>
    </AuthProvider>
  </Auth0Provider>
);

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Wrap, WrapItem, Flex, Icon, Tooltip } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { HiUser } from 'react-icons/hi';
import { FaExpandAlt } from 'react-icons/fa';

/**
 * ContactTag Component
 * Displays a contact with hover effects and click functionality.
 */
const ContactTag = ({ contactName, onClick }) => (
  <Tooltip label="View Contact Details" placement="top">
    <Flex
      align="center"
      bg="gray.50"
      color="black"
      px={3}
      py={2}
      borderRadius="md"
      borderWidth="1px"
      borderColor="gray.200"
      cursor="pointer"
      onClick={onClick}
      role="group"
      _hover={{
        bg: "gray.100",
      }}
      height="40px"
      minWidth="120px"
    >
      {/* HiUser Icon - visible by default, hidden on hover */}
      <Icon
        as={HiUser}
        mr={2}
        _groupHover={{ display: 'none' }}
        color="gray.500"
        boxSize={4}
      />
      {/* FaExpandAlt Icon - hidden by default, visible on hover */}
      <Icon
        as={FaExpandAlt}
        mr={2}
        display="none"
        _groupHover={{ display: 'block', color: 'blue.500' }}
        boxSize={4}
      />
      <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
        {contactName || 'Unnamed Contact'}
      </Text>
    </Flex>
  </Tooltip>
);

ContactTag.propTypes = {
  contactName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

/**
 * DealContactsSection Component
 * Displays a list of contacts associated with a deal.
 */
const DealContactsSection = ({ contacts }) => {
  const navigate = useNavigate();

  const handleViewDetails = (contact) => {
    navigate(`/admin/contacts/${contact.id}`);
  };

  return (
    <Box>
      <Text fontSize="md" fontWeight="semibold" mb={3}>
        Contacts
      </Text>
      {contacts.length === 0 ? (
        <Text color="gray.500">No contacts associated with this deal.</Text>
      ) : (
        <Wrap spacing={4}>
          {contacts.map((contact) => (
            <WrapItem key={contact.id}>
              <ContactTag
                contactName={`${contact.name} ${contact.last_name || ''}`}
                onClick={() => handleViewDetails(contact)}
              />
            </WrapItem>
          ))}
        </Wrap>
      )}
    </Box>
  );
};

DealContactsSection.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
    })
  ),
};

DealContactsSection.defaultProps = {
  contacts: [],
};

export default DealContactsSection; 
// src/constants/inboxMappings.js

export const STATUS_MAP = {
  "to do": { label: "To Do", color: "yellow" },
  completed: { label: "Completed", color: "green" },
  // Add more statuses as needed
};

export const TASK_MAP = {
  "Follow-up Call": { label: "Follow-up Call", color: "blue" },
  "Follow-up Email": { label: "Follow-up Email", color: "green" },
  // Add more tasks as needed
};

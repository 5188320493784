import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Spinner, Center, Text, Alert, AlertIcon } from "@chakra-ui/react";
import { useAuthData } from "auth-context";
import DealDetail from "./DealDetail";
import GoBackHeader from "../Shared/GoBackHeader";
import { useFetchDealById } from "../hooks/useFetchDealById";

const DealDetailsPage = () => {
  const { dealId } = useParams();
  const navigate = useNavigate();
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";

  // Use custom hook to fetch deal
  const { data: deal, loading: isLoading, error: isError } = useFetchDealById(accountID, dealId);

  const handleGoBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (isError) {
    return (
      <Center h="100vh">
        <Alert status="error" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
          <AlertIcon boxSize="40px" mr={0} />
          <Text mt={4} mb={1} fontSize="lg">
            Error Loading Deal
          </Text>
          <Text fontSize="sm">
            {typeof isError === 'string' ? isError : isError.message || 'Failed to load deal details. Please try again later.'}
          </Text>
        </Alert>
      </Center>
    );
  }

  if (!deal) {
    return (
      <Center h="100vh">
        <Alert status="warning" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
          <AlertIcon boxSize="40px" mr={0} />
          <Text mt={4} mb={1} fontSize="lg">
            Deal Not Found
          </Text>
          <Text fontSize="sm">
            The requested deal could not be found. It may have been deleted or you may not have permission to view it.
          </Text>
        </Alert>
      </Center>
    );
  }

  return (
    <Box pt={10}>
      {/* Go Back Header with Deal Title */}
      <GoBackHeader
        onGoBack={handleGoBack}
        title={deal.deal_name || "Deal Details"}
      />

      {/* Deal Detail Component */}
      <DealDetail deal={deal} />
    </Box>
  );
};

export default DealDetailsPage;

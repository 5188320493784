import { useState } from 'react';
import { MdTrendingUp, MdSpeed, MdPieChart, MdAssessment, MdInsertChart, MdAccountTree } from "react-icons/md";

export function useDashboardNavigation() {
  // Get current quarter (1-4)
  const getCurrentQuarter = () => {
    const month = new Date().getMonth();
    return Math.floor(month / 3) + 1;
  };

  const [selectedSection, setSelectedSection] = useState("Sales Performance");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedQuarter, setSelectedQuarter] = useState(getCurrentQuarter());
  const [selectedSegment, setSelectedSegment] = useState("All");

  const navigationOptions = [
    { label: "Sales Performance", value: "Sales Performance", icon: MdTrendingUp },
    { label: "Sales Efficiency", value: "Sales Efficiency", icon: MdSpeed },
    { label: "Sales to Target", value: "Sales to Target", icon: MdPieChart },
    { label: "Win-Loss Analysis", value: "Win-Loss Analysis", icon: MdAssessment },
    { label: "Pipeline Analysis", value: "Pipeline Analysis", icon: MdInsertChart },
    { label: "Sales Process", value: "Sales Process", icon: MdAccountTree },
  ];

  const segmentOptions = [
    { label: "All Sizes", value: "All" },
    { label: "SMB (1-20)", value: "SMB" },
    { label: "Mid-Market (20-200)", value: "Mid-Market" },
    { label: "Enterprise (200+)", value: "Enterprise" },
  ];

  const availableYears = [
    new Date().getFullYear(),
    new Date().getFullYear() - 1,
    new Date().getFullYear() - 2
  ];

  const quarters = [
    { label: "Q1", value: 1 },
    { label: "Q2", value: 2 },
    { label: "Q3", value: 3 },
    { label: "Q4", value: 4 }
  ];

  const shouldShowYearDropdown = (section) => {
    return [
      "Sales Performance",
      "Sales Efficiency",
      "Sales to Target",
      "Win-Loss Analysis",
      "Pipeline Analysis",
      "Sales Process"
    ].includes(section);
  };

  const shouldShowSegmentDropdown = (section) => {
    return [
      "Sales Performance",
      "Sales Efficiency",
      "Sales to Target",
      "Win-Loss Analysis",
      "Pipeline Analysis",
      "Sales Process"
    ].includes(section);
  };

  return {
    selectedSection,
    setSelectedSection,
    selectedYear,
    setSelectedYear,
    selectedQuarter,
    setSelectedQuarter,
    selectedSegment,
    setSelectedSegment,
    navigationOptions,
    segmentOptions,
    availableYears,
    quarters,
    shouldShowYearDropdown,
    shouldShowSegmentDropdown
  };
} 
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useAuthData } from "../../../auth-context";
import { DateTime } from "luxon";
import { GetUserTimezone } from "../Meetings/utils";

const useFetchMeetings = (
  accountID,
  userID,
  selectedSegment = "Upcoming",
  currentPage = 1,
  itemsPerPage = 10
) => {
  const { getAccessToken } = useAuthData();
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Transform meetings data
  const transformMeetingsData = useCallback((events) => {
    const userTimezone = GetUserTimezone();
    return events.map((event) => {
      const eventTimezone = event.startTimezone || "UTC";
      const startTime = DateTime.fromISO(event.startTime, {
        zone: eventTimezone,
      });
      const endTime = DateTime.fromISO(event.endTime, { zone: eventTimezone });

      const userStartTime = startTime.setZone(userTimezone);
      const userEndTime = endTime.setZone(userTimezone);

      const attendees =
        event.attendees?.map((attendee) => {
          const parts = attendee.split(", ");
          const email =
            parts
              .find((p) => p.startsWith("Email: "))
              ?.replace("Email: ", "") || "";
          const name =
            parts.find((p) => p.startsWith("Name: "))?.replace("Name: ", "") ||
            "";
          const response =
            parts
              .find((p) => p.startsWith("Response: "))
              ?.replace("Response: ", "") || "needsAction";
          return {
            name: name || email.split("@")[0],
            email,
            response: response.toLowerCase(),
          };
        }) || [];

      return {
        id: event.id,
        title: event.summary || "Untitled Meeting",
        description: event.description || "",
        startTime: userStartTime.toISO(),
        endTime: userEndTime.toISO(),
        location: event.location || "",
        hangoutLink: event.hangoutLink || "",
        attendees: attendees,
        status: event.status || "confirmed",
        timezone: eventTimezone,
        recorded: event.isRecorded || false,
      };
    });
  }, []);

  useEffect(() => {
    const fetchMeetings = async () => {
      if (!accountID || !userID) {
        setLoading(false);
        setMeetings([]);
        return;
      }

      try {
        setLoading(true);
        setError(null);

        const accessToken = await getAccessToken();

        const target =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
        const timeRange = selectedSegment === "Upcoming" ? "future" : "past";

        // Make parallel requests for both recorded and unrecorded meetings
        const requests = [
          // Request for regular meetings
          axios.get(
            `${target}/accounts/${accountID}/users/${userID}/calendar/events`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
              params: {
                page_number: currentPage,
                start_time: new Date().toISOString(),
                items_per_page: itemsPerPage,
                status: "confirmed",
                time_range: timeRange,
              },
            }
          ),
          // Request for recorded meetings
          axios.get(
            `${target}/accounts/${accountID}/users/${userID}/calendar/events`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
              params: {
                page_number: currentPage,
                start_time: new Date().toISOString(),
                items_per_page: itemsPerPage,
                status: "confirmed",
                time_range: timeRange,
                recorded: true,
              },
            }
          ),
        ];

        const responses = await Promise.all(requests);

        // Combine and transform the results
        const allEvents = responses.reduce((acc, response) => {
          if (response.data && response.data.events) {
            return [...acc, ...response.data.events];
          }
          return acc;
        }, []);

        // Remove duplicates based on event ID
        const uniqueEvents = Array.from(
          new Map(allEvents.map((event) => [event.id, event])).values()
        );

        const transformedMeetings = transformMeetingsData(uniqueEvents);
        setMeetings(transformedMeetings);
        setError(null);
      } catch (err) {
        console.error("Error fetching meetings:", err);
        setError("Failed to load meetings");
        setMeetings([]);
      } finally {
        setLoading(false);
      }
    };

    fetchMeetings();
  }, [
    accountID,
    userID,
    selectedSegment,
    currentPage,
    itemsPerPage,
    getAccessToken,
    transformMeetingsData,
  ]);

  return { meetings, loading, error };
};

export default useFetchMeetings;

import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { MdBusiness } from "react-icons/md";
import { FaExpandAlt } from "react-icons/fa";
import PropTypes from "prop-types";
import DataTable from "../Shared/DataTable";
import { formatDistanceToNow } from "date-fns";
import HighlightText from "../Shared/HighlightText";
import HoverBox from "../Shared/HoverBox";

// Status color mapping
const statusColorMap = {
  active: "blue.500",
  inactive: "gray.500"
};

const AccountsTable = ({ accounts, onViewDetails, onSort, sortConfig, searchQuery }) => {
  // Define the columns configuration
  const columns = [
    {
      key: "name",
      label: "Name",
      width: "250px",
      sortable: true,
      isFirst: true,
      render: (row) => (
        <HoverBox
          icon={MdBusiness}
          hoverIcon={FaExpandAlt}
          onClick={() => onViewDetails(row)}
          tooltipLabel="View Account Details"
        >
          <HighlightText
            text={row.name || "—"}
            query={searchQuery}
          />
        </HoverBox>
      ),
    },
    {
      key: "website_root",
      label: "Website",
      width: "250px",
      sortable: true,
      render: (row) => (
        <Text color="black" isTruncated maxWidth="100%" fontSize="md">
          <HighlightText
            text={row.website_root || row.website || "—"}
            query={searchQuery}
          />
        </Text>
      ),
    },
    {
      key: "city",
      label: "City",
      width: "180px",
      sortable: true,
      render: (row) => (
        <Text color="black" isTruncated maxWidth="100%" fontSize="md">
          <HighlightText
            text={row.city || "—"}
            query={searchQuery}
          />
        </Text>
      ),
    },
    {
      key: "isActive",
      label: "Status",
      width: "150px",
      sortable: true,
      hasDot: true,
      render: (row) => (
        <Box display="flex" alignItems="center" fontSize="md">
          <Box
            height="10px"
            width="10px"
            borderRadius="full"
            bg={statusColorMap[row.isActive ? 'active' : 'inactive']}
            mr={2}
          />
          <Text color="black" isTruncated maxWidth="100%">
            <HighlightText
              text={row.isActive ? "Active" : "Inactive"}
              query={searchQuery}
            />
          </Text>
        </Box>
      ),
    },
    {
      key: "last_activity_date",
      label: "Last Activity",
      width: "200px",
      sortable: true,
      format: (value) =>
        value ? formatDistanceToNow(new Date(value), { addSuffix: true }) : "—",
    },
  ];

  // Get color for status indicators
  const getRowColor = (row, columnKey) => {
    if (columnKey === "isActive") {
      return statusColorMap[row.isActive ? 'active' : 'inactive'];
    }
    return "gray.400";
  };

  // Function to get row icon component
  const getRowIcon = () => MdBusiness;

  return (
    <Box p={4} bg="white" borderRadius="lg" shadow="sm">
      <DataTable
        data={accounts}
        columns={columns}
        onSort={onSort}
        sortConfig={sortConfig}
        searchQuery={searchQuery}
        onRowClick={onViewDetails}
        rowIcon={getRowIcon}
        hoverIcon={FaExpandAlt}
        rowTooltip="View Account Details"
        getRowColor={getRowColor}
      />
    </Box>
  );
};

AccountsTable.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onViewDetails: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf(["ascending", "descending"]),
  }).isRequired,
  searchQuery: PropTypes.string,
};

AccountsTable.defaultProps = {
  searchQuery: "",
};

export default AccountsTable;
// /src/components/shared/BaseTag.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Icon, Text, Tooltip } from '@chakra-ui/react';

const BaseTag = ({ 
  label,
  icon: IconComponent,
  hoverIcon: HoverIconComponent,
  colorScheme = "gray",
  onClick,
  tooltip,
  children,
  ...props 
}) => (
  <Tooltip label={tooltip} placement="top" hasArrow>
    <Flex
      align="center"
      bg={`${colorScheme}.50`}
      color={`${colorScheme}.700`}
      px={3}
      py={2}
      borderRadius="md"
      borderWidth="1px"
      borderColor={`${colorScheme}.200`}
      cursor={onClick ? "pointer" : "default"}
      role={onClick ? "group" : undefined}
      onClick={(e) => {
        console.log('BaseTag clicked');
        onClick?.(e);
      }}
      _hover={{
        bg: `${colorScheme}.100`,
        transform: 'translateY(-1px)',
        borderColor: `${colorScheme}.300`,
        transition: 'all 0.2s ease-in-out',
      }}
      transition="all 0.2s ease-in-out"
      {...props}
    >
      {IconComponent && (
        <Flex position="relative" mr={2}>
          <Icon
            as={IconComponent}
            boxSize={4}
            transition="opacity 0.2s"
            opacity={HoverIconComponent ? 1 : undefined}
            _groupHover={{ opacity: HoverIconComponent ? 0 : undefined }}
          />
          {HoverIconComponent && (
            <Icon
              as={HoverIconComponent}
              boxSize={4}
              position="absolute"
              top="0"
              left="0"
              transition="opacity 0.2s"
              opacity={0}
              _groupHover={{ 
                opacity: 1,
                color: `${colorScheme}.500`
              }}
            />
          )}
        </Flex>
      )}
      {children || (
        <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
          {label}
        </Text>
      )}
    </Flex>
  </Tooltip>
);

BaseTag.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.elementType,
  hoverIcon: PropTypes.elementType,
  colorScheme: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  children: PropTypes.node,
};

export default BaseTag;
// contexts/SidebarContext.js
import React, { createContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useAuthData } from "../auth-context";
import { useFetchVisibleRoutes } from "../views/lysto/hooks/useFetchVisibleRoutes";

// Import your routes
import routesData from "routes.js";

export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const { account_ID: accountID } = useAuthData();
  const {
    visibleRoutes,
    loading,
    error,
    fetchVisibleRoutes,
    updateVisibleRoutes
  } = useFetchVisibleRoutes();

  // Initialize visibleRoutes by fetching from the backend or defaulting to all visible
  useEffect(() => {
    const initializeVisibility = async () => {
      if (!accountID) return;

      const fetchedRoutes = await fetchVisibleRoutes();

      if (!fetchedRoutes || Object.keys(fetchedRoutes).length === 0) {
        const initialVisibility = {};
        routesData.forEach((route) => {
          initialVisibility[route.name] = !route.hidden;
        });
        await updateVisibleRoutes(initialVisibility);
      }
    };

    initializeVisibility();
  }, [accountID]);

  const toggleRouteVisibility = (routeName) => {
    const updatedRoutes = {
      ...visibleRoutes,
      [routeName]: !visibleRoutes[routeName],
    };
    updateVisibleRoutes(updatedRoutes);
  };

  return (
    <SidebarContext.Provider
      value={{
        visibleRoutes,
        toggleRouteVisibility,
        loading,
        error,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

SidebarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// DealCountSinceLastActivityBarCard.js
import React from "react";
import { Box, Text } from "@chakra-ui/react";
import BarCard from "../BarCard";
import { CHART_COLORS } from "../../constants";
import { useDealCountSinceLastActivity } from "../../../hooks/useDealCountSinceLastActivity";

/**
 * DealCountSinceLastActivityBarCard Component
 * Displays deal count data based on last activity in a BarCard.
 *
 * @returns {JSX.Element} The rendered component.
 */
export default function DealCountSinceLastActivityBarCard({ 
  accountID, 
  toast, 
  selectedYear,
  selectedQuarter,
  selectedSegment 
}) {
  const {
    data,
    loading,
    error
  } = useDealCountSinceLastActivity(accountID, selectedYear, selectedQuarter, selectedSegment, toast);

  if (loading) {
    return (
      <Box textAlign="center" py={10}>
        <Text>Loading deal statistics...</Text>
      </Box>
    );
  }

  if (error || !data || !data.datasets) {
    return (
      <Box textAlign="center" py={10}>
        <Text color="red.500">
          {error
            ? "Failed to load deal statistics."
            : "No deal data available."}
        </Text>
      </Box>
    );
  }

  // Add colors to the datasets
  const barData = {
    ...data,
    datasets: data.datasets.map(dataset => ({
      ...dataset,
      backgroundColor: CHART_COLORS.slice(0, data.labels?.length || 0)
    }))
  };

  return (
    <Box pb={4} w="100%">
      <BarCard
        barData={barData}
        showLabels={false}
        useCurrencyFormat={false}
      />
    </Box>
  );
}

// src/views/lysto/Dashboard/utils/getDateRange.js

export const getDateRange = (filter, year, monthIndex = null, MONTHS = []) => {
  let startDate, endDate;

  switch (filter) {
    case "all_year": {
      startDate = new Date(year, 0, 1); // Jan 1
      endDate = new Date(year, 11, 31); // Dec 31
      break;
    }
    case "Q1": {
      startDate = new Date(year, 0, 1); // Jan 1
      endDate = new Date(year, 2, 31); // Mar 31
      break;
    }
    case "Q2": {
      startDate = new Date(year, 3, 1); // Apr 1
      endDate = new Date(year, 5, 30); // Jun 30
      break;
    }
    case "Q3": {
      startDate = new Date(year, 6, 1); // Jul 1
      endDate = new Date(year, 8, 30); // Sep 30
      break;
    }
    case "Q4": {
      startDate = new Date(year, 9, 1); // Oct 1
      endDate = new Date(year, 11, 31); // Dec 31
      break;
    }
    default: {
      // Handle individual months
      if (monthIndex !== null && MONTHS.length > monthIndex) {
        startDate = new Date(year, monthIndex, 1);
        endDate = new Date(year, monthIndex + 1, 0); // Last day of the month
      } else {
        // Fallback to entire year
        startDate = new Date(year, 0, 1);
        endDate = new Date(year, 11, 31);
      }
      break;
    }
  }

  return { startDate, endDate };
};

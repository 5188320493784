// src/views/lysto/Dashboard/components/Sales Performance/TopDealsTable.js

import React, { useMemo } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Box,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { MdBusiness, MdOpenInNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import HoverBox from "../../../Shared/HoverBox";

const TopDealsTable = ({ 
  deals, 
  timeFilter, 
  limit = 10,
  selectedYear,
  selectedQuarter,
  selectedSegment,
  periodRange 
}) => {
  const navigate = useNavigate();

  // Define quarters and months for filtering
  const QUARTERS = [
    { value: "Q1", months: [0, 1, 2] },
    { value: "Q2", months: [3, 4, 5] },
    { value: "Q3", months: [6, 7, 8] },
    { value: "Q4", months: [9, 10, 11] },
  ];

  const MONTHS = {
    "Jan": 0, "Feb": 1, "Mar": 2,
    "Apr": 3, "May": 4, "Jun": 5,
    "Jul": 6, "Aug": 7, "Sep": 8,
    "Oct": 9, "Nov": 10, "Dec": 11
  };

  // Filter deals by segment
  const filterDealsBySegment = (deals) => {
    if (!selectedSegment || selectedSegment === "All") return deals;

    return deals.filter(deal => {
      const locations = deal.extra_fields?.No_of_Locations__c;
      if (locations === null || locations === undefined) return false;
      
      switch (selectedSegment) {
        case "SMB":
          return locations >= 1 && locations <= 20;
        case "Mid-Market":
          return locations > 20 && locations <= 200;
        case "Enterprise":
          return locations > 200;
        default:
          return true;
      }
    });
  };

  // Filter and sort deals
  const filteredDeals = useMemo(() => {
    if (!deals) return [];

    const startDate = new Date(periodRange.startDate);
    const endDate = new Date(periodRange.endDate);

    // Adjust date range based on time filter
    if (timeFilter.length === 3) { // Month filter (e.g., "Jan")
      const monthIndex = MONTHS[timeFilter];
      if (monthIndex !== undefined) {
        startDate.setMonth(monthIndex);
        endDate.setMonth(monthIndex + 1);
        endDate.setDate(0); // Last day of the month
      }
    } else if (timeFilter.startsWith('Q') && !selectedQuarter) { // Quarter filter (only in full year view)
      const quarterIndex = parseInt(timeFilter[1]) - 1;
      const quarterMonths = QUARTERS[quarterIndex].months;
      startDate.setMonth(quarterMonths[0]);
      endDate.setMonth(quarterMonths[2] + 1);
      endDate.setDate(0);
    }

    // First filter by date range and closed/won status
    let filtered = deals.filter(deal => {
      const dealDate = new Date(deal.close_date);
      return (
        deal.is_closed &&
        deal.is_won &&
        dealDate >= startDate &&
        dealDate <= endDate
      );
    });

    // Then apply segment filter
    filtered = filterDealsBySegment(filtered);

    return filtered
      .sort((a, b) => Number(b.amount) - Number(a.amount))
      .slice(0, limit);
  }, [deals, timeFilter, selectedYear, selectedQuarter, selectedSegment, periodRange, limit]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  // Define fixed widths for each column
  const columnWidths = {
    rank: "60px",
    deal_name: "300px",
    amount: "120px",
    close_date: "120px",
    owner: "200px",
    source: "150px",
  };

  const handleDealClick = (dealId) => {
    navigate(`/admin/deals/${dealId}`);
  };

  if (!filteredDeals.length) {
    return (
      <Box textAlign="center" py={4}>
        <Text>No closed-won deals found in the selected time period</Text>
      </Box>
    );
  }

  return (
    <Box overflowX="auto">
      <Table variant="simple" size="lg">
        <Thead>
          <Tr>
            <Th width={columnWidths.rank}>#</Th>
            <Th width={columnWidths.deal_name}>Deal Name</Th>
            <Th width={columnWidths.amount} isNumeric>Amount</Th>
            <Th width={columnWidths.close_date}>Close Date</Th>
            <Th width={columnWidths.owner}>Owner</Th>
            <Th width={columnWidths.source}>Source</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredDeals.map((deal, index) => (
            <Tr key={deal.id} _hover={{ bg: "gray.50" }}>
              <Td>{index + 1}</Td>
              <Td>
                <HoverBox
                  icon={MdBusiness}
                  hoverIcon={MdOpenInNew}
                  tooltipLabel="View Deal Details"
                  onClick={() => handleDealClick(deal.id)}
                >
                  <Text fontSize="md" isTruncated maxWidth="100%">
                    {deal.deal_name || "Unnamed Deal"}
                  </Text>
                </HoverBox>
              </Td>
              <Td isNumeric>
                <Text fontSize="md" fontWeight="500">
                  {formatCurrency(deal.amount)}
                </Text>
              </Td>
              <Td>
                <Text fontSize="md">
                  {format(new Date(deal.close_date), 'M/d/yy')}
                </Text>
              </Td>
              <Td>
                <Text fontSize="md" isTruncated>
                  {deal.user_name ? `${deal.user_name} ${deal.user_last_name || ''}` : "—"}
                </Text>
              </Td>
              <Td>
                <Text fontSize="md" isTruncated>
                  {deal.lead_source || "—"}
                </Text>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default TopDealsTable;

// src/components/Contacts/hooks/useFetchContacts.js

import { useState, useEffect } from "react";
import axios from "axios";

/**
 * Custom hook to fetch contacts data.
 * @param {number} accountID - The ID of the account.
 * @param {string} contactType - The type of contacts to fetch (e.g., 'Lead').
 * @returns {Object} - { contacts, isLoading, isError }
 */
const useFetchContacts = (accountID, contactType = "Lead") => {
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchContacts = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const response = await axios.get(
          `https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net/accounts/${accountID}/contacts`,
          {
            params: {
              contact_type: contactType,
            },
          }
        );
        setContacts(response.data);
      } catch (error) {
        console.error("Error fetching contacts:", error);
        setIsError(true);
      }
      setIsLoading(false);
    };

    if (accountID) {
      fetchContacts();
    }
  }, [accountID, contactType]);

  return { contacts, isLoading, isError };
};

export default useFetchContacts;

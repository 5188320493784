import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Box,
  VStack,
  useToast,
  Flex,
  Text,
  Button,
} from "@chakra-ui/react";
import OverviewSection from "./OverviewSection";
import EmailDetailsSection from "./EmailDetailsSection";
import EmailDisplay from "../Activities/EmailDisplay";
import MessageInput from "./MessageInput";
import AnnotationModal from "./AnnotationModal";
import { FaPaperPlane } from "react-icons/fa";

const EmailFollowupDetail = ({
  item,
  onSendMessage,
  onAddAnnotation,
}) => {
  const toast = useToast();
  const conversationHistoryRef = useRef(null);

  // Find the draft message in the activities array
  const draftMessage = item?.activities?.find(
    (activity) => activity.status === "draft" && activity.activity_type === "Email"
  );

  // Initialize state with draft content if available
  const [message, setMessage] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailRecipients, setEmailRecipients] = useState([]);
  const [isAnnotationModalOpen, setIsAnnotationModalOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);

  // Update state when draft message or item changes
  useEffect(() => {
    if (draftMessage) {
      const draftData = draftMessage.activity_data?.data || {};
      setMessage(draftData.body || "");
      setEmailSubject(draftData.subject || item?.subject || "");
      setEmailRecipients(draftMessage.email_recipients || []);
    } else {
      setMessage("");
      setEmailSubject(item?.subject || "");
      // Get recipients from the most recent received email
      const lastReceivedEmail = item?.activities?.find(
        (activity) => activity.status === "received" && activity.activity_type === "Email"
      );
      setEmailRecipients(lastReceivedEmail?.email_recipients || []);
    }
  }, [draftMessage, item]);

  // Scroll to the top of the conversation history after rendering
  useEffect(() => {
    if (conversationHistoryRef.current) {
      conversationHistoryRef.current.scrollTop = 0;
    }
  }, []);

  const toggleAnnotationModal = () => {
    setIsAnnotationModalOpen((prev) => !prev);
  };

  const handleSend = async () => {
    if (!message.trim()) {
      toast({
        title: "Empty Message",
        description: "Please enter a message to send.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (emailRecipients.length === 0) {
      toast({
        title: "No Recipients",
        description: "Please add at least one recipient (To, CC, or BCC).",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsSending(true);
    try {
      await onSendMessage({
        subject: emailSubject,
        content: message,
        email_recipients: emailRecipients,
      });
      setMessage("");
      setEmailSubject("");
      setEmailRecipients([]);
      toast({
        title: "Message Sent",
        description: "Your email has been sent successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error sending message:", error);
      toast({
        title: "Send Failed",
        description: error.message || "Failed to send the message.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleEmailSubjectChange = (e) => {
    setEmailSubject(e.target.value);
  };

  const handleEmailRecipientsChange = (recipients) => {
    setEmailRecipients(recipients);
  };

  // Sort activities by date (most recent first), excluding draft messages
  const sortedActivities = item?.activities
    ? item.activities
        .filter(
          (activity) =>
            activity.status !== "draft" &&
            activity.activity_type === "Email" &&
            activity.activity_data?.data // Only include activities with valid email data
        )
        .sort((a, b) => {
          const dateA = new Date(a.activity_date || a.created_at);
          const dateB = new Date(b.activity_date || b.created_at);
          return dateB - dateA;
        })
    : [];

  // Early return if no item
  if (!item) {
    return null;
  }

  return (
    <Box
      bg="transparent"
      p={0}
      borderRadius="md"
      boxShadow="none"
      mb={4}
      height="100%"
    >
      <VStack spacing={6} align="stretch" height="100%">
        {/* Card 1: Overview */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={4} align="stretch">
            <Text fontSize="md" fontWeight="semibold">
              Overview
            </Text>
            <Box>
              <OverviewSection
                account={item.account_name}
                taskType={
                  item.task_subtype === "email"
                    ? "Email Follow-up"
                    : item.task_subtype
                }
                champion={item.champion || "N/A"}
                lastEngagementDate={item.last_activity_date}
              />
            </Box>
          </VStack>
        </Box>

        {/* Card 2: Generated Reply */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={4} align="stretch">
            <Text fontSize="md" fontWeight="semibold">
              Generated Reply
            </Text>
            <Box>
              <VStack spacing={6} align="stretch">
                <EmailDetailsSection
                  emailSubject={emailSubject}
                  onSubjectChange={handleEmailSubjectChange}
                  recipients={emailRecipients}
                  onRecipientsChange={handleEmailRecipientsChange}
                />

                <MessageInput
                  message={message}
                  setMessage={setMessage}
                  isSending={isSending}
                />
              </VStack>
            </Box>

            {/* Action Buttons */}
            <Flex justify="flex-end">
              <Button
                leftIcon={<FaPaperPlane />}
                colorScheme="blue"
                onClick={handleSend}
                isLoading={isSending}
                loadingText="Sending..."
                isDisabled={!message.trim() || isSending}
                size="md"
              >
                Send
              </Button>
            </Flex>
          </VStack>
        </Box>

        {/* Card 3: Activities */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={4} align="stretch">
            <Text fontSize="md" fontWeight="semibold">
              Email History
            </Text>
            <Box
              ref={conversationHistoryRef}
              maxHeight="400px"
              overflowY="auto"
              borderWidth="1px"
              borderColor="gray.300"
              borderRadius="md"
              p={4}
            >
              {sortedActivities.length > 0 ? (
                <VStack spacing={4} align="stretch">
                  {sortedActivities.map((activity) => (
                    <EmailDisplay key={activity.id} activity={activity} />
                  ))}
                </VStack>
              ) : (
                <Flex justify="center" align="center" minH="100px" color="gray.500">
                  <Text>No email history available.</Text>
                </Flex>
              )}
            </Box>
          </VStack>
        </Box>
      </VStack>

        {/* Make sure AnnotationModal does not throw error and renders only when needed */}
      { onAddAnnotation && <AnnotationModal
        isOpen={isAnnotationModalOpen}
        onClose={toggleAnnotationModal}
        onSubmit={onAddAnnotation}
      /> }
    </Box>
  );
};

EmailFollowupDetail.propTypes = {
  item: PropTypes.object,
  onSendMessage: PropTypes.func.isRequired,
    // Make this prop optional, because it may not exist in all cases.
  onAddAnnotation: PropTypes.func,
};

export default EmailFollowupDetail;
import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthData } from "../../../auth-context";

export function useFetchContactActivities(
  accountId,
  contactId,
  startDate,
  endDate
) {
  const { getAccessToken } = useAuthData();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchActivities = async () => {
      if (!accountId || !contactId || !startDate || !endDate) {
        setError(new Error("Missing required parameters"));
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const accessToken = await getAccessToken();

        const target =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

        const response = await axios.get(
          `${target}/accounts/${accountId}/contacts/${contactId}/activities`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              start_date: startDate,
              end_date: endDate,
            },
          }
        );

        // Process and filter the activities
        const processedActivities = (response.data || [])
          .filter((activity) => {
            // Filter out email activities with empty bodies
            if (activity.activity_type?.toLowerCase() === "email") {
              const emailBody = activity.activity_data?.data?.body;
              return emailBody && emailBody.trim() !== "";
            }
            // Keep all other types of activities
            return true;
          })
          .map((activity) => {
            // Transform the activity to match the expected shape
            return {
              id: activity.id || activity.activity_id,
              account_id: Number(accountId),
              user_id: activity.user_id,
              user_name: activity.user_name || "",
              user_last_name: activity.user_last_name || "",
              user_email: activity.user_email || "",
              deal_id: activity.deal_id,
              activity_id: activity.activity_id,
              activity_type: activity.activity_type,
              activity_date: activity.activity_date || activity.created_at,
              completed_date_time:
                activity.completed_date_time ||
                activity.activity_date ||
                activity.created_at,
              status: activity.status || "completed",
              title: activity.title || "",
              description: activity.description || "",
              activity_data: {
                activity_type: activity.activity_type?.toLowerCase(),
                data: {
                  // For email activities
                  ...(activity.activity_type?.toLowerCase() === "email" && {
                    body: activity.activity_data?.data?.body || "",
                    received_at:
                      activity.activity_data?.data?.received_at ||
                      activity.activity_date,
                    recipients: (
                      activity.activity_data?.data?.recipients || []
                    ).map((recipient) => ({
                      email: recipient.email,
                      recipient_type: recipient.recipient_type,
                    })),
                    sender:
                      activity.activity_data?.data?.sender ||
                      activity.user_email,
                    subject:
                      activity.activity_data?.data?.subject || activity.title,
                  }),
                  // For meeting activities
                  ...(activity.activity_type?.toLowerCase() === "meeting" && {
                    end_time: activity.activity_data?.data?.end_time || "",
                    location: activity.activity_data?.data?.location || "",
                    meeting_id: activity.activity_data?.data?.meeting_id || "",
                    participants: (
                      activity.activity_data?.data?.participants || []
                    ).map((participant) => ({
                      email: participant.email,
                      display_name: participant.display_name,
                    })),
                    start_time:
                      activity.activity_data?.data?.start_time ||
                      activity.activity_date,
                  }),
                },
                version: activity.activity_data?.version || "1.0",
              },
              source_system: activity.source_system || "internal",
              timestamp: activity.timestamp || activity.created_at,
              created_at: activity.created_at,
              updated_at: activity.updated_at || activity.created_at,
              is_internal: activity.is_internal || false,
              contact_id: activity.contact_id,
            };
          });

        setData(processedActivities);
        setError(null);
      } catch (err) {
        console.error("Error fetching contact activities:", err);
        setError(err);
        setData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, [accountId, contactId, startDate, endDate, getAccessToken]);

  return { data, loading, error };
}

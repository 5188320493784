// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Contacts/index.jsx
import React, { useCallback, useState, useMemo, useEffect } from "react";
import {
  Box,
  SimpleGrid,
   Text,
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { useAuthData } from "auth-context";
import ContactsTable from "./ContactsTable";
import PaginationControls from "../Shared/PaginationControls";
import PageHeader from "../Shared/PageHeader";
import TableSkeleton from "../Shared/TableSkeleton";
import useFetchContacts from "./hooks/useFetchContacts";
import { useDebounce } from 'use-debounce';

const ITEMS_PER_PAGE = 10;

const Contacts = () => {
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const navigate = useNavigate(); // Replaced useHistory with useNavigate
  const { contacts, isLoading, isError } = useFetchContacts(accountID, "Lead");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  // Sorting state - Default sort by 'last_activity_date' descending
  const [sortConfig, setSortConfig] = useState({ 
    key: 'last_activity_date', 
    direction: 'descending' 
  });

  // Filter states
  const [currentStatusFilter, setCurrentStatusFilter] = useState('active'); // Set default to 'active'
  const [searchQuery, setSearchQuery] = useState('');

  // Debounce the search query by 300ms
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  // Generate search suggestions based on contact names
  const searchSuggestions = useMemo(() => {
    return contacts
      .filter(contact => 
        contact.name && 
        contact.name.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
      )
      .map(contact => ({
        id: contact.id,
        label: contact.name
      }));
  }, [contacts, debouncedSearchQuery]);

  // Handle sorting
  const handleSort = (key) => {
    setSortConfig((prevState) => {
      if (prevState.key === key) {
        // Toggle direction
        return {
          key,
          direction: prevState.direction === 'ascending' ? 'descending' : 'ascending',
        };
      } else {
        // New sort
        return { key, direction: 'ascending' };
      }
    });
  };

  /**
   * Helper function to determine if a contact is active (has activity within last two weeks)
   * @param {string} dateString - ISO date string.
   * @returns {boolean}
   */
  const isActive = (dateString) => {
    if (!dateString) return false;
    const lastActivityDate = new Date(dateString);
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    return lastActivityDate >= twoWeeksAgo;
  };

  // Enhance contacts with isActive flag
  const enhancedContacts = useMemo(() => {
    return contacts.map(contact => ({
      ...contact,
      isActive: isActive(contact.last_activity_date),
    }));
  }, [contacts]);

  // Filter contacts based on currentStatusFilter and debouncedSearchQuery
  const filteredContacts = useMemo(() => {
    let filtered = enhancedContacts;

    // Status Filter based on last_activity_date
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

    if (currentStatusFilter === 'active') {
      filtered = filtered.filter((contact) => {
        if (!contact.last_activity_date) return false;
        const lastActivity = new Date(contact.last_activity_date);
        return lastActivity >= twoWeeksAgo;
      });
    } else if (currentStatusFilter === 'inactive') {
      filtered = filtered.filter((contact) => {
        // Contacts with no last_activity_date are considered inactive
        if (!contact.last_activity_date) return true;
        const lastActivity = new Date(contact.last_activity_date);
        return lastActivity < twoWeeksAgo;
      });
    }
    // 'all' shows all contacts, no filtering needed

    // Search Filter
    if (debouncedSearchQuery.trim() !== "") {
      const query = debouncedSearchQuery.toLowerCase();
      filtered = filtered.filter(contact =>
        // Search across multiple fields
        (
          (contact.name && contact.name.toLowerCase().includes(query)) ||
          (contact.email && contact.email.toLowerCase().includes(query)) ||
          (contact.crm_account_name && contact.crm_account_name.toLowerCase().includes(query)) ||
          (contact.source && contact.source.toLowerCase().includes(query)) ||
          (contact.title && contact.title.toLowerCase().includes(query))
        )
      );
    }

    return filtered;
  }, [enhancedContacts, currentStatusFilter, debouncedSearchQuery]);

  // Sort contacts
  const sortedContacts = useMemo(() => {
    if (!sortConfig.key) return filteredContacts;

    const sorted = [...filteredContacts].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Special handling for last_activity_date
      if (sortConfig.key === 'last_activity_date') {
        // Contacts with no last_activity_date should be sorted last
        if (!aValue && !bValue) return 0;
        if (!aValue) return 1;
        if (!bValue) return -1;
        return new Date(bValue) - new Date(aValue); // Newest first by default
      }

      // Handle different data types
      if (typeof aValue === 'string') aValue = aValue.toLowerCase();
      if (typeof bValue === 'string') bValue = bValue.toLowerCase();

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return aValue - bValue;
      }
      if (Date.parse(aValue) && Date.parse(bValue)) {
        return new Date(aValue) - new Date(bValue);
      }
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue);
      }
      if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
        return (aValue === bValue) ? 0 : aValue ? -1 : 1;
      }
      return 0;
    });

    if (sortConfig.direction === 'ascending') {
      sorted.reverse(); // Reverse only if ascending is requested
    }
    return sorted;
  }, [filteredContacts, sortConfig]);

  // Calculate total pages
  const totalPages = useMemo(() => {
    return Math.ceil(sortedContacts.length / ITEMS_PER_PAGE) || 1;
  }, [sortedContacts.length]);

  // Get current page's contacts
  const currentContacts = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return sortedContacts.slice(startIndex, endIndex);
  }, [currentPage, sortedContacts]);

  /**
   * Handles the navigation to the contact details page.
   * @param {Object} contact - The contact object.
   */
  const handleViewDetails = useCallback((contact) => {
    navigate(`/admin/contacts/${contact.id}`); // Updated to use navigate
  }, [navigate]);

  /**
   * Handles navigating to the previous page.
   */
  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  /**
   * Handles navigating to the next page.
   */
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  // Reset to first page if filtered data, sorting, or search changes
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredContacts, sortConfig, debouncedSearchQuery]);

  const segmentOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "All", value: "all" },
  ];
  const handleStatusChange = (newStatus) => {
      setCurrentStatusFilter(newStatus)
  };
  const handleSearchChange = (newQuery) => {
      setSearchQuery(newQuery);
  };

      /**
     * Handles suggestion selection by navigating to the selected item's detail view.
     * @param {string|number} itemId - The ID of the selected inbox item.
     */
       const handleSuggestionSelect = useCallback(
        (itemId) => {
          const selectedContact = contacts.find(contact => contact.id === itemId)
            if(selectedContact){
              navigate(`/admin/contacts/${selectedContact.id}`);
            }
        },
        [navigate, contacts]
    );


  if (isLoading) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
        <TableSkeleton rowCount={10} />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} p={4} color="red.500">
        <Text>Failed to load contacts. Please try again later.</Text>
      </Box>
    );
  }

  if (contacts.length === 0) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} p={4}>
        <Text>No contacts available.</Text>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
      <PageHeader
        title="Contacts"
         segmentOptions={segmentOptions}
        selectedSegment={currentStatusFilter}
         onSegmentChange={handleStatusChange}
        searchPlaceholder="Search contacts..."
        onSearchChange={handleSearchChange}
        searchSuggestions={searchSuggestions}
         searchQuery={searchQuery}
         onSuggestionSelect={handleSuggestionSelect}
      />

      {/* Contacts Table */}
      <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p={4}
          bg="white"
        >
          <ContactsTable
            contacts={currentContacts}
            onViewDetails={handleViewDetails}
            onSort={handleSort}
            sortConfig={sortConfig}
            searchQuery={debouncedSearchQuery}
          />
        </Box>
      </SimpleGrid>

      {/* Pagination Controls */}
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPrevious={handlePreviousPage}
        onNext={handleNextPage}
      />
    </Box>
  );
};

export default Contacts;
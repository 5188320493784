// src/components/Sales Target/GapAnalysisGrid.js

import React from 'react';
import { Text, Grid, GridItem } from "@chakra-ui/react";
import Card from "components/card/Card";
import { formatCurrencyValue } from '../../../utils/formatters';

const GapAnalysisGrid = ({ salesGap, pipeGap, selectedSegment }) => {
  return (
    <Card p="20px">
      <Text fontSize="md" fontWeight="bold" color="gray.700" mb={4}>
        Gap Analysis{selectedSegment && selectedSegment !== "All" ? ` (${selectedSegment})` : ''}
      </Text>
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        <GridItem>
          <Text fontSize="sm" color="gray.500">Sales Gap</Text>
          <Text
            fontSize="lg"
            fontWeight="bold"
            color={salesGap >= 0 ? "green.500" : "red.500"}
          >
            {formatCurrencyValue(salesGap)}
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize="sm" color="gray.500">Pipe Gap</Text>
          <Text
            fontSize="lg"
            fontWeight="bold"
            color={pipeGap >= 0 ? "green.500" : "red.500"}
          >
            {formatCurrencyValue(pipeGap)}
          </Text>
        </GridItem>
      </Grid>
    </Card>
  );
};

export default GapAnalysisGrid;
// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Deals/index.jsx
import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverHeader,
  PopoverFooter,
  Stack,
  FormControl,
  FormLabel,
  Input,
  CheckboxGroup,
  Checkbox,
  Icon,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { FiFilter } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useAuthData } from "auth-context";
import DealsTable from "./DealsTable";
import PaginationControls from "../Shared/PaginationControls";
import PageHeader from "../Shared/PageHeader";
import TableSkeleton from "../Shared/TableSkeleton";
import useFetchDeals from "../hooks/useFetchDeals";
import { useDebounce } from "use-debounce";
import { useToast } from "@chakra-ui/react"; // Import useToast for notifications

const ITEMS_PER_PAGE = 10;

const Deals = () => {
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const navigate = useNavigate();
  const toast = useToast(); // Initialize toast for notifications
  const { deals, isLoading, isError } = useFetchDeals(accountID);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  // Sorting state
  const [sortConfig, setSortConfig] = useState({ 
    key: 'last_activity_date', 
    direction: 'descending' 
  });

  // Filter states
  const [currentStatusFilter, setCurrentStatusFilter] = useState("active");
  const [searchQuery, setSearchQuery] = useState("");

  // Debounce the search query by 300ms
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  // Generate search suggestions based on deal names mapped to deal IDs
  const searchSuggestions = useMemo(() => {
    // Filter deals based on search query before mapping to suggestions
    return deals
      .filter(deal => 
        deal.deal_name && 
        deal.deal_name.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
      )
      .map(deal => ({ 
        id: deal.id, 
        label: deal.deal_name 
      }));
  }, [deals, debouncedSearchQuery]); // Add debouncedSearchQuery as dependency

  // Handle sorting
  const handleSort = (key) => {
    setSortConfig((prevState) => {
      if (prevState.key === key) {
        // Toggle direction
        return {
          key,
          direction: prevState.direction === "ascending" ? "descending" : "ascending",
        };
      } else {
        // New sort
        return { key, direction: "ascending" };
      }
    });
  };

  // New filter states
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedStages, setSelectedStages] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);
  const [filterType, setFilterType] = useState("closeDate"); // or "lastActivity"

  // Get unique stages and sources from the deals data
  const availableStages = useMemo(() => {
    const stages = new Set(deals.map(deal => deal.stage_name).filter(Boolean));
    return Array.from(stages).sort();
  }, [deals]);
  
  const availableSources = useMemo(() => {
    const sources = new Set(deals.map(deal => deal.lead_source).filter(Boolean));
    return Array.from(sources).sort();
  }, [deals]);

  // Enhanced filter logic
  const filteredDeals = useMemo(() => {
    let filtered = deals;

    // Status Filter (existing logic)
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

    if (currentStatusFilter === "active") {
      filtered = filtered.filter((deal) => {
        if (!deal.last_activity_date) return false;
        const lastActivity = new Date(deal.last_activity_date);
        return lastActivity >= twoWeeksAgo;
      });
    } else if (currentStatusFilter === "inactive") {
      filtered = filtered.filter((deal) => {
        if (!deal.last_activity_date) return true;
        const lastActivity = new Date(deal.last_activity_date);
        return lastActivity < twoWeeksAgo;
      });
    }

    // Date Range Filter
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      filtered = filtered.filter((deal) => {
        const dateToCheck = filterType === "closeDate" 
          ? new Date(deal.close_date)
          : new Date(deal.last_activity_date);
        return dateToCheck >= start && dateToCheck <= end;
      });
    }

    // Stage Filter
    if (selectedStages.length > 0) {
      filtered = filtered.filter((deal) => 
        selectedStages.includes(deal.stage_name)
      );
    }

    // Source Filter
    if (selectedSources.length > 0) {
      filtered = filtered.filter((deal) => 
        selectedSources.includes(deal.lead_source)
      );
    }

    // Search Filter (existing logic)
    if (debouncedSearchQuery.trim() !== "") {
      const query = debouncedSearchQuery.toLowerCase();
      filtered = filtered.filter(
        (deal) =>
          (deal.deal_name && deal.deal_name.toLowerCase().includes(query)) ||
          (deal.crm_account_name && deal.crm_account_name.toLowerCase().includes(query)) ||
          (deal.user_name && deal.user_name.toLowerCase().includes(query)) ||
          (deal.user_last_name && deal.user_last_name.toLowerCase().includes(query)) ||
          (deal.pipeline_name && deal.pipeline_name.toLowerCase().includes(query)) ||
          (deal.lead_source && deal.lead_source.toLowerCase().includes(query))
      );
    }

    return filtered;
  }, [deals, currentStatusFilter, debouncedSearchQuery, startDate, endDate, selectedStages, selectedSources, filterType]);

  // Filter reset handler
  const handleResetFilters = () => {
    setStartDate("");
    setEndDate("");
    setSelectedStages([]);
    setSelectedSources([]);
    setFilterType("closeDate");
  };

  // Sort deals
  const sortedDeals = useMemo(() => {
    if (!sortConfig.key) return filteredDeals;

    const sorted = [...filteredDeals].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Special handling for last_activity_date
      if (sortConfig.key === 'last_activity_date') {
        // Deals with no last_activity_date should be sorted last
        if (!aValue && !bValue) return 0;
        if (!aValue) return 1;
        if (!bValue) return -1;
        return new Date(bValue) - new Date(aValue); // Newest first by default
      }

      // Handle different data types
      if (typeof aValue === "string") aValue = aValue.toLowerCase();
      if (typeof bValue === "string") bValue = bValue.toLowerCase();

      if (typeof aValue === "number" && typeof bValue === "number") {
        return aValue - bValue;
      }
      if (Date.parse(aValue) && Date.parse(bValue)) {
        return new Date(aValue) - new Date(bValue);
      }
      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue);
      }
      return 0;
    });

    if (sortConfig.direction === "ascending") {
      sorted.reverse(); // Reverse only if ascending is requested
    }
    return sorted;
  }, [filteredDeals, sortConfig]);

  // Calculate total pages
  const totalPages = useMemo(() => {
    return Math.ceil(sortedDeals.length / ITEMS_PER_PAGE) || 1;
  }, [sortedDeals.length]);

  // Get current page's deals
  const currentDeals = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return sortedDeals.slice(startIndex, endIndex);
  }, [currentPage, sortedDeals]);

  /**
   * Handles the navigation to the deal details page.
   * @param {Object} deal - The deal object.
   */
  const handleViewDetails = useCallback(
    (deal) => {
      navigate(`/admin/deals/${deal.id}`);
    },
    [navigate]
  );

  /**
   * Handles suggestion selection by navigating to the selected deal's detail view.
   * @param {number|string} dealId - The ID of the selected deal.
   */
  const handleSuggestionSelect = useCallback(
    (dealId) => {
      const selectedDeal = deals.find(deal => deal.id === dealId);
      if (selectedDeal) {
        navigate(`/admin/deals/${selectedDeal.id}`);
      } else {
        toast({
          title: 'Deal not found.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [navigate, deals, toast]
  );

  /**
   * Handles navigating to the previous page.
   */
  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  /**
   * Handles navigating to the next page.
   */
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  // Reset to first page if filtered data, sorting, or search changes
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredDeals, sortConfig, debouncedSearchQuery]);

  const segmentOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "All", value: "all" },
  ];
  
  const handleStatusChange = (newStatus) => {
    setCurrentStatusFilter(newStatus);
  };
  
  const handleSearchChange = (newQuery) => {
    setSearchQuery(newQuery);
  };

  // Add this new handler
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleApplyFilters = () => {
    setIsFilterOpen(false); // This will close the popover
  };

  const hasActiveFilters = startDate || endDate || selectedStages.length > 0 || selectedSources.length > 0;

  // Move loading check before any data processing
  if (isLoading) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
        <TableSkeleton rowCount={10} />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} p={4} color="red.500">
        <Text>Failed to load deals. Please try again later.</Text>
      </Box>
    );
  }

  // Check for "No deals" only after loading
  if (!isLoading && (!deals || deals.length === 0)) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} p={4}>
        <Text>No deals available.</Text>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
      <PageHeader
        title="Deals"
        segmentOptions={segmentOptions}
        selectedSegment={currentStatusFilter}
        onSegmentChange={handleStatusChange}
        searchPlaceholder="Search deals..."
        onSearchChange={handleSearchChange}
        searchSuggestions={searchSuggestions}
        searchQuery={searchQuery}
        onSuggestionSelect={handleSuggestionSelect}
        filteredCount={filteredDeals.length}
        hasActiveFilters={hasActiveFilters}
        additionalControls={
          <Popover 
            placement="bottom-end" 
            isOpen={isFilterOpen}
            onClose={() => setIsFilterOpen(false)}
          >
            <PopoverTrigger>
              <Button
                leftIcon={<Icon as={FiFilter} />}
                variant="outline"
                bg="white"
                borderRadius="md"
                borderColor="gray.300"
                _hover={{
                  bg: "gray.50",
                }}
                onClick={() => setIsFilterOpen(true)}
              >
                {hasActiveFilters && filteredDeals.length > 0 ? `Filters (${filteredDeals.length} Deal${filteredDeals.length === 1 ? '' : 's'})` : 'Filters'}
              </Button>
            </PopoverTrigger>
            <PopoverContent width="400px">
              <PopoverHeader fontWeight="semibold">Advanced Filters</PopoverHeader>
              <PopoverBody>
                <VStack spacing={4} align="stretch">
                  <Box 
                    borderWidth="1px" 
                    borderRadius="md" 
                    p={4}
                    bg="gray.50"
                  >
                    <FormControl mb={4}>
                      <FormLabel fontWeight="medium">Filter Date By</FormLabel>
                      <HStack>
                        <Button
                          size="sm"
                          variant={filterType === "closeDate" ? "solid" : "outline"}
                          onClick={() => setFilterType("closeDate")}
                          colorScheme="blue"
                        >
                          Close Date
                        </Button>
                        <Button
                          size="sm"
                          variant={filterType === "lastActivity" ? "solid" : "outline"}
                          onClick={() => setFilterType("lastActivity")}
                          colorScheme="blue"
                        >
                          Last Activity
                        </Button>
                      </HStack>
                    </FormControl>

                    <FormControl>
                      <FormLabel fontWeight="medium">Select Range</FormLabel>
                      <Stack direction="row">
                        <Input
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          bg="white"
                        />
                        <Input
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          bg="white"
                        />
                      </Stack>
                    </FormControl>
                  </Box>

                  <FormControl>
                    <FormLabel fontWeight="medium">Deal Stages</FormLabel>
                    <Box 
                      maxH="200px" 
                      overflowY="auto" 
                      borderWidth="1px"
                      borderColor="gray.200"
                      borderRadius="md"
                      bg="gray.50"
                      _hover={{
                        borderColor: "gray.300"
                      }}
                      sx={{
                        '&::-webkit-scrollbar': {
                          width: '8px',
                          borderRadius: '8px',
                          backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          borderRadius: '8px',
                        },
                      }}
                    >
                      <CheckboxGroup value={selectedStages} onChange={setSelectedStages}>
                        <Stack spacing={2} p={3}>
                          {availableStages.map((stage) => (
                            <Checkbox key={stage} value={stage}>
                              {stage}
                            </Checkbox>
                          ))}
                        </Stack>
                      </CheckboxGroup>
                    </Box>
                  </FormControl>

                  <FormControl>
                    <FormLabel fontWeight="medium">Deal Sources</FormLabel>
                    <Box 
                      maxH="200px" 
                      overflowY="auto"
                      borderWidth="1px"
                      borderColor="gray.200"
                      borderRadius="md"
                      bg="gray.50"
                      _hover={{
                        borderColor: "gray.300"
                      }}
                      sx={{
                        '&::-webkit-scrollbar': {
                          width: '8px',
                          borderRadius: '8px',
                          backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          borderRadius: '8px',
                        },
                      }}
                    >
                      <CheckboxGroup value={selectedSources} onChange={setSelectedSources}>
                        <Stack spacing={2} p={3}>
                          {availableSources.map((source) => (
                            <Checkbox key={source} value={source}>
                              {source}
                            </Checkbox>
                          ))}
                        </Stack>
                      </CheckboxGroup>
                    </Box>
                  </FormControl>
                </VStack>
              </PopoverBody>
              <PopoverFooter>
                <HStack justify="flex-end" spacing={2}>
                  <Button size="sm" onClick={handleResetFilters}>Reset</Button>
                  <Button size="sm" colorScheme="blue" onClick={handleApplyFilters}>Apply</Button>
                </HStack>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        }
      />

      {/* Deals Table */}
      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} bg="white">
          <DealsTable
            deals={currentDeals}
            onViewDetails={handleViewDetails}
            onSort={handleSort}
            sortConfig={sortConfig}
            searchQuery={debouncedSearchQuery} // Pass debouncedSearchQuery
          />
        </Box>
      </SimpleGrid>

      {/* Pagination Controls */}
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPrevious={handlePreviousPage}
        onNext={handleNextPage}
      />
    </Box>
  );
};

export default Deals;
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Flex } from '@chakra-ui/react';
import { FaBuilding, FaClock, FaTasks } from 'react-icons/fa';
import { parseISO, formatDistanceToNow } from 'date-fns';
import BaseTag from '../Shared/BaseTag';

const OverviewSection = ({ 
  account, 
  taskType, 
  lastEngagementDate 
}) => {
  const formattedDate = lastEngagementDate 
    ? formatDistanceToNow(parseISO(lastEngagementDate), { addSuffix: true })
    : 'N/A';

  // Define overview items with their configurations
  const overviewItems = [
    {
      id: 'account',
      icon: FaBuilding,
      label: 'Account',
      value: account || 'Unknown Account',
      colorScheme: 'blue',
      tooltip: `Account: ${account || 'Unknown Account'}`
    },
    {
      id: 'taskType',
      icon: FaTasks,
      label: 'Task Type',
      value: taskType || 'Followup',
      colorScheme: 'green',
      tooltip: `Task Type: ${taskType || 'Followup'}`
    },
    {
      id: 'lastActivity',
      icon: FaClock,
      label: 'Last Activity',
      value: formattedDate,
      colorScheme: 'orange',
      tooltip: `Last Activity: ${formattedDate}`
    }
  ];

  return (
    <Box>
      <Flex gap={3} flexWrap="wrap">
        {overviewItems.map((item) => (
          <BaseTag
            key={item.id}
            icon={item.icon}
            colorScheme={item.colorScheme}
            tooltip={item.tooltip}
          >
            <Text fontSize="sm" fontWeight="medium">
              {item.value}
            </Text>
          </BaseTag>
        ))}
      </Flex>
    </Box>
  );
};

OverviewSection.propTypes = {
  account: PropTypes.string.isRequired,
  taskType: PropTypes.string,
  lastEngagementDate: PropTypes.string,
};

OverviewSection.defaultProps = {
  taskType: 'Followup',
  lastEngagementDate: null,
};

export default OverviewSection;
// src/components/CallRecordingPlayer.jsx

import React, { useState, useEffect, useMemo, useRef, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  VStack,
  Text,
  Flex,
  HStack,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaUser } from 'react-icons/fa';
import DOMPurify from 'dompurify';
import SearchInput from '../Shared/SearchInput';

const SPEAKER_COLORS = [
  { color: 'blue', hover: 'blue.50' },
  { color: 'green', hover: 'green.50' },
  { color: 'purple', hover: 'purple.50' },
  { color: 'orange', hover: 'orange.50' },
  { color: 'cyan', hover: 'cyan.50' },
  { color: 'pink', hover: 'pink.50' },
];

// Add debounce utility
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export default function CallRecordingPlayer({ 
  transcript: initialTranscript,
  searchQuery: initialSearchQuery,
  onSearchQueryChange
}) {
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery || '');
  const [parsedTranscript, setParsedTranscript] = useState([]);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [activeSearchTerm, setActiveSearchTerm] = useState('');
  const messageRefs = useRef({});

  // Create a mapping of speakers to colors
  const speakerColors = useMemo(() => {
    const uniqueSpeakers = [...new Set(parsedTranscript.map(entry => entry.speaker))];
    return Object.fromEntries(
      uniqueSpeakers.map((speaker, idx) => [
        speaker,
        SPEAKER_COLORS[idx % SPEAKER_COLORS.length]
      ])
    );
  }, [parsedTranscript]);

  // Parse transcript on prop change
  useEffect(() => {
    if (initialTranscript) {
      console.log('Parsing transcript:', {
        length: initialTranscript.length,
        preview: initialTranscript.substring(0, 100)
      });

      const lines = initialTranscript.split('\n').map(line => line.trim()).filter(line => line);
      console.log('Split into lines:', {
        lineCount: lines.length,
        firstLine: lines[0]
      });

      const parsed = [];
      let currentEntry = null;

      for (const line of lines) {
        // Check if this line starts with a timestamp (format: "HH:MM:SS - Speaker Name (Organization)")
        const headerMatch = line.match(/^(\d{2}:\d{2}:\d{2})\s*-\s*([^(]+)(?:\(([^)]+)\))?$/);
        
        if (headerMatch) {
          console.log('Found header match:', {
            line,
            match: headerMatch
          });

          // If we have a previous entry, push it
          if (currentEntry && currentEntry.text.trim()) {
            parsed.push(currentEntry);
          }
          
          // Start a new entry
          const [, timestamp, speaker, organization] = headerMatch;
          currentEntry = {
            id: parsed.length,
            timestamp,
            speaker: speaker.trim(),
            organization: organization ? organization.trim() : null,
            text: '',
            date_sent: new Date().toISOString(),
          };
        } else if (currentEntry) {
          // Append this line to the current entry's text if it's not empty
          const trimmedLine = line.trim();
          if (trimmedLine) {
            currentEntry.text += (currentEntry.text ? ' ' : '') + trimmedLine;
          }
        } else {
          console.log('Skipping line - no current entry:', line);
        }
      }
      
      // Don't forget to push the last entry if it has text
      if (currentEntry && currentEntry.text.trim()) {
        parsed.push(currentEntry);
      }

      console.log('Parsed transcript:', {
        entryCount: parsed.length,
        firstEntry: parsed[0]
      });
      
      setParsedTranscript(parsed);
    } else {
      console.log('No transcript provided');
      setParsedTranscript([]);
    }
  }, [initialTranscript]);

  // Memoize the search filter function
  const getFilteredSuggestions = useCallback((query, transcript) => {
    if (!query.trim()) return [];
    
    const lowerQuery = query.toLowerCase();
    return transcript
      .filter(item =>
        item.text.toLowerCase().includes(lowerQuery) ||
        item.speaker.toLowerCase().includes(lowerQuery)
      )
      .slice(0, 10) // Limit to 10 suggestions for better performance
      .map(item => ({
        id: item.id,
        label: `${item.timestamp} - ${item.speaker}: ${item.text.substring(0, 100)}${item.text.length > 100 ? '...' : ''}`
      }));
  }, []);

  // Memoize search suggestions
  const memoizedSearchSuggestions = useMemo(() => {
    return getFilteredSuggestions(searchQuery, parsedTranscript);
  }, [searchQuery, parsedTranscript, getFilteredSuggestions]);

  // Update search suggestions with debounce
  useEffect(() => {
    setSearchSuggestions(memoizedSearchSuggestions);
  }, [memoizedSearchSuggestions]);

  // Debounced search handler
  const debouncedHandleSearch = useCallback(
    debounce((query) => {
      setSearchQuery(query);
      if (onSearchQueryChange) {
        onSearchQueryChange(query);
      }
    }, 300),
    [onSearchQueryChange]
  );

  const handleSearch = (query) => {
    // Update the input value immediately for responsiveness
    setSearchQuery(query);
    // Debounce the actual search processing
    debouncedHandleSearch(query);
  };

  const handleSuggestionSelect = (messageId) => {
    // Save the current search term before clearing the search
    setActiveSearchTerm(searchQuery);
    setSearchQuery('');
    
    // Wait for messages to be restored before scrolling
    setTimeout(() => {
      const messageRef = messageRefs.current[messageId];
      if (messageRef) {
        messageRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 0);
  };

  // Optimize highlight text function with memoization
  const highlightText = useCallback((text, searchTerm) => {
    if (!searchTerm) return text;
    
    // Cache the regex to avoid recompilation
    const regex = useMemo(() => 
      new RegExp(`(${searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi'),
      [searchTerm]
    );

    const parts = text.split(regex);
    return parts.map((part, i) => {
      if (part.toLowerCase() === searchTerm.toLowerCase()) {
        return (
          <Text
            key={i}
            as="span"
            bg="blue.100"
            color="gray.900"
            px={0.5}
            rounded="sm"
          >
            {part}
          </Text>
        );
      }
      return part;
    });
  }, []);

  // Memoize TranscriptMessage component
  const TranscriptMessage = memo(({ message }) => {
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const speakerColor = speakerColors[message.speaker] || SPEAKER_COLORS[0];
    
    // Sanitize text once per render
    const sanitizedText = useMemo(() => DOMPurify.sanitize(message.text), [message.text]);

    return (
      <Box 
        width="100%" 
        mb={3} 
        ref={el => messageRefs.current[message.id] = el}
      >
        <Box
          p={3}
          bg={bgColor}
          boxShadow="sm"
          borderRadius="md"
          borderWidth="1px"
          borderColor={borderColor}
          transition="all 0.2s"
          _hover={{
            borderColor: `${speakerColor.color}.300`,
            bg: speakerColor.hover,
          }}
        >
          <HStack spacing={4} width="100%" align="flex-start">
            <Flex
              align="center"
              justify="center"
              boxSize="24px"
              borderRadius="full"
              bg={`${speakerColor.color}.100`}
              color={`${speakerColor.color}.700`}
            >
              <Icon as={FaUser} boxSize={3} />
            </Flex>
            
            <VStack align="start" flex="1" spacing={1}>
              <HStack spacing={2} width="100%" justify="space-between">
                <HStack spacing={2}>
                  <Text fontWeight="medium" fontSize="sm" color={`${speakerColor.color}.700`}>
                    {highlightText(message.speaker, activeSearchTerm)}
                    {message.organization && (
                      <Text as="span" color="gray.500" fontWeight="normal">
                        {' '}({message.organization})
                      </Text>
                    )}
                  </Text>
                </HStack>
                <Text fontSize="xs" color="gray.500">
                  {message.timestamp}
                </Text>
              </HStack>
              <Box
                fontSize="sm"
                color="gray.700"
                width="100%"
              >
                <Text>{highlightText(sanitizedText, activeSearchTerm)}</Text>
              </Box>
            </VStack>
          </HStack>
        </Box>
      </Box>
    );
  }, (prevProps, nextProps) => {
    // Custom comparison function for memo
    return (
      prevProps.message.id === nextProps.message.id &&
      prevProps.message.text === nextProps.message.text &&
      prevProps.message.speaker === nextProps.message.speaker
    );
  });

  TranscriptMessage.displayName = 'TranscriptMessage';

  // Memoize the filtered transcript for rendering
  const filteredTranscript = useMemo(() => {
    if (!activeSearchTerm) return parsedTranscript;
    
    const lowerQuery = activeSearchTerm.toLowerCase();
    return parsedTranscript.filter(item =>
      item.text.toLowerCase().includes(lowerQuery) ||
      item.speaker.toLowerCase().includes(lowerQuery)
    );
  }, [parsedTranscript, activeSearchTerm]);

  return (
    <Box>
      {/* Header with Search */}
      <Flex justify="space-between" align="center" mb={4} position="relative" zIndex="dropdown">
      <Text fontSize="md" fontWeight="semibold" color="gray.700">
                      Meeting Transcript
                  </Text>
        <Box width="300px">
          <SearchInput
            searchPlaceholder="Search transcript..."
            onSearchChange={handleSearch}
            searchSuggestions={searchSuggestions}
            searchQuery={searchQuery}
            onSuggestionSelect={handleSuggestionSelect}
          />
        </Box>
      </Flex>

      {/* Transcript Messages */}
      <Box 
        maxHeight="600px" 
        overflowY="auto"
        borderWidth="1px"
        borderColor={useColorModeValue("gray.200", "gray.600")}
        borderRadius="md"
        p={4}
      >
        <VStack spacing={0} align="stretch">
          {filteredTranscript.map((entry) => (
            <TranscriptMessage
              key={entry.id}
              message={entry}
            />
          ))}
        </VStack>
      </Box>
    </Box>
  );
}

CallRecordingPlayer.propTypes = {
  transcript: PropTypes.string,
  searchQuery: PropTypes.string,
  onSearchQueryChange: PropTypes.func
};

const noop = (...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Noop function called with args:', args);
  }
};

CallRecordingPlayer.defaultProps = {
  transcript: '',
  searchQuery: '',
  onSearchQueryChange: noop
};
import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  VStack,
  HStack,
  Text,
  Switch,
  Button,
  Input,
  Checkbox,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, AddIcon, CloseIcon } from '@chakra-ui/icons';
import { useAuthData } from '../../../auth-context';
import axios from 'axios';
import crmData from './crmData.json';

const CRMSection = () => {
  const [stages, setStages] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getAccessToken, account_ID: accountID } = useAuthData();
  const toast = useToast();
  const apiUrl = process.env.REACT_APP_API_URL || "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";

  useEffect(() => {
    const fetchStages = async () => {
      try {
        const accessToken = await getAccessToken();
        const response = await axios.get(
          `${apiUrl}/accounts/${accountID}/crm/stages`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        
        // If we have stages from the API, use them; otherwise, use the default stages from crmData
        const initialStages = (response.data?.stages || crmData.stages).map(stage => ({ 
          ...stage, 
          expanded: false 
        }));
        setStages(initialStages);
      } catch (error) {
        console.error('Error fetching CRM stages:', error);
        // Fall back to default stages from crmData if API call fails
        const initialStages = crmData.stages.map(stage => ({ ...stage, expanded: false }));
        setStages(initialStages);
      } finally {
        setLoading(false);
      }
    };

    if (accountID) {
      fetchStages();
    }
  }, [accountID, getAccessToken, apiUrl]);

  const saveStages = async () => {
    try {
      const accessToken = await getAccessToken();
      await axios.put(
        `${apiUrl}/accounts/${accountID}/crm/stages`,
        { stages },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      
      toast({
        title: "Changes Saved",
        description: "Your CRM stage configuration has been updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving CRM stages:', error);
      toast({
        title: "Error Saving Changes",
        description: "Failed to save your CRM stage configuration.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const toggleQualification = (index) => {
    const newStages = [...stages];
    newStages[index].qualification = !newStages[index].qualification;
    setStages(newStages);
  };

  const toggleExpand = (index) => {
    const newStages = [...stages];
    newStages[index].expanded = !newStages[index].expanded;
    setStages(newStages);
  };

  const addQuestion = (stageIndex) => {
    const newStages = [...stages];
    newStages[stageIndex].questions.push({
      text: '',
      required: false,
      requirement: '',
    });
    setStages(newStages);
  };

  const updateQuestion = (stageIndex, questionIndex, field, value) => {
    const newStages = [...stages];
    newStages[stageIndex].questions[questionIndex][field] = value;
    setStages(newStages);
  };

  const removeQuestion = (stageIndex, questionIndex) => {
    const newStages = [...stages];
    newStages[stageIndex].questions.splice(questionIndex, 1);
    setStages(newStages);
  };

  const updateStageName = (index, newName) => {
    const newStages = [...stages];
    newStages[index].name = newName;
    setStages(newStages);
  };

  const addStage = () => {
    const newStages = [
      ...stages,
      {
        name: '',
        qualification: false,
        questions: [],
        expanded: true,
      },
    ];
    setStages(newStages);
  };

  const removeStage = (stageIndex) => {
    const newStages = [...stages];
    newStages.splice(stageIndex, 1);
    setStages(newStages);
  };

  if (loading) {
    return (
      <Box borderWidth="1px" borderRadius="lg" p={6} bg="white" boxShadow="md">
        <Text>Loading CRM configuration...</Text>
      </Box>
    );
  }

  return (
    <Box borderWidth="1px" borderRadius="lg" p={6} bg="white" boxShadow="md">
      <Heading as="h3" size="md" mb={2}>
        CRM
      </Heading>
      <Text mb={6}>Configure AI-assisted qualification forms for each stage of your sales process.</Text>
      <VStack spacing={4} align="stretch">
        {stages.map((stage, stageIndex) => (
          <Box key={stageIndex} borderWidth="1px" borderRadius="md" p={4}>
            <HStack justifyContent="space-between">
              <Input
                value={stage.name}
                onChange={(e) => updateStageName(stageIndex, e.target.value)}
                placeholder="Enter stage name"
              />
              <HStack>
                <Text>Qualification</Text>
                <Switch
                  isChecked={stage.qualification}
                  onChange={() => toggleQualification(stageIndex)}
                />
                <IconButton
                  icon={stage.expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  onClick={() => toggleExpand(stageIndex)}
                  variant="action"
                  size="sm"
                />
                <IconButton
                  icon={<CloseIcon />}
                  onClick={() => removeStage(stageIndex)}
                  variant="action"
                  size="sm"
                  colorScheme="red"
                />
              </HStack>
            </HStack>
            {stage.expanded && stage.qualification && (
              <Box mt={4}>
                <VStack spacing={4} align="stretch">
                  {stage.questions.map((question, questionIndex) => (
                    <Box key={questionIndex} borderWidth="1px" borderRadius="md" p={4}>
                      <HStack justifyContent="space-between" mb={2}>
                        <Input
                          value={question.text}
                          onChange={(e) =>
                            updateQuestion(stageIndex, questionIndex, 'text', e.target.value)
                          }
                          placeholder="Enter question"
                        />
                        <IconButton
                          icon={<CloseIcon />}
                          onClick={() => removeQuestion(stageIndex, questionIndex)}
                          variant="action"
                          size="sm"
                        />
                      </HStack>
                      <HStack>
                        <Checkbox
                          isChecked={question.required}
                          onChange={(e) =>
                            updateQuestion(stageIndex, questionIndex, 'required', e.target.checked)
                          }
                        >
                          Required
                        </Checkbox>
                        <Input
                          value={question.requirement}
                          onChange={(e) =>
                            updateQuestion(stageIndex, questionIndex, 'requirement', e.target.value)
                          }
                          placeholder="Enter requirement (optional)"
                          isDisabled={!question.required}
                        />
                      </HStack>
                    </Box>
                  ))}
                  <Button onClick={() => addQuestion(stageIndex)} leftIcon={<AddIcon />} variant="action">
                    Add Question
                  </Button>
                </VStack>
              </Box>
            )}
          </Box>
        ))}
        <Button onClick={addStage} leftIcon={<AddIcon />} variant="action" colorScheme="blue">
          Add Stage
        </Button>
        <Button onClick={saveStages} colorScheme="blue">
          Save Changes
        </Button>
      </VStack>
    </Box>
  );
};

export default CRMSection;

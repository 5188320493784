import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { AttendeeTag } from './TagComponents';

const AttendeesSection = ({ attendees }) => {

    // Helper function to capitalize the first letter of a name
  const capitalizeFirstName = (name) => {
    if (!name) return "";
    return name.split(" ")[0].charAt(0).toUpperCase() + name.split(" ")[0].slice(1);
  };

    // Sort attendees by response_status
    const sortedAttendees = [...attendees].sort((a, b) => {
    const statusOrder = {
      accepted: 1,
      tentative: 2,
      declined: 3,
      needsAction: 4, // Assuming "needsAction" is the correct status value
      Unknown: 5, // Handle other/unknown statuses last
    };
    
    const statusA = a.response_status || 'Unknown';
    const statusB = b.response_status || 'Unknown';
    
    return (statusOrder[statusA] || 999) - (statusOrder[statusB] || 999);
  });
    
  return (
    <Box>
      <Text fontSize="md" fontWeight="semibold" color="gray.700" mb={4}>
        Attendees
      </Text>
      <Flex flexWrap="wrap" gap={2}>
        {sortedAttendees.map((attendee, index) => (
          <AttendeeTag
            key={`${attendee.email}-${index}`}
            attendee={{...attendee, name: capitalizeFirstName(attendee.display_name || attendee.name)}}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default AttendeesSection;
// src/components/Sales Target/TargetComparisonCard.js

import React from 'react';
import { Box, Text, Flex } from "@chakra-ui/react";
import { MdTrackChanges } from "react-icons/md";
import Card from "components/card/Card";

const TargetComparisonCard = ({ value, target, primaryColor, selectedSegment }) => {
  return (
    <Card p="20px">
      <Flex alignItems="center" mb={4}>
        <Box
          bg={primaryColor}
          w="56px"
          h="56px"
          borderRadius="50%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          mr={4}
        >
          <MdTrackChanges size="32px" color="white" />
        </Box>
        <Box>
          <Text fontSize="sm" color="gray.500">
            vs. Target{selectedSegment && selectedSegment !== "All" ? ` (${selectedSegment})` : ''}
          </Text>
          <Flex alignItems="baseline">
            <Text fontSize="2xl" fontWeight="bold" mr={2}>
              {value.toFixed(1)}%
            </Text>
            <Text fontSize="md" color="gray.600">
              {target}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Card>
  );
};

export default TargetComparisonCard;
import { useState, useEffect } from 'react';

const DEFAULT_MONTHLY_TARGET = 25000;

const useSalesTargets = () => {
  const [salesTargets, setSalesTargets] = useState(() => {
    const savedTargets = localStorage.getItem('salesTargets');
    if (savedTargets) {
      return JSON.parse(savedTargets);
    }
    
    // Initialize with default values for each month
    const defaultTargets = {};
    for (let month = 1; month <= 12; month++) {
      defaultTargets[month] = DEFAULT_MONTHLY_TARGET;
    }
    return defaultTargets;
  });

  useEffect(() => {
    localStorage.setItem('salesTargets', JSON.stringify(salesTargets));
  }, [salesTargets]);

  const updateMonthlyTarget = (month, target) => {
    setSalesTargets(prev => ({
      ...prev,
      [month]: target
    }));
  };

  return {
    salesTargets,
    updateMonthlyTarget,
    DEFAULT_MONTHLY_TARGET
  };
};

export default useSalesTargets; 
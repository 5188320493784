import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Center, Spinner } from '@chakra-ui/react';
import { useAuthData } from 'auth-context';
import AccountDetail from './AccountDetail';
import GoBackHeader from '../Shared/GoBackHeader';
import useFetchAccountById from '../hooks/useFetchAccountById';

const AccountDetailsPage = () => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const userData = useAuthData();
  const accountID = userData?.account_ID || '';

  // Use the hook with both IDs
  const { account, isLoading, isError } = useFetchAccountById(accountID, accountId);

  const handleGoBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (isError) {
    return (
      <Center h="100vh">
        <Box>Error fetching account details.</Box>
      </Center>
    );
  }

  if (!account) {
    return (
      <Center h="100vh">
        <Box>Account not found.</Box>
      </Center>
    );
  }

  return (
    <Box pt={10}>
      <GoBackHeader
        onGoBack={handleGoBack}
        title={account.name || "Account Details"}
      />
      <AccountDetail account={account} />
    </Box>
  );
};

export default AccountDetailsPage;
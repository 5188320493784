import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import axios from "axios";
import {
  Box,
  Text,
  VStack,
  Button,
  useToast,
  SimpleGrid,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  Icon,
  useDisclosure,
  FormControl,
  FormLabel,
  Progress,
  ModalFooter,
} from "@chakra-ui/react";
import { useAuthData } from "auth-context";
import { useAuth0 } from "@auth0/auth0-react";
import { useGoogleIntegrations } from "../hooks/useGoogleIntegrations";
import ConnectionCard from './ConnectionCard';
import { FaEnvelope, FaCalendarAlt } from 'react-icons/fa';


const STEPS = [
  { id: 'service', title: 'Choose Service' },
  { id: 'permissions', title: 'Data Permissions' },
  { id: 'account', title: 'Google Account' },
];

const IntegrationsSection = forwardRef((props, ref) => {
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const userID = userData?.user_ID;
  const { getAccessTokenSilently } = useAuth0();
  const [connectingService, setConnectingService] = useState(null);
  const toast = useToast();
  const target =
    process.env.REACT_APP_NODE_ENV === "local"
      ? "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net"
      : "https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net";
  const { integrationStatus, refetch: refetchGoogleStatus } =
    useGoogleIntegrations();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [integrations, setIntegrations] = useState({
    gmail_integrations: [],
    calendar_integrations: [],
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentStep, setCurrentStep] = useState(0);
  const [integrationData, setIntegrationData] = useState({
    service: '',
    permissionType: '',
  });

  // Expose the onOpen function to parent
  useImperativeHandle(ref, () => ({
    openModal: () => {
      onOpen();
    }
  }));

  useEffect(() => {
    const checkConnectionStatus = async () => {
      if (!accountID || !userID) return;

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:calendar",
          },
        });

        // Only check HubSpot status since Google services are handled by useGoogleIntegrations
        const response = await axios.get(
          `${target}/accounts/${accountID}/users/${userID}/hubspot/status`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!response.data.connected) {
          console.log('HubSpot not connected');
        }
      } catch (err) {
        console.error("Error checking connection status:", err);
      }
    };

    checkConnectionStatus();
  }, [accountID, userID, getAccessTokenSilently, integrationStatus]);

  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const response = await fetch(
          `https://lysto-dtctbse3drdvhed6.eastus2-01.azurewebsites.net/accounts/${userData.account_ID}/google/status`
        );
        if (!response.ok) throw new Error('Failed to fetch integrations');
        const data = await response.json();
        setIntegrations(data);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    if (userData?.account_ID) {
      fetchIntegrations();
    }
  }, [userData?.account_ID]);

  const handleAuthenticationComplete = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          scope: "read:calendar",
        },
      });

      if (connectingService === "gmail" || connectingService === "calendar") {
        // Wait a moment for the backend to process the new connection
        await new Promise(resolve => setTimeout(resolve, 2000));
        await refetchGoogleStatus();
        
        // Refresh the integrations list
        const response = await axios.get(
          `${target}/accounts/${accountID}/google/status`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        
        // Check if the new integration was actually added
        const currentIntegrations = response.data;
        const previousCount = integrations?.gmail_integrations?.length || 0;
        const newCount = currentIntegrations?.gmail_integrations?.length || 0;
        
        if (newCount <= previousCount) {
          throw new Error("No new integration was detected. Please try again and select a different Google account.");
        }
        
        setIntegrations(currentIntegrations);
      } else {
        const response = await axios.get(
          `${target}/accounts/${accountID}/users/${userID}/${connectingService}/status`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data.connected) {
          console.log('Connection successful');
        } else {
          throw new Error("Connection verification failed");
        }
      }

      toast({
        title: "Connected successfully",
        description: `New ${connectingService} account has been connected to your account.`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (err) {
      console.error("Error verifying connection:", err);
      toast({
        title: "Connection failed",
        description: err.message,
        status: "error",
        duration: 7000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setConnectingService(null);
    }
  };

  useEffect(() => {
    const connectAccount = async () => {
      if (!accountID || !userID || !connectingService) return;

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:calendar",
          },
        });

        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const popupWidth = 500;
        const popupHeight = 600;
        const left = screenWidth / 2 - popupWidth / 2;
        const top = screenHeight / 2 - popupHeight / 2;

        const popupWindow = window.open(
          "",
          "authWindow",
          `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`
        );

        if (!popupWindow) {
          throw new Error(
            "Popup was blocked. Please allow popups for this site."
          );
        }

        // Force new account selection by adding prompt=select_account and access_type=offline
        const response = await axios.get(
          `${target}/accounts/${accountID}/users/${userID}/${connectingService}/auth?prompt=select_account&access_type=offline`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const authURL = response.data.auth_url;

        if (authURL) {
          // Ensure the Google OAuth URL has the necessary parameters
          const finalAuthURL = new URL(authURL);
          finalAuthURL.searchParams.set('prompt', 'select_account');
          finalAuthURL.searchParams.set('access_type', 'offline');
          // Remove approval_prompt as it conflicts with prompt parameter
          finalAuthURL.searchParams.delete('approval_prompt');
          popupWindow.location.href = finalAuthURL.toString();
        }

        const pollTimer = setInterval(() => {
          if (popupWindow.closed) {
            clearInterval(pollTimer);
            handleAuthenticationComplete();
          }
        }, 500);
      } catch (err) {
        console.error("Error Authenticating:", err);
        toast({
          title: "Connection failed",
          description: err.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setConnectingService(null);
      }
    };

    connectAccount();
  }, [accountID, userID, getAccessTokenSilently, connectingService, toast]);

  // Transform integrations into a format for ConnectionCard
  const sortedIntegrations = [
    ...(integrations.gmail_integrations || []).map(integration => ({
      type: 'gmail',
      connection: {
        email: integration.email,
        expiration: integration.expiration,
      }
    })),
    ...(integrations.calendar_integrations || []).map(integration => ({
      type: 'calendar',
      connection: {
        email: integration.email,
        expires_at: integration.expires_at,
      }
    }))
  ].sort((a, b) => {
    const aActive = a.type === 'gmail'
      ? new Date(a.connection.expiration * 1000) > new Date()
      : new Date(a.connection.expires_at) > new Date();
    const bActive = b.type === 'gmail'
      ? new Date(b.connection.expiration * 1000) > new Date()
      : new Date(b.connection.expires_at) > new Date();
    return bActive - aActive;
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIntegrationData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateStep = () => {
    const requiredFields = {
      service: ['service'],
      permissions: ['permissionType'],
      account: [],
    };

    const currentFields = requiredFields[STEPS[currentStep].id];
    const missingFields = currentFields.filter(field => !integrationData[field]);

    if (missingFields.length > 0) {
      toast({
        title: 'Required Fields Missing',
        description: `Please make a selection before proceeding.`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  const handleNext = () => {
    if (validateStep()) {
      if (currentStep === STEPS.length - 1) {
        // Start the OAuth flow
        setConnectingService(integrationData.service);
        onClose();
      } else {
        setCurrentStep((prev) => prev + 1);
      }
    }
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const renderServiceSelection = () => (
    <VStack spacing={4} align="stretch">
      <Text fontSize="sm" color="gray.600" mb={2}>
        Choose which Google service you&apos;d like to integrate with Lysto.
      </Text>
      
      <FormControl isRequired>
        <FormLabel>Service</FormLabel>
        <VStack spacing={4} align="stretch">
          <Button
            size="lg"
            leftIcon={<Icon as={FaEnvelope} />}
            onClick={() => handleInputChange({ target: { name: 'service', value: 'gmail' }})}
            colorScheme={integrationData.service === 'gmail' ? 'blue' : 'gray'}
            variant={integrationData.service === 'gmail' ? 'solid' : 'outline'}
          >
            Gmail
          </Button>
          <Button
            size="lg"
            leftIcon={<Icon as={FaCalendarAlt} />}
            onClick={() => handleInputChange({ target: { name: 'service', value: 'calendar' }})}
            colorScheme={integrationData.service === 'calendar' ? 'purple' : 'gray'}
            variant={integrationData.service === 'calendar' ? 'solid' : 'outline'}
          >
            Google Calendar
          </Button>
        </VStack>
      </FormControl>
    </VStack>
  );

  const renderPermissionsSelection = () => (
    <VStack spacing={4} align="stretch">
      <Text fontSize="sm" color="gray.600" mb={2}>
        Choose how you want to use this integration.
      </Text>
      
      <FormControl isRequired>
        <FormLabel>Permission Type</FormLabel>
        <VStack spacing={4} align="stretch">
          <Button
            size="lg"
            onClick={() => handleInputChange({ target: { name: 'permissionType', value: 'personal' }})}
            colorScheme={integrationData.permissionType === 'personal' ? 'blue' : 'gray'}
            variant={integrationData.permissionType === 'personal' ? 'solid' : 'outline'}
          >
            Personal Account
            <Text fontSize="sm" color="gray.500" ml={2}>
              Only access my own data
            </Text>
          </Button>
          <Button
            size="lg"
            onClick={() => handleInputChange({ target: { name: 'permissionType', value: 'shared' }})}
            colorScheme={integrationData.permissionType === 'shared' ? 'blue' : 'gray'}
            variant={integrationData.permissionType === 'shared' ? 'solid' : 'outline'}
          >
            Shared Account
            <Text fontSize="sm" color="gray.500" ml={2}>
              Access shared team data
            </Text>
          </Button>
        </VStack>
      </FormControl>
    </VStack>
  );

  const renderAccountSelection = () => (
    <VStack spacing={4} align="stretch">
      <Text fontSize="sm" color="gray.600" mb={2}>
        Click continue to select your Google account.
      </Text>
      
      <Text>
        You&apos;ll be prompted to select and authorize your Google account in the next step.
      </Text>
    </VStack>
  );

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return renderServiceSelection();
      case 1:
        return renderPermissionsSelection();
      case 2:
        return renderAccountSelection();
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <Box textAlign="center" py={10}>
        <Spinner size="lg" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" py={10} color="red.500">
        <Text>Error loading integrations: {error}</Text>
      </Box>
    );
  }

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
        {sortedIntegrations.map((item) => (
          <ConnectionCard
            key={`${item.type}-${item.connection.email}`}
            connection={item.connection}
            type={item.type}
          />
        ))}
      </SimpleGrid>

      {/* Integration Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxH="90vh">
          <ModalHeader p={0}>
            <Box px={6} pt={6} pb={4}>
              <Text mb={4}>{STEPS[currentStep].title}</Text>
              <Progress
                value={(currentStep + 1) * (100 / STEPS.length)}
                size="sm"
                colorScheme="blue"
                borderRadius="full"
                width="100%"
              />
            </Box>
          </ModalHeader>
          
          <ModalBody overflowY="auto">
            {renderStepContent()}
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4}>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              {currentStep > 0 && (
                <Button onClick={handleBack}>
                  Back
                </Button>
              )}
              <Button 
                colorScheme="blue" 
                onClick={handleNext}
              >
                {currentStep === STEPS.length - 1 ? 'Continue' : 'Next'}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
});

// Add display name to fix the linter error
IntegrationsSection.displayName = 'IntegrationsSection';

export default IntegrationsSection;

import React from 'react';
import { Box, Text } from "@chakra-ui/react";
import Chart from "react-apexcharts";

const filterDealsBySegment = (deals, segment) => {
  if (!segment || segment === "All") return deals;

  return deals.filter(deal => {
    const locations = deal.extra_fields?.No_of_Locations__c;
    if (locations === null || locations === undefined) return false;
    
    switch (segment) {
      case "SMB":
        return locations >= 1 && locations <= 20;
      case "Mid-Market":
        return locations > 20 && locations <= 200;
      case "Enterprise":
        return locations > 200;
      default:
        return true;
    }
  });
};

const WinRateByLeadSource = ({ deals, selectedYear, selectedQuarter, selectedSegment, primaryColor, secondaryColor }) => {
  const chartData = React.useMemo(() => {
    if (!deals?.currentPeriod) {
      return {
        sources: [],
        wonDeals: [],
        lostDeals: [],
        winRates: []
      };
    }

    // Filter deals by segment first
    const filteredDeals = filterDealsBySegment(deals.currentPeriod, selectedSegment);

    // Filter deals by year and quarter
    const periodDeals = filteredDeals.filter(deal => {
      if (!deal?.close_date) return false;
      const date = new Date(deal.close_date);
      if (isNaN(date.getTime())) return false;

      const dealYear = date.getFullYear();
      if (dealYear !== selectedYear) return false;

      if (selectedQuarter) {
        const dealQuarter = Math.floor(date.getMonth() / 3) + 1;
        return dealQuarter === selectedQuarter;
      }
      return true;
    });

    // Group by lead source
    const sourceStats = {};
    periodDeals.forEach(deal => {
      const source = deal.lead_source || 'Unknown';
      if (!sourceStats[source]) {
        sourceStats[source] = {
          wonDeals: 0,
          lostDeals: 0,
          totalDeals: 0
        };
      }
      sourceStats[source].totalDeals += 1;
      if (deal.is_won) {
        sourceStats[source].wonDeals += 1;
      } else {
        sourceStats[source].lostDeals += 1;
      }
    });

    // Process and sort by total deals
    const processedData = Object.entries(sourceStats)
      .map(([source, stats]) => ({
        source,
        ...stats,
        winRate: (stats.wonDeals / stats.totalDeals) * 100
      }))
      .sort((a, b) => b.totalDeals - a.totalDeals);

    return {
      sources: processedData.map(d => d.source),
      wonDeals: processedData.map(d => d.wonDeals),
      lostDeals: processedData.map(d => d.lostDeals),
      winRates: processedData.map(d => Math.round(d.winRate))
    };
  }, [deals, selectedYear, selectedQuarter, selectedSegment]);

  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 6
      }
    },
    colors: [primaryColor, secondaryColor],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.abs(val);
      }
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    yaxis: {
      title: {
        text: undefined
      }
    },
    tooltip: {
      shared: true,
      intersect: false
    },
    xaxis: {
      categories: chartData.sources,
      labels: {
        formatter: function (val) {
          return Math.abs(Math.round(val));
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    annotations: {
      position: 'front',
      points: chartData.sources.map((source, index) => ({
        x: chartData.wonDeals[index] + chartData.lostDeals[index],
        y: source,
        marker: {
          size: 0
        },
        label: {
          borderColor: 'transparent',
          style: {
            color: '#666',
            background: 'transparent'
          },
          offsetX: 10,
          text: `${chartData.winRates[index]}%`
        }
      }))
    }
  };

  const series = [
    {
      name: 'Won',
      data: chartData.wonDeals
    },
    {
      name: 'Lost',
      data: chartData.lostDeals
    }
  ];

  return (
    <Box bg="white" p={4} borderRadius="md" boxShadow="sm">
      <Text fontSize="lg" fontWeight="bold" mb={4}>
        Win Rate by Lead Source {selectedQuarter ? `(Q${selectedQuarter} ${selectedYear})` : `(${selectedYear})`}
        {selectedSegment !== "All" ? ` - ${selectedSegment}` : ""}
      </Text>
      {chartData.sources.length > 0 ? (
        <Chart
          options={chartOptions}
          series={series}
          type="bar"
          height={Math.max(350, chartData.sources.length * 40)}
        />
      ) : (
        <Text textAlign="center" py={8} color="gray.500">
          No lead source data available for {selectedQuarter ? `Q${selectedQuarter} ${selectedYear}` : selectedYear}
          {selectedSegment !== "All" ? ` in ${selectedSegment} segment` : ""}
        </Text>
      )}
    </Box>
  );
};

export default WinRateByLeadSource;
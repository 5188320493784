import React from 'react';
import { Text } from '@chakra-ui/react';
import { getActivityConfig } from './utils';
import { ActivityPropType } from './types';

const ActivityTitle = ({ activity }) => {
  // Handle both regular activities and Spark activities
  const activityType = activity.activity_type || activity.activity_data?.activity_type;
  const { category } = getActivityConfig({ ...activity, activity_type: activityType });
  
  // Get title from either direct title or email subject
  const title = activity.title || activity.activity_data?.data?.subject || "";

  // Get sender info with improved fallbacks
  const getSenderInfo = () => {
    // For email activities
    if (category === "email") {
      // Try activity_data first (Spark format)
      const senderName = activity.activity_data?.data?.sender_name;
      const senderEmail = activity.activity_data?.data?.sender_email;
      
      // If we have both name and email
      if (senderName && senderEmail) {
        return senderName;
      }
      
      // If we only have email, use the part before @
      if (senderEmail) {
        return senderEmail.split('@')[0];
      }
    }
    
    // For non-email activities or fallback
    if (activity.user_name || activity.user_last_name) {
      return [
        activity.user_name?.charAt(0).toUpperCase() + activity.user_name?.slice(1),
        activity.user_last_name?.charAt(0).toUpperCase() + activity.user_last_name?.slice(1),
      ]
        .filter(Boolean)
        .join(" ");
    }

    // Final fallback
    return "Unknown User";
  };

  const displayName = getSenderInfo();
  
  // Get recording/transcript info
  const isRecorded = activity.activity_data?.data?.is_recorded;
  const hasTranscript = Boolean(activity.activity_data?.data?.transcript);

  const getBaseText = () => {
    switch (category) {
      case "email":
        return "sent an email";
      case "call":
        return `made a call${hasTranscript ? " (transcribed)" : ""}`;
      case "meeting":
        return `${isRecorded ? "recorded" : "attended"} a meeting`;
      case "task":
        return "created a task";
      default:
        return "performed an activity";
    }
  };

  const renderTitle = () => {
    const baseText = getBaseText();
    if (!title) return baseText;
    return (
      <>
        {baseText}: <Text as="span" fontWeight="medium">{title}</Text>
      </>
    );
  };

  return (
    <Text>
      <Text as="span" fontWeight="semibold">
        {displayName}
      </Text>{" "}
      {renderTitle()}
    </Text>
  );
};

ActivityTitle.propTypes = {
  activity: ActivityPropType,
};

export default ActivityTitle;
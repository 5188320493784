import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Box, Text } from '@chakra-ui/react';
import remarkGfm from 'remark-gfm';

const MarkdownRenderer = ({ content, isMarkdown=true }) => {

  // If content is not markdown, use Text component
  if (!isMarkdown) {
    return (
      <Box
        className="markdown-content"
        p={6}
        fontSize="sm"
        sx={{
          'p': {
            mb: 3,
            fontSize: '0.95em',
            lineHeight: '1.6',
          },
        }}
      >
         <Text whiteSpace="pre-line" >{content || 'No content available.'}</Text>
      </Box>
    );
  }

    return (
      <Box
        className="markdown-content"
        p={6}
        fontSize="sm"
        sx={{
          'h1': {
            fontSize: '1.3em',
            fontWeight: '600',
            mb: 3,
            mt: 3,
            color: 'gray.800',
            borderBottom: '1px solid',
            borderColor: 'gray.200',
            paddingBottom: 1,
          },
          'h2': {
            fontSize: '1.1em',
            fontWeight: '600',
            mb: 3,
            mt: 3,
            color: 'gray.700',
            borderBottom: '1px solid',
            borderColor: 'gray.200',
            paddingBottom: 1,
          },
          'h3': {
            fontSize: '1.0em',
            fontWeight: '600',
            mb: 2,
            mt: 2,
            color: 'gray.700',
            borderBottom: '1px solid',
            borderColor: 'gray.200',
            paddingBottom: 1,
          },
          'h4': {
            fontSize: '0.9em',
            fontWeight: '600',
            mb: 1,
            mt: 2,
            color: 'gray.700',
          },
          'h5': {
            fontSize: '0.8em',
            fontWeight: '600',
            mb: 1,
            mt: 2,
            color: 'gray.700',
          },
          'ul, ol': {
            pl: 5,
            mb: 4,
          },
          'ul ul, ol ol, ul ol, ol ul': {
            mt: 2,
            mb: 0,
            ml: 3,
          },
          'li': {
            mb: 2,
            listStyleType: 'none',
            position: 'relative',
            pl: 2,
            fontSize: '0.95em',
            lineHeight: '1.6',
          },
          'ul > li::before': {
            content: '"•"',
            position: 'absolute',
            left: '-12px',
            color: 'gray.600',
          },
          'ol > li': {
            listStyleType: 'decimal',
            ml: 2,
          },
          'p': {
            mb: 3,
            fontSize: '0.95em',
            lineHeight: '1.6',
          },
          '> *:first-of-type': {
            mt: 0,
          },
          '> *:last-child': {
            mb: 0,
          },
          'h1 + ul, h2 + ul, h3 + ul': {
            mt: 2,
          },
          'blockquote': {
            bg: 'gray.100',
            borderLeft: '5px solid',
            borderColor: 'gray.300',
            p: 4,
            m: 4,
            borderRadius: 'md',
            fontStyle: 'italic',
            color: 'gray.600'
          },
          'code': {
            bg: 'gray.100',
            color: 'gray.800',
            borderRadius: 'sm',
            padding: '0.1em 0.3em',
            fontSize: '0.9em',
            fontFamily: 'monospace',
          },
        }}
      >
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
        >
          {content || 'No content available.'}
        </ReactMarkdown>
      </Box>
    );
  };

MarkdownRenderer.propTypes = {
  content: PropTypes.string.isRequired,
  isMarkdown: PropTypes.bool,
};

export default MarkdownRenderer;
import React, { useMemo, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Box,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { MdBusiness, MdOpenInNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import HoverBox from "../../../Shared/HoverBox";
import PaginationControls from "../../../Shared/PaginationControls";

const ITEMS_PER_PAGE = 8;

const UnpaidDealsTable = ({ deals, selectedSegment }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  // Filter deals by segment
  const filterDealsBySegment = (deals) => {
    if (!selectedSegment || selectedSegment === "All") return deals;

    return deals.filter(deal => {
      const locations = deal.extra_fields?.No_of_Locations__c;
      if (locations === null || locations === undefined) return false;
      
      switch (selectedSegment) {
        case "SMB":
          return locations >= 1 && locations <= 20;
        case "Mid-Market":
          return locations > 20 && locations <= 200;
        case "Enterprise":
          return locations > 200;
        default:
          return true;
      }
    });
  };

  // Filter and sort unpaid deals
  const unpaidDeals = useMemo(() => {
    if (!deals) return [];

    // First filter by unpaid status
    let filtered = deals.filter(deal => {
      // Has a closed won date but no closed paid date
      return deal.extra_fields?.Date_Time_Closed_Won__c && !deal.extra_fields?.Date_Time_Closed_Paid__c;
    });

    // Then apply segment filter
    filtered = filterDealsBySegment(filtered);

    // Sort by closed won date, most recent first
    return filtered.sort((a, b) => {
      const dateA = new Date(a.extra_fields.Date_Time_Closed_Won__c);
      const dateB = new Date(b.extra_fields.Date_Time_Closed_Won__c);
      return dateB - dateA;
    });
  }, [deals, selectedSegment]);

  // Calculate pagination values
  const totalPages = Math.max(1, Math.ceil(unpaidDeals.length / ITEMS_PER_PAGE));
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedDeals = unpaidDeals.slice(startIndex, endIndex);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const handleDealClick = (dealId) => {
    navigate(`/admin/deals/${dealId}`);
  };

  const handlePrevious = () => {
    setCurrentPage(prev => Math.max(1, prev - 1));
  };

  const handleNext = () => {
    setCurrentPage(prev => Math.min(totalPages, prev + 1));
  };

  if (!unpaidDeals.length) {
    return (
      <Box textAlign="center" py={4}>
        <Text>No unpaid deals found</Text>
      </Box>
    );
  }

  return (
    <Box>
      <Box overflowX="auto">
        <Table variant="simple" size="lg">
          <Thead>
            <Tr>
              <Th>Deal Name</Th>
              <Th isNumeric>Amount</Th>
              <Th>Won Date</Th>
              <Th>Days Since Won</Th>
              <Th>Owner</Th>
              <Th>Source</Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedDeals.map((deal) => {
              const wonDate = new Date(deal.extra_fields.Date_Time_Closed_Won__c);
              const daysSinceWon = Math.floor((new Date() - wonDate) / (1000 * 60 * 60 * 24));
              
              return (
                <Tr key={deal.id} _hover={{ bg: "gray.50" }}>
                  <Td>
                    <HoverBox
                      icon={MdBusiness}
                      hoverIcon={MdOpenInNew}
                      tooltipLabel="View Deal Details"
                      onClick={() => handleDealClick(deal.id)}
                    >
                      <Text fontSize="md" isTruncated maxWidth="100%">
                        {deal.deal_name || "Unnamed Deal"}
                      </Text>
                    </HoverBox>
                  </Td>
                  <Td isNumeric>
                    <Text fontSize="md" fontWeight="500">
                      {formatCurrency(deal.amount)}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="md">
                      {format(wonDate, 'M/d/yy')}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="md" color={daysSinceWon > 30 ? "red.500" : "inherit"}>
                      {daysSinceWon}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="md" isTruncated>
                      {deal.user_name ? `${deal.user_name} ${deal.user_last_name || ''}` : "—"}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="md" isTruncated>
                      {deal.lead_source || "—"}
                    </Text>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
    </Box>
  );
};

export default UnpaidDealsTable; 
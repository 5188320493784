// src/views/lysto/Shared/SearchInput.jsx

import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  useColorModeValue,
  List,
  ListItem,
} from "@chakra-ui/react";
import { Search } from "lucide-react";
import PropTypes from "prop-types";
import HighlightText from "./HighlightText";


const SearchInput = ({
  searchPlaceholder = "Search...",
  onSearchChange,
  searchSuggestions = [],
  searchQuery,
  onSuggestionSelect,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  // Theme Colors consistent with InboxFilterControls
  const searchBg = useColorModeValue("white", "gray.600");
  const searchBorderColor = useColorModeValue("gray.300", "gray.500");
  const suggestionBg = useColorModeValue("white", "gray.700");
  const suggestionBorderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("gray.100", "gray.600");

  // Update suggestion visibility when suggestions or query changes
  useEffect(() => {
    if (isFocused && searchQuery && searchSuggestions.length > 0) {
      setShowSuggestions(true);
    } else if (!searchQuery || searchSuggestions.length === 0) {
      setShowSuggestions(false);
    }
  }, [searchSuggestions, searchQuery, isFocused]);

  // Handle suggestion selection
  const handleSuggestionClick = useCallback((itemId) => {
    if (onSuggestionSelect) {
      onSuggestionSelect(itemId);
    }
    setShowSuggestions(false);
  }, [onSuggestionSelect]);

  // Handle focus and blur for suggestions
  const handleBlur = () => {
    setIsFocused(false);
    // Delay hiding to allow click event to register
    setTimeout(() => setShowSuggestions(false), 100);
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (searchQuery && searchSuggestions.length > 0) {
      setShowSuggestions(true);
    }
  };

  return (
    <Box width="100%" position="relative">
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={Search} color="gray.500" />
        </InputLeftElement>
        <Input
          type="text"
          placeholder={searchPlaceholder}
          value={searchQuery}
          onChange={(e) => onSearchChange(e.target.value)}
          bg={searchBg}
          borderColor={searchBorderColor}
          onFocus={handleFocus}
          onBlur={handleBlur}
          size="md"
          fontWeight="400"
        />
      </InputGroup>
      {showSuggestions && searchSuggestions.length > 0 && (
        <Box
          position="absolute"
          top="100%"
          left="0"
          right="0"
          bg={suggestionBg}
          border="1px solid"
          borderColor={suggestionBorderColor}
          borderRadius="md"
          mt={1}
          zIndex={10}
          maxHeight="200px"
          overflowY="auto"
        >
          <List spacing={0}>
            {searchSuggestions.map((suggestion) => (
              <ListItem
                  key={suggestion.id}
                  px={4}
                  py={2}
                  cursor="pointer"
                  _hover={{
                      bg: hoverBg,
                  }}
                  onClick={() => handleSuggestionClick(suggestion.id)}
              >
                   <HighlightText
                     text={suggestion.label}
                     query={searchQuery}
                     truncate={false} // Ensure no truncation
                   />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

SearchInput.propTypes = {
  searchPlaceholder: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
  searchSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  searchQuery: PropTypes.string,
  onSuggestionSelect: PropTypes.func,
};

SearchInput.defaultProps = {
  searchPlaceholder: "Search...",
  searchSuggestions: [],
  searchQuery: "",
  onSuggestionSelect: null,
};

export default SearchInput;

import React, { useMemo } from "react";
import { VStack, Card, Text, Box, SimpleGrid } from "@chakra-ui/react";
import DealCountSinceLastActivityBarCard from "./DealCountSinceLastActivityBarCard";
import DealCountByStageAndSourceBarCard from "./DealCountByStageAndSourceBarCard";
import UnpaidDealsTable from "../Sales Efficiency/UnpaidDealsTable";
import StatCard from "../StatCard";
import { MdAttachMoney, MdPayments, MdPendingActions } from "react-icons/md";

const PRIMARY_COLOR = "#1A5BF6";

// Helper function to filter deals by segment
const filterDealsBySegment = (deals, segment) => {
  if (!segment || segment === "All") return deals;

  return deals.filter(deal => {
    const locations = deal.extra_fields?.No_of_Locations__c;
    if (locations === null || locations === undefined) {
      return false;
    }
    
    switch (segment) {
      case "SMB":
        return locations >= 1 && locations <= 20;
      case "Mid-Market":
        return locations > 20 && locations <= 200;
      case "Enterprise":
        return locations > 200;
      default:
        return true;
    }
  });
};

// Helper function to calculate growth percentage
const calculateGrowth = (current, previous) => {
  if (!previous) return 0;
  return ((current - previous) / previous) * 100;
};

const SalesProcess = ({ 
  accountID, 
  toast, 
  selectedYear, 
  selectedQuarter,
  selectedSegment,
  deals 
}) => {
  const metrics = useMemo(() => {
    const calculatePeriodMetrics = (periodDeals) => {
      if (!periodDeals) return {
        totalWonValue: 0,
        totalPaidValue: 0,
        totalUnpaidValue: 0,
        wonCount: 0,
        paidCount: 0,
        unpaidCount: 0
      };

      // Filter deals by segment
      const filteredDeals = filterDealsBySegment(periodDeals, selectedSegment);

      // Get won deals
      const wonDeals = filteredDeals.filter(deal => 
        deal.extra_fields?.Date_Time_Closed_Won__c
      );
      const totalWonValue = wonDeals.reduce((sum, deal) => 
        sum + (Number(deal.amount) || 0), 0
      );

      // Split won deals into paid and unpaid
      const paidDeals = wonDeals.filter(deal => 
        deal.extra_fields?.Date_Time_Closed_Paid__c
      );
      const totalPaidValue = paidDeals.reduce((sum, deal) => 
        sum + (Number(deal.amount) || 0), 0
      );

      const unpaidDeals = wonDeals.filter(deal => 
        !deal.extra_fields?.Date_Time_Closed_Paid__c
      );
      const totalUnpaidValue = unpaidDeals.reduce((sum, deal) => 
        sum + (Number(deal.amount) || 0), 0
      );

      return {
        totalWonValue,
        totalPaidValue,
        totalUnpaidValue,
        wonCount: wonDeals.length,
        paidCount: paidDeals.length,
        unpaidCount: unpaidDeals.length
      };
    };

    const currentMetrics = calculatePeriodMetrics(deals?.currentPeriod);
    const prevMetrics = calculatePeriodMetrics(deals?.prevPeriod);

    // Calculate growth percentages
    const wonValueGrowth = calculateGrowth(currentMetrics.totalWonValue, prevMetrics.totalWonValue);
    const paidValueGrowth = calculateGrowth(currentMetrics.totalPaidValue, prevMetrics.totalPaidValue);
    const unpaidValueGrowth = calculateGrowth(currentMetrics.totalUnpaidValue, prevMetrics.totalUnpaidValue);

    // Debug logging
    console.log('Deals Metrics:', {
      segment: selectedSegment,
      current: currentMetrics,
      previous: prevMetrics,
      growth: {
        wonValue: wonValueGrowth,
        paidValue: paidValueGrowth,
        unpaidValue: unpaidValueGrowth
      }
    });

    return {
      ...currentMetrics,
      wonValueGrowth,
      paidValueGrowth,
      unpaidValueGrowth
    };
  }, [deals, selectedSegment]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  return (
    <VStack spacing={6} align="stretch">
      <Box pb={4}>
        <SimpleGrid columns={{ base: 1, sm: 1, md: 3 }} gap="20px">
          <StatCard
            icon={MdAttachMoney}
            title={`Total Won Value (${metrics.wonCount})`}
            value={formatCurrency(metrics.totalWonValue)}
            growth={metrics.wonValueGrowth}
            timeRange={selectedQuarter ? 'QoQ' : 'YoY'}
            primaryColor={PRIMARY_COLOR}
          />
          <StatCard
            icon={MdPayments}
            title={`Total Paid Value (${metrics.paidCount})`}
            value={formatCurrency(metrics.totalPaidValue)}
            growth={metrics.paidValueGrowth}
            timeRange={selectedQuarter ? 'QoQ' : 'YoY'}
            primaryColor={PRIMARY_COLOR}
          />
          <StatCard
            icon={MdPendingActions}
            title={`Total Unpaid Value (${metrics.unpaidCount})`}
            value={formatCurrency(metrics.totalUnpaidValue)}
            growth={metrics.unpaidValueGrowth}
            timeRange={selectedQuarter ? 'QoQ' : 'YoY'}
            primaryColor={PRIMARY_COLOR}
          />
        </SimpleGrid>
      </Box>

      <Card p="20px">
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          Unpaid Deals
        </Text>
        <UnpaidDealsTable 
          deals={deals?.currentPeriod} 
          selectedSegment={selectedSegment}
        />
      </Card>

      <Card p="20px">
        <DealCountByStageAndSourceBarCard 
          accountID={accountID}
          toast={toast}
          selectedYear={selectedYear}
          selectedQuarter={selectedQuarter}
          selectedSegment={selectedSegment}
        />
      </Card>

      <Card p="20px">
        <DealCountSinceLastActivityBarCard 
          accountID={accountID}
          toast={toast}
          selectedYear={selectedYear}
          selectedQuarter={selectedQuarter}
          selectedSegment={selectedSegment}
        />
      </Card>
    </VStack>
  );
};

export default SalesProcess; 
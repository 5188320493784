// src/components/Shared/DataTable.jsx

import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Box,
  Flex,
} from "@chakra-ui/react";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import HoverBox from "./HoverBox";
import HighlightText from "./HighlightText";
import PropTypes from "prop-types";

/**
 * DataTable Component
 * A reusable table component that supports sorting, searching, and custom column rendering.
 */
const DataTable = ({
  data,
  columns,
  onSort,
  sortConfig,
  searchQuery = "",
  onRowClick = null,
  rowIcon = null,
  hoverIcon = null,
  rowTooltip = "View Details",
  getRowColor = null,
}) => {
  /**
   * Renders the sort icon based on the current sort configuration.
   * @param {string} columnKey - The key of the column.
   * @returns {JSX.Element|null}
   */
  const renderSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) return null;
    if (sortConfig.direction === "ascending") {
      return <FaSortUp style={{ marginLeft: "4px" }} />;
    }
    return <FaSortDown style={{ marginLeft: "4px" }} />;
  };

  /**
   * Renders a cell's content based on the column configuration.
   * @param {Object} row - The row data.
   * @param {Object} column - The column configuration.
   * @returns {JSX.Element}
   */
  const renderCell = (row, column) => {
    const value = row[column.key];

    // If the column has a custom render function, use it
    if (column.render) {
      return column.render(row);
    }

    // If it's the first column and we have row click handling
    if (column.isFirst && onRowClick) {
      return (
        <Box width="100%" maxWidth="100%" overflow="hidden">
          <HoverBox
            icon={typeof rowIcon === 'function' ? rowIcon(row) : rowIcon}
            hoverIcon={hoverIcon}
            onClick={() => onRowClick(row)}
            tooltipLabel={rowTooltip || "View Details"}
          >
            <Text 
              fontSize="md"
              noOfLines={2}
              overflow="hidden"
              textOverflow="ellipsis"
              width="100%"
              pr="8px"
            >
              {column.format ? column.format(value) : value || "N/A"}
            </Text>
          </HoverBox>
        </Box>
      );
    }

    // For regular cells with potential dot indicators
    if (column.hasDot) {
      return (
        <Box display="flex" alignItems="center" fontSize="md" width="100%" overflow="hidden">
          <Box
            height="10px"
            width="10px"
            borderRadius="full"
            bg={getRowColor ? getRowColor(row, column.key) : "gray.400"}
            mr={2}
            flexShrink={0}
          />
          <Text 
            color="black" 
            isTruncated 
            maxWidth="calc(100% - 20px)"
            pr="8px"
          >
            <HighlightText
              text={column.format ? column.format(value) : value || "N/A"}
              query={searchQuery}
            />
          </Text>
        </Box>
      );
    }

    // For regular cells without dots
    return (
      <Box width="100%" overflow="hidden">
        <Text
          fontSize="md"
          isTruncated
          pr="8px"
        >
          <HighlightText
            text={column.format ? column.format(value) : value || "N/A"}
            query={searchQuery}
          />
        </Text>
      </Box>
    );
  };

  return (
    <Box bg="white" overflowX="auto">
      <Table variant="simple" bg="white" width="100%">
        <Thead bg="white">
          <Tr>
            {columns.map((column) => (
              <Th
                key={column.key}
                cursor={column.sortable ? "pointer" : "default"}
                onClick={() => column.sortable && onSort(column.key)}
                minWidth={column.minWidth || "100px"}
                maxWidth={column.maxWidth}
                width={column.width || "auto"}
                whiteSpace="nowrap"
                position="relative"
                _hover={column.sortable ? { bg: "gray.100" } : {}}
                role={column.sortable ? "group" : undefined}
              >
                <Flex align="center">
                  <Box mr={2}>{column.label}</Box>
                  {column.sortable && (
                    <Box
                      display="flex"
                      alignItems="center"
                      opacity={sortConfig.key === column.key ? 1 : 0}
                      transition="opacity 0.2s"
                      _groupHover={{ opacity: 1 }}
                    >
                      {renderSortIcon(column.key)}
                    </Box>
                  )}
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row, index) => (
            <Tr key={row.id || index} _hover={{ bg: "gray.50" }}>
              {columns.map((column) => (
                <Td 
                  key={column.key}
                  minWidth={column.minWidth || "100px"}
                  maxWidth={column.maxWidth}
                  width={column.width || "auto"}
                >
                  {renderCell(row, column)}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      width: PropTypes.string,
      minWidth: PropTypes.string,
      maxWidth: PropTypes.string,
      sortable: PropTypes.bool,
      isFirst: PropTypes.bool,
      hasDot: PropTypes.bool,
      format: PropTypes.func,
      render: PropTypes.func,
    })
  ).isRequired,
  onSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf(["ascending", "descending"]),
  }).isRequired,
  searchQuery: PropTypes.string,
  onRowClick: PropTypes.func,
  rowIcon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]),
  hoverIcon: PropTypes.elementType,
  rowTooltip: PropTypes.string,
  getRowColor: PropTypes.func,
};

export default DataTable;
import React from 'react';
import { Box, Text, Table, Thead, Tbody, Tr, Th, Td, Badge } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { MdBusiness, MdOpenInNew } from 'react-icons/md';
import HoverBox from '../../../Shared/HoverBox';

const filterDealsBySegment = (deals, segment) => {
  if (!segment || segment === "All") return deals;

  return deals.filter(deal => {
    const locations = deal.extra_fields?.No_of_Locations__c;
    if (locations === null || locations === undefined) return false;
    
    switch (segment) {
      case "SMB":
        return locations >= 1 && locations <= 20;
      case "Mid-Market":
        return locations > 20 && locations <= 200;
      case "Enterprise":
        return locations > 200;
      default:
        return true;
    }
  });
};

const RecentOpportunities = ({ deals, selectedYear, selectedQuarter, selectedSegment }) => {
  const navigate = useNavigate();

  const processedDeals = React.useMemo(() => {
    if (!deals?.currentPeriod || !Array.isArray(deals.currentPeriod)) {
      return [];
    }

    // Filter deals by segment first
    const filteredDeals = filterDealsBySegment(deals.currentPeriod, selectedSegment);

    // Filter deals by year and quarter
    const periodDeals = filteredDeals.filter(deal => {
      if (!deal?.close_date) return false;
      const date = new Date(deal.close_date);
      if (isNaN(date.getTime())) return false;

      const dealYear = date.getFullYear();
      if (dealYear !== selectedYear) return false;

      if (selectedQuarter) {
        const dealQuarter = Math.floor(date.getMonth() / 3) + 1;
        return dealQuarter === selectedQuarter;
      }
      return true;
    });

    return periodDeals
      .filter(deal => deal.is_closed)
      .map(deal => ({
        id: deal.id,
        name: deal.deal_name || 'Unnamed Deal',
        owner: deal.user_name ? `${deal.user_name} ${deal.user_last_name || ''}` : 'Unknown Owner',
        amount: Number(deal.amount) || 0,
        closeDate: new Date(deal.close_date),
        status: deal.is_won ? 'Won' : 'Lost',
        reason: deal.deal_summary || 'No reason provided'
      }))
      .sort((a, b) => b.closeDate - a.closeDate) // Sort by close date descending
      .slice(0, 5); // Show 5 most recent opportunities
  }, [deals, selectedYear, selectedQuarter, selectedSegment]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };

  const handleDealClick = (dealId) => {
    navigate(`/admin/deals/${dealId}`);
  };

  // Define fixed widths for each column
  const columnWidths = {
    deal_name: "30%",
    owner: "20%",
    amount: "20%",
    close_date: "15%",
    status: "15%"
  };

  return (
    <Box bg="white" p={4} borderRadius="md" boxShadow="sm" width="100%">
      <Text fontSize="lg" fontWeight="bold" mb={4}>
        Recent Opportunities {selectedQuarter ? `(Q${selectedQuarter} ${selectedYear})` : `(${selectedYear})`}
        {selectedSegment !== "All" ? ` - ${selectedSegment}` : ""}
      </Text>

      {processedDeals.length > 0 ? (
        <Box overflowX="auto" width="100%">
          <Table variant="simple" size="lg" width="100%">
            <Thead>
              <Tr>
                <Th width={columnWidths.deal_name}>Deal Name</Th>
                <Th width={columnWidths.owner}>Owner</Th>
                <Th width={columnWidths.amount} isNumeric>Amount</Th>
                <Th width={columnWidths.close_date}>Close Date</Th>
                <Th width={columnWidths.status}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {processedDeals.map((deal) => (
                <Tr key={deal.id} _hover={{ bg: "gray.50" }}>
                  <Td>
                    <HoverBox
                      icon={MdBusiness}
                      hoverIcon={MdOpenInNew}
                      onClick={() => handleDealClick(deal.id)}
                      tooltipLabel="View Deal Details"
                    >
                      <Text fontSize="md" isTruncated maxWidth="100%">
                        {deal.name}
                      </Text>
                    </HoverBox>
                  </Td>
                  <Td>
                    <Text fontSize="md" isTruncated>
                      {deal.owner}
                    </Text>
                  </Td>
                  <Td isNumeric>
                    <Text fontSize="md" fontWeight="500">
                      {formatCurrency(deal.amount)}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="md">
                      {formatDate(deal.closeDate)}
                    </Text>
                  </Td>
                  <Td>
                    <Badge
                      colorScheme={deal.status === 'Won' ? 'green' : 'red'}
                      px={2}
                      py={1}
                      borderRadius="full"
                    >
                      {deal.status}
                    </Badge>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ) : (
        <Text textAlign="center" py={8} color="gray.500">
          No recent opportunities found {selectedQuarter ? `in Q${selectedQuarter} ${selectedYear}` : `in ${selectedYear}`}
          {selectedSegment !== "All" ? ` for ${selectedSegment} segment` : ""}
        </Text>
      )}
    </Box>
  );
};

export default RecentOpportunities;
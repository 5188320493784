// src/views/lysto/Dashboard/components/Sales Performance/TopDealsChart.js

import React, { useState, useEffect, useMemo } from "react";
import { 
  Box, 
  Text, 
} from "@chakra-ui/react";
import { MdCalendarToday } from "react-icons/md";
import BarCard from "../BarCard";
import { CHART_COLORS } from "../../constants";

// Define month names and their corresponding numbers
const MONTHS = [
  { label: "Jan", value: "Jan", number: 0 },
  { label: "Feb", value: "Feb", number: 1 },
  { label: "Mar", value: "Mar", number: 2 },
  { label: "Apr", value: "Apr", number: 3 },
  { label: "May", value: "May", number: 4 },
  { label: "Jun", value: "Jun", number: 5 },
  { label: "Jul", value: "Jul", number: 6 },
  { label: "Aug", value: "Aug", number: 7 },
  { label: "Sep", value: "Sep", number: 8 },
  { label: "Oct", value: "Oct", number: 9 },
  { label: "Nov", value: "Nov", number: 10 },
  { label: "Dec", value: "Dec", number: 11 },
];

const QUARTERS = [
  { label: "Q1", value: "Q1", months: [0, 1, 2] },
  { label: "Q2", value: "Q2", months: [3, 4, 5] },
  { label: "Q3", value: "Q3", months: [6, 7, 8] },
  { label: "Q4", value: "Q4", months: [9, 10, 11] },
];

const isDateInYear = (dateStr, year) => {
  const date = new Date(dateStr);
  return date.getFullYear() === year;
};

const isDateInQuarter = (dateStr, year, quarter) => {
  const date = new Date(dateStr);
  return date.getFullYear() === year && Math.floor(date.getMonth() / 3) + 1 === quarter;
};

const TopDealsChart = ({ 
  deals, 
  timeFilter, 
  setTimeFilter, 
  selectedYear,
  selectedQuarter,
  selectedSegment,
  periodRange 
}) => {
  const [barData, setBarData] = useState(null);

  // Filter deals by segment
  const filterDealsBySegment = (deals) => {
    if (!selectedSegment || selectedSegment === "All") return deals;

    return deals.filter(deal => {
      const locations = deal.extra_fields?.No_of_Locations__c;
      if (locations === null || locations === undefined) return false;
      
      switch (selectedSegment) {
        case "SMB":
          return locations >= 1 && locations <= 20;
        case "Mid-Market":
          return locations > 20 && locations <= 200;
        case "Enterprise":
          return locations > 200;
        default:
          return true;
      }
    });
  };

  // Get available time filters based on the selected period
  const timeFilterOptions = useMemo(() => {
    if (selectedQuarter) {
      // If a quarter is selected, only show months within that quarter
      const quarterMonths = QUARTERS[selectedQuarter - 1].months;
      return [
        { label: `Q${selectedQuarter}`, value: `Q${selectedQuarter}`, icon: MdCalendarToday },
        ...MONTHS
          .filter(month => quarterMonths.includes(month.number))
          .map(month => ({
            label: month.label,
            value: month.value,
            icon: MdCalendarToday,
          }))
      ];
    } else {
      // If viewing full year, show all quarters and months
      const allYearOption = { label: "Full Year", value: "all_year", icon: MdCalendarToday };
      const quarters = QUARTERS.map(q => ({
        label: q.label,
        value: q.value,
        icon: MdCalendarToday,
      }));
      return [allYearOption, ...quarters];
    }
  }, [selectedQuarter]);

  // Set initial time filter based on selected period
  useEffect(() => {
    if (selectedQuarter) {
      setTimeFilter(`Q${selectedQuarter}`);
    } else {
      setTimeFilter("all_year");
    }
  }, [selectedQuarter, setTimeFilter]);

  useEffect(() => {
    // Filter deals based on the selected time filter
    const filterDeals = () => {
      // Filter by year first
      let filteredDeals = deals.filter(deal => isDateInYear(deal.close_date, selectedYear));

      // Apply segment filter
      filteredDeals = filterDealsBySegment(filteredDeals);

      // If a specific month is selected
      if (timeFilter.length === 3) { // Month format: "Jan", "Feb", etc.
        const monthIndex = MONTHS.findIndex(m => m.value === timeFilter);
        if (monthIndex !== -1) {
          filteredDeals = filteredDeals.filter(deal => {
            const dealDate = new Date(deal.close_date);
            return dealDate.getMonth() === monthIndex;
          });
        }
      }
      // If a specific quarter is selected
      else if (timeFilter.startsWith('Q')) {
        const quarterNumber = parseInt(timeFilter[1]);
        filteredDeals = filteredDeals.filter(deal => 
          isDateInQuarter(deal.close_date, selectedYear, quarterNumber)
        );
      }

      return filteredDeals.filter(deal => deal.stage_name === "Closed-Paid" && deal.amount);
    };

    const filteredDeals = filterDeals();

    // Sort deals by amount and get top 5
    const topDeals = filteredDeals
      .sort((a, b) => Number(b.amount) - Number(a.amount))
      .slice(0, 5);

    const chartData = {
      title: "Top 5 Deals by Value",
      // Format deal names to be more readable
      labels: topDeals.map((deal) => {
        const name = deal.deal_name.replace(/-$/, ''); // Remove trailing dash
        const amount = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(deal.amount);
        return `${name}\n${amount}`;
      }),
      datasets: [
        {
          label: "Deal Amount",
          data: topDeals.map((deal) => Number(deal.amount)),
          backgroundColor: CHART_COLORS.slice(0, topDeals.length),
        },
      ],
    };

    setBarData(chartData);
  }, [deals, timeFilter, selectedYear, selectedQuarter, selectedSegment, periodRange]);

  // Calculate available time periods with data
  const availableTimeFilters = useMemo(() => {
    return timeFilterOptions.filter(option => {
      const startDate = new Date(periodRange.startDate);
      const endDate = new Date(periodRange.endDate);

      if (option.value.length === 3) { // Month
        const monthIndex = MONTHS.findIndex(m => m.value === option.value);
        if (monthIndex !== -1) {
          startDate.setMonth(monthIndex);
          endDate.setMonth(monthIndex + 1);
          endDate.setDate(0);
        }
      } else if (option.value.startsWith('Q')) {
        const quarterIndex = parseInt(option.value[1]) - 1;
        const quarterMonths = QUARTERS[quarterIndex].months;
        startDate.setMonth(quarterMonths[0]);
        endDate.setMonth(quarterMonths[2] + 1);
        endDate.setDate(0);
      }

      return deals.some(deal => {
        const dealDate = new Date(deal.close_date);
        return (
          deal.stage_name === "Closed-Paid" &&
          dealDate >= startDate &&
          dealDate <= endDate &&
          deal.amount
        );
      });
    });
  }, [deals, timeFilterOptions, periodRange]);

  return (
    <Box pb={4} w="100%">
      {barData && availableTimeFilters.length > 0 ? (
        <BarCard
          barData={barData}
          showLabels={false}
          useCurrencyFormat={true}
        />
      ) : (
        <Box textAlign="center" py={4}>
          <Text>No deals found in the selected time period</Text>
        </Box>
      )}
    </Box>
  );
};

export default TopDealsChart;

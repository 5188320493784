// src/views/lysto/Dashboard/components/Sales Performance/SalesPerformanceGrid.js

import React, { useState, useMemo } from 'react';
import { Box, SimpleGrid, VStack, Flex, Text } from "@chakra-ui/react";
import StatCard from "../StatCard";
import TopDealsChart from "./TopDealsChart";
import TopDealsTable from "./TopDealsTable";
import { MdAttachMoney } from "react-icons/md";

const isDateInYear = (dateStr, year) => {
  const date = new Date(dateStr);
  return date.getFullYear() === year;
};

const isDateInQuarter = (dateStr, year, quarter) => {
  const date = new Date(dateStr);
  return date.getFullYear() === year && Math.floor(date.getMonth() / 3) + 1 === quarter;
};

const filterDealsBySegment = (deals, segment) => {
  if (!segment || segment === "All") return deals;

  return deals.filter(deal => {
    const locations = deal.extra_fields?.No_of_Locations__c;
    if (locations === null || locations === undefined) return false;
    
    switch (segment) {
      case "SMB":
        return locations >= 1 && locations <= 20;
      case "Mid-Market":
        return locations > 20 && locations <= 200;
      case "Enterprise":
        return locations > 200;
      default:
        return true;
    }
  });
};

const calculateMetrics = (currentDeals = [], prevDeals = [], selectedYear, selectedQuarter = null, selectedSegment = "All") => {
  // Filter deals by year/quarter first
  const filteredCurrentDeals = currentDeals.filter(deal => 
    selectedQuarter 
      ? isDateInQuarter(deal.close_date, selectedYear, selectedQuarter)
      : isDateInYear(deal.close_date, selectedYear)
  );
  
  const filteredPrevDeals = prevDeals.filter(deal => 
    selectedQuarter 
      ? isDateInQuarter(deal.close_date, selectedYear - 1, selectedQuarter)
      : isDateInYear(deal.close_date, selectedYear - 1)
  );

  // Apply segment filter
  const segmentFilteredCurrentDeals = filterDealsBySegment(filteredCurrentDeals, selectedSegment);
  const segmentFilteredPrevDeals = filterDealsBySegment(filteredPrevDeals, selectedSegment);

  // Filter for only closed and won deals
  const currentWonDeals = segmentFilteredCurrentDeals.filter(deal => deal.is_closed && deal.is_won);
  const prevWonDeals = segmentFilteredPrevDeals.filter(deal => deal.is_closed && deal.is_won);

  const currentTotal = currentWonDeals.reduce((sum, deal) => sum + (Number(deal.amount) || 0), 0);
  const prevTotal = prevWonDeals.reduce((sum, deal) => sum + (Number(deal.amount) || 0), 0);

  // Calculate growth percentage
  let growth = 0;
  if (prevTotal > 0) {
    growth = ((currentTotal - prevTotal) / prevTotal) * 100;
  } else if (prevTotal === 0 && currentTotal === 0) {
    growth = 0;
  } else if (prevTotal === 0 && currentTotal > 0) {
    growth = 100;
  }

  return {
    value: currentTotal,
    growth,
    numDeals: currentWonDeals.length,
    compareTotal: prevTotal,
    compareNumDeals: prevWonDeals.length
  };
};

const getCurrentQuarter = (date = new Date()) => {
  return Math.floor(date.getMonth() / 3) + 1;
};

const SalesPerformanceGrid = ({ 
  deals, 
  selectedYear, 
  selectedQuarter,
  selectedSegment,
  currentPeriodRange
}) => {
  const [timeFilter, setTimeFilter] = useState(selectedQuarter ? `Q${selectedQuarter}` : "all_year");
  const PRIMARY_COLOR = "#1A5BF6";

  // Calculate metrics for different comparisons
  const yearMetrics = useMemo(() => {
    if (selectedQuarter) return null;
    return calculateMetrics(deals.currentPeriod, deals.prevPeriod, selectedYear, null, selectedSegment);
  }, [deals, selectedQuarter, selectedYear, selectedSegment]);

  const quarterMetrics = useMemo(() => {
    const currentDate = new Date();
    const currentQuarter = selectedQuarter || getCurrentQuarter(currentDate);
    
    // Filter deals for current quarter
    const currentQuarterDeals = deals.currentPeriod.filter(deal => {
      return isDateInQuarter(deal.close_date, selectedYear, currentQuarter);
    });

    // Filter deals for previous quarter
    const prevQuarterDeals = deals.prevPeriod.filter(deal => {
      const prevQuarter = currentQuarter === 1 ? 4 : currentQuarter - 1;
      const prevYear = currentQuarter === 1 ? selectedYear - 1 : selectedYear;
      return isDateInQuarter(deal.close_date, prevYear, prevQuarter);
    });

    return calculateMetrics(currentQuarterDeals, prevQuarterDeals, selectedYear, currentQuarter, selectedSegment);
  }, [deals, selectedQuarter, selectedYear, selectedSegment]);

  const sameQuarterLastYearMetrics = useMemo(() => {
    const currentDate = new Date();
    const currentQuarter = selectedQuarter || getCurrentQuarter(currentDate);
    
    return calculateMetrics(
      deals.currentPeriod,
      deals.prevPeriod,
      selectedYear,
      currentQuarter,
      selectedSegment
    );
  }, [deals, selectedQuarter, selectedYear, selectedSegment]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  // Format comparison text for tooltips
  const getComparisonText = (metrics, comparisonType) => {
    const currentValue = formatCurrency(metrics.value);
    const compareValue = formatCurrency(metrics.compareTotal);
    let comparisonText;
    
    const prevQuarter = selectedQuarter === 1 ? 4 : selectedQuarter - 1;
    const prevQuarterYear = selectedQuarter === 1 ? selectedYear - 1 : selectedYear;
    
    switch (comparisonType) {
      case 'year':
        comparisonText = `${currentValue} vs ${compareValue} (${selectedYear - 1})`;
        break;
      case 'quarter':
        comparisonText = `${currentValue} vs ${compareValue} (Q${prevQuarter} ${prevQuarterYear})`;
        break;
      case 'sameQuarter':
        comparisonText = `${currentValue} vs ${compareValue} (Q${selectedQuarter || getCurrentQuarter()} ${selectedYear - 1})`;
        break;
      default:
        comparisonText = `${currentValue} vs ${compareValue}`;
    }
    
    return comparisonText;
  };

  return (
    <VStack spacing={6} align="stretch">
      <Box pb={4}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: selectedQuarter ? 2 : 3 }} gap="20px">
          {!selectedQuarter && yearMetrics && (
            <StatCard
              icon={MdAttachMoney}
              title={`Annual Sales ${selectedYear} (${yearMetrics.numDeals})`}
              value={formatCurrency(yearMetrics.value)}
              growth={yearMetrics.growth}
              timeRange="YoY"
              primaryColor={PRIMARY_COLOR}
              tooltip={getComparisonText(yearMetrics, 'year')}
            />
          )}
          <StatCard
            icon={MdAttachMoney}
            title={`Q${selectedQuarter || getCurrentQuarter()} vs Previous Quarter (${quarterMetrics.numDeals})`}
            value={formatCurrency(quarterMetrics.value)}
            growth={quarterMetrics.growth}
            timeRange="QoQ"
            primaryColor={PRIMARY_COLOR}
            tooltip={getComparisonText(quarterMetrics, 'quarter')}
          />
          <StatCard
            icon={MdAttachMoney}
            title={`Q${selectedQuarter || getCurrentQuarter()} vs Same Quarter Last Year (${sameQuarterLastYearMetrics.numDeals})`}
            value={formatCurrency(sameQuarterLastYearMetrics.value)}
            growth={sameQuarterLastYearMetrics.growth}
            timeRange="YoY"
            primaryColor={PRIMARY_COLOR}
            tooltip={getComparisonText(sameQuarterLastYearMetrics, 'sameQuarter')}
          />
        </SimpleGrid>
      </Box>

      <Box bg="white" p={4} borderRadius="lg" borderWidth="1px">
        <TopDealsChart 
          deals={deals.currentPeriod} 
          timeFilter={timeFilter}
          setTimeFilter={setTimeFilter}
          selectedYear={selectedYear}
          selectedQuarter={selectedQuarter}
          selectedSegment={selectedSegment}
          periodRange={currentPeriodRange}
        />
      </Box>

      <Box bg="white" p={4} borderRadius="lg" borderWidth="1px">
        <Flex justify="space-between" align="center" mb={4}>
          <Text fontSize="lg" fontWeight="bold">
            Top 10 Closed-Won Deals ({selectedQuarter ? `Q${selectedQuarter} ` : ''}{selectedYear})
          </Text>
        </Flex>
        <TopDealsTable 
          deals={deals.currentPeriod}
          timeFilter={timeFilter}
          limit={10}
          selectedYear={selectedYear}
          selectedQuarter={selectedQuarter}
          selectedSegment={selectedSegment}
          periodRange={currentPeriodRange}
        />
      </Box>
    </VStack>
  );
};

export default SalesPerformanceGrid;

// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Spark/index.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';
import { useAuthData } from 'auth-context';
import PageHeader from '../Shared/PageHeader';
import TableSkeleton from '../Shared/TableSkeleton';
import useFetchSparks from '../hooks/useFetchSparks';
import SparkCard from './SparkCard';
import PaginationControls from '../Shared/PaginationControls';

const ITEMS_PER_PAGE = 10;

const Spark = () => {
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const userID = userData?.user_ID;

  const [currentFilter, setCurrentFilter] = useState("recommended");
  const [currentPage, setCurrentPage] = useState(1);

  const { 
    sparks, 
    loading, 
    error, 
    hasMore, 
    totalCount,
    handleAddDeal, 
    handleIgnoreSpark 
  } = useFetchSparks(
    accountID, 
    userID, 
    currentPage, 
    ITEMS_PER_PAGE,
    currentFilter
  );

  // Reset page when filter changes
  useEffect(() => {
    setCurrentPage(1);
  }, [currentFilter]);

  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE) || 1;

  const handleNextPage = () => {
    if (hasMore) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const segmentOptions = [
    { label: "Recommended", value: "recommended" },
    { label: "All", value: "all" },
  ];

  if (loading) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
        <TableSkeleton rowCount={6} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} px={6}>
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={6}>
      <PageHeader
        title="Spark"
        segmentOptions={segmentOptions}
        selectedSegment={currentFilter}
        onSegmentChange={setCurrentFilter}
        showSearch={false}
      />

      {sparks.length === 0 ? (
        <Alert status="info" borderRadius="md">
          <AlertIcon />
          <AlertDescription>
            No potential deals found matching your criteria.
          </AlertDescription>
        </Alert>
      ) : (
        <SimpleGrid 
          columns={{ base: 1, lg: 2 }} 
          spacing={6}
          maxW="1600px"
          mx="auto"
        >
          {sparks.map((spark) => (
            <SparkCard
              key={spark.user_id}
              spark={spark}
              onAddDeal={handleAddDeal}
              onIgnore={handleIgnoreSpark}
            />
          ))}
        </SimpleGrid>
      )}

      {sparks.length > 0 && (
        <Box mt={6}>
          <PaginationControls
            currentPage={currentPage}
            hasNextPage={hasMore}
            onPrevious={handlePreviousPage}
            onNext={handleNextPage}
            disableNext={!hasMore}
            disablePrevious={currentPage === 1}
            totalPages={totalPages}
          />
        </Box>
      )}
    </Box>
  );
};

export default Spark;
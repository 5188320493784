import React from 'react';
import { Box, Text, SimpleGrid } from "@chakra-ui/react";
import ApexCharts from 'react-apexcharts';

const filterDealsBySegment = (deals, segment) => {
  if (!segment || segment === "All") return deals;

  return deals.filter(deal => {
    const locations = deal.extra_fields?.No_of_Locations__c;
    if (locations === null || locations === undefined) return false;
    
    switch (segment) {
      case "SMB":
        return locations >= 1 && locations <= 20;
      case "Mid-Market":
        return locations > 20 && locations <= 200;
      case "Enterprise":
        return locations > 200;
      default:
        return true;
    }
  });
};

const WinRateByRepColumn = ({ repStats, primaryColor, secondaryColor, title = "" }) => {
  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false
      },
      animations: {
        enabled: true
      },
      background: 'transparent'
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 6,
        barHeight: '65%'
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: repStats.map(stat => stat.rep),
      labels: {
        formatter: function (val) {
          return Math.abs(Math.round(val));
        }
      },
      title: {
        text: 'Number of Deals'
      }
    },
    yaxis: {
      title: {
        text: undefined
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (val) {
          return Math.abs(Math.round(val)) + " deals";
        }
      }
    },
    colors: [primaryColor, secondaryColor],
    fill: {
      opacity: 1
    },
    legend: {
      show: title === "", // Only show legend in the first chart
      position: 'top',
      horizontalAlign: 'left'
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.abs(Math.round(val));
      },
      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
      background: {
        enabled: true,
        foreColor: '#304758',
        padding: 4,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: '#fff',
      }
    },
    annotations: {
      position: 'front',
      points: repStats.map((stat) => ({
        x: stat.totalDeals,
        y: stat.rep,
        marker: {
          size: 0
        },
        label: {
          borderColor: 'transparent',
          borderRadius: 0,
          style: {
            fontSize: '13px',
            fontWeight: 600,
            color: '#666',
            background: 'transparent'
          },
          offsetX: 17,
          offsetY: 10,
          text: `${stat.winRate.toFixed(0)}%`,
          position: 'center'
        }
      }))
    }
  };

  const series = [
    {
      name: 'Won',
      data: repStats.map(stat => stat.wonDeals)
    },
    {
      name: 'Lost',
      data: repStats.map(stat => stat.lostDeals)
    }
  ];

  return (
    <Box>
      {title && <Text fontSize="lg" fontWeight="bold" mb={4}>{title}</Text>}
      <ApexCharts 
        options={chartOptions} 
        series={series} 
        type="bar" 
        height={Math.max(350, repStats.length * 45)}
      />
    </Box>
  );
};

const WinRateBySalesRep = ({ deals, primaryColor, secondaryColor, selectedYear, selectedQuarter, selectedSegment }) => {
  // Calculate statistics by rep
  const repStats = React.useMemo(() => {
    if (!deals?.currentPeriod) {
      console.warn('No deals data available for processing');
      return [];
    }

    // Filter deals by segment first
    const filteredDeals = filterDealsBySegment(deals.currentPeriod, selectedSegment);

    // Filter deals by year and quarter
    const periodDeals = filteredDeals.filter(deal => {
      if (!deal?.close_date) return false;
      const date = new Date(deal.close_date);
      if (isNaN(date.getTime())) return false;

      const dealYear = date.getFullYear();
      if (dealYear !== selectedYear) return false;

      if (selectedQuarter) {
        const dealQuarter = Math.floor(date.getMonth() / 3) + 1;
        return dealQuarter === selectedQuarter;
      }
      return true;
    });
    
    const stats = {};
    
    periodDeals.forEach(deal => {
      const repName = `${deal.user_name} ${deal.user_last_name}`;
      if (!stats[repName]) {
        stats[repName] = {
          wonDeals: 0,
          lostDeals: 0,
          totalDeals: 0
        };
      }
      stats[repName].totalDeals += 1;
      if (deal.is_won) {
        stats[repName].wonDeals += 1;
      } else {
        stats[repName].lostDeals += 1;
      }
    });

    const processedStats = Object.entries(stats)
      .map(([rep, data]) => ({
        rep,
        wonDeals: data.wonDeals,
        lostDeals: data.lostDeals,
        totalDeals: data.totalDeals,
        winRate: (data.wonDeals / data.totalDeals) * 100
      }))
      .sort((a, b) => b.totalDeals - a.totalDeals);

    return processedStats;
  }, [deals, selectedYear, selectedQuarter, selectedSegment]);

  // Split data into two columns
  const midpoint = Math.ceil(repStats.length / 2);
  const firstHalf = repStats.slice(0, midpoint);
  const secondHalf = repStats.slice(midpoint);

  return (
    <Box bg="white" p={4} borderRadius="md" boxShadow="sm">
      <Text fontSize="lg" fontWeight="bold" mb={4}>
        Win Rate by Sales Rep {selectedQuarter ? `(Q${selectedQuarter} ${selectedYear})` : `(${selectedYear})`}
        {selectedSegment !== "All" ? ` - ${selectedSegment}` : ""}
      </Text>
      {repStats.length > 0 ? (
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          <WinRateByRepColumn 
            repStats={firstHalf}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          />
          <WinRateByRepColumn 
            repStats={secondHalf}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          />
        </SimpleGrid>
      ) : (
        <Text textAlign="center" py={8} color="gray.500">
          No sales rep data available for {selectedQuarter ? `Q${selectedQuarter} ${selectedYear}` : selectedYear}
          {selectedSegment !== "All" ? ` in ${selectedSegment} segment` : ""}
        </Text>
      )}
    </Box>
  );
};

export default WinRateBySalesRep;
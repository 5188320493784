// src/views/lysto/Inbox/InboxTable.jsx

import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { MdMail, MdPhone } from "react-icons/md";
import { FaExpandAlt } from "react-icons/fa";
import PropTypes from "prop-types";
import DataTable from "../Shared/DataTable";
import { STATUS_MAP, TASK_MAP } from "./inboxMappings";
import { formatDistanceToNow } from "date-fns";
import HighlightText from "../Shared/HighlightText";

/**
 * Helper functions from your original implementation
 */
const removeDealFromSubject = (subject) => {
  if (!subject) return "";
  return subject.replace("For Deal ", "For ");
};

const formatStatus = (status) => {
  return STATUS_MAP[status]?.label || status.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
};

const formatTask = (taskType, taskSubtype) => {
  const capitalize = (str) => str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase() || "";
  const formattedTaskType = taskType ? capitalize(taskType) : "";
  const formattedTaskSubtype = taskSubtype ? capitalize(taskSubtype) : "";

  if (formattedTaskType && formattedTaskSubtype) {
    if (formattedTaskType.toLowerCase() === "follow up" || formattedTaskType.toLowerCase() === "follow-up") {
      return `Follow-up ${formattedTaskSubtype}`;
    }
    return `${formattedTaskType} ${formattedTaskSubtype}`;
  }
  return formattedTaskType || formattedTaskSubtype || "—";
};

const InboxTable = ({ inboxItems, onViewDetails, onSort, sortConfig, searchQuery = "" }) => {
  // Get the appropriate icon based on task type and subtype
  const getTaskIcon = (row) => {
    const taskType = row.task_type?.toLowerCase() || '';
    const taskSubtype = row.task_subtype?.toLowerCase() || '';

    if (taskType.includes('follow') && taskSubtype === 'call') {
      return MdPhone;
    }
    return MdMail;
  };

  // Define the columns configuration
  const columns = [
    {
      key: "subject",
      label: "Task Name",
      width: "50%",
      minWidth: "450px",
      sortable: true,
      isFirst: true,
       format: (value) => {
          const formattedValue = removeDealFromSubject(value);
          return <HighlightText text={formattedValue} query={searchQuery} />
       },
    },
    {
      key: "status",
      label: "Status",
      width: "15%",
      minWidth: "100px",
      sortable: true,
      hasDot: true,
      format: formatStatus,
    },
    {
      key: "task_type",
      label: "Task",
      width: "20%",
      minWidth: "150px",
      sortable: true,
      hasDot: true,
      render: (row) => {
        const formattedTask = formatTask(row.task_type, row.task_subtype);
        const taskColorScheme = TASK_MAP[formattedTask]?.color || "gray";
        return (
          <Box display="flex" alignItems="center" fontSize="md" width="100%" overflow="hidden">
            <Box
              height="10px"
              width="10px"
              borderRadius="full"
              bg={`${taskColorScheme}.500`}
              mr={2}
              flexShrink={0}
            />
            <Text color="black" isTruncated maxWidth="calc(100% - 20px)" pr="8px">
              <HighlightText text={formattedTask} query={searchQuery} />
            </Text>
          </Box>
        );
      },
    },
    {
      key: "last_activity_date",
      label: "Last Activity",
      width: "15%",
      minWidth: "120px",
      sortable: true,
      format: (value) => value ? formatDistanceToNow(new Date(value), { addSuffix: true }) : "—",
    },
  ];

  // Get color for status and task indicators
  const getRowColor = (row, columnKey) => {
    if (columnKey === "status") {
      return `${STATUS_MAP[row.status]?.color}.500` || "gray.500";
    }
    const formattedTask = formatTask(row.task_type, row.task_subtype);
    return `${TASK_MAP[formattedTask]?.color}.500` || "gray.500";
  };

  return (
    <DataTable
      data={inboxItems}
      columns={columns}
      onSort={onSort}
      sortConfig={sortConfig}
      searchQuery={searchQuery}
      onRowClick={onViewDetails}
      rowIcon={getTaskIcon}
      hoverIcon={FaExpandAlt}
      rowTooltip="View Task Details"
      getRowColor={getRowColor}
    />
  );
};

InboxTable.propTypes = {
  inboxItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  onViewDetails: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf(["ascending", "descending"]),
  }).isRequired,
  searchQuery: PropTypes.string,
};

export default InboxTable;
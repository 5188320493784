import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  VStack,
  Text,
  Flex,
} from '@chakra-ui/react';
import { 
  FaBuilding, 
  FaDollarSign, 
  FaClipboardList, 
  FaClock, 
  FaPlay, 
  FaFlagCheckered,
} from 'react-icons/fa';
import { useAuthData } from 'auth-context';
import { OverviewTag } from '../Shared/Tags';
import DealSummarySection from './DealSummarySection';
import DealActivity from './DealActivity';
import DealContactsSection from './DealContactsSection';
import DealAccountsSection from './DealAccountsSection';
import { parseISO, format } from 'date-fns';

const DealDetail = ({ deal }) => {
  const userData = useAuthData();
  const userAccountID = userData?.account_ID;

  const formattedLastActivityDate = deal.last_activity_date 
    ? format(parseISO(deal.last_activity_date), 'MMMM do, yyyy')
    : 'N/A';
  
  const formattedStartDate = deal?.created_date 
    ? format(parseISO(deal.created_date), 'MMMM do, yyyy') 
    : 'N/A';
  
  const formattedEndDate = deal?.close_date 
    ? format(parseISO(deal?.close_date), 'MMMM do, yyyy') 
    : 'N/A';

  // Check if the user's account ID is 4 (handles both string and number types)
  const shouldShowSections = Number(userAccountID) !== 4;

  return (
    <Box
      bg="transparent"
      p={0}
      borderRadius="md"
      boxShadow="none"
      mb={4}
      height="100%"
    >
      <VStack spacing={6} align="stretch" height="100%">
        {/* Card 1: Overview, Contacts, Accounts, and Summary */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            <Box>
              <Text fontSize="md" fontWeight="semibold" mb={3}>
                Overview
              </Text>
              <Flex gap={3} flexWrap="wrap">
                <OverviewTag
                  icon={FaBuilding}
                  label="Account"
                  value={deal.crm_account_name || 'Unknown Account'}
                  colorScheme="blue"
                />
                <OverviewTag
                  icon={FaClipboardList}
                  label="Deal Name"
                  value={deal.deal_name || 'Unnamed Deal'}
                  colorScheme="green"
                />
                <OverviewTag
                  icon={FaDollarSign}
                  label="Amount"
                  value={`$${deal.amount || '0'}`}
                  colorScheme="purple"
                />
                <OverviewTag
                  icon={FaClipboardList}
                  label="Stage"
                  value={deal.stage_name || 'N/A'}
                  colorScheme="orange"
                />
                <OverviewTag
                  icon={FaClock}
                  label="Last Activity"
                  value={formattedLastActivityDate}
                  colorScheme="teal"
                />
                <OverviewTag
                  icon={FaPlay}
                  label="Start"
                  value={formattedStartDate}
                  colorScheme="cyan"
                />
                <OverviewTag
                  icon={FaFlagCheckered}
                  label="End"
                  value={formattedEndDate}
                  colorScheme="red"
                />
              </Flex>
            </Box>
            {shouldShowSections && (
              <>
                <DealContactsSection contacts={deal.contacts || []} />
                <DealAccountsSection deal={deal} />
              </>
            )}
            <DealSummarySection
              summary={deal.deal_summary?.summary}
              shortSummary={deal.deal_summary?.short_summary}
            />
          </VStack>
        </Box>

        {/* Card 2: Deal Activity */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            <DealActivity accountID={deal.account_id} dealID={deal.id} />
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

DealDetail.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    account_id: PropTypes.number.isRequired,
    crm_account_name: PropTypes.string,
    deal_name: PropTypes.string,
    amount: PropTypes.string,
    stage_name: PropTypes.string,
    last_activity_date: PropTypes.string,
    extra_fields: PropTypes.objectOf(PropTypes.string),
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string,
      })
    ),
    accounts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
      })
    ),
    deal_summary: PropTypes.shape({
      summary: PropTypes.string,
      short_summary: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
    }),
  }).isRequired,
};

export default DealDetail;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  VStack,
  Text,
  Flex,
  ButtonGroup,
  Button,
  Circle,
  Image,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { OverviewTag } from '../Shared/Tags';
import { FaCalendar, FaCog } from 'react-icons/fa';

const ConnectionCard = ({ connection, type }) => {
  const getFormattedDate = (timestamp) => {
    try {
      if (!timestamp) return 'No date';
      
      // Handle Unix timestamp (Gmail)
      if (type === 'gmail') {
        // Check if timestamp is a number or string number
        const unixTimestamp = typeof timestamp === 'string' ? parseInt(timestamp, 10) : timestamp;
        if (isNaN(unixTimestamp)) return 'Invalid date';
        
        // Convert milliseconds to seconds if needed (if timestamp is too large)
        const adjustedTimestamp = unixTimestamp > 9999999999 
          ? Math.floor(unixTimestamp / 1000) 
          : unixTimestamp;
          
        const date = new Date(adjustedTimestamp * 1000);
        if (isNaN(date.getTime())) return 'Invalid date';
        return format(date, 'MMM d, yyyy h:mm a');
      }
      
      // Handle ISO string (Calendar)
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) return 'Invalid date';
      return format(date, 'MMM d, yyyy h:mm a');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  const isActive = type === 'gmail' 
    ? new Date((connection.expiration > 9999999999 
        ? Math.floor(connection.expiration / 1000) 
        : connection.expiration) * 1000) > new Date()
    : new Date(connection.expires_at) > new Date();

  const getStatusDetails = (isActive) => {
    if (isActive) {
      return { 
        icon: FaCalendar,
        label: "Status",
        value: "Active",
        colorScheme: "green"
      };
    }
    return { 
      icon: FaCalendar,
      label: "Status",
      value: "Inactive",
      colorScheme: "red"
    };
  };

  const statusDetails = getStatusDetails(isActive);
  const lastUpdated = type === 'gmail' 
    ? getFormattedDate(connection.expiration)
    : getFormattedDate(connection.expires_at);

  return (
    <Box
      bg="white"
      p={6}
      borderRadius="md"
      boxShadow="sm"
      borderWidth="1px"
      borderColor="gray.200"
      _hover={{
        borderColor: "gray.300",
        boxShadow: "md",
      }}
      transition="all 0.2s"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <VStack spacing={3} align="stretch" flex={1}>
        {/* Title Bar */}
        <Flex justify="space-between" align="center" mb={1}>
          <Flex align="center" gap={4}>
            <Circle
              size="56px"
              bg="gray.100"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Image
                src={type === 'gmail' 
                  ? 'https://www.gstatic.com/images/branding/product/2x/gmail_2020q4_32dp.png'
                  : 'https://www.gstatic.com/images/branding/product/2x/calendar_2020q4_32dp.png'
                }
                alt={type === 'gmail' ? 'Gmail' : 'Google Calendar'}
                width="32px"
                height="32px"
              />
            </Circle>
            <VStack align="start" spacing={0}>
              <Text fontSize="lg" fontWeight="semibold" color="gray.700">
                {connection.email}
              </Text>
              <Text fontSize="sm" color="gray.600">
                {type === 'gmail' ? 'Gmail' : 'Google Calendar'}
              </Text>
            </VStack>
          </Flex>
          <ButtonGroup size="sm" spacing={2}>
            <Button
              leftIcon={<FaCog />}
              variant="outline"
              colorScheme="gray"
              size="sm"
            >
              Edit
            </Button>
          </ButtonGroup>
        </Flex>

        {/* Overview Tags */}
        <Flex gap={3} flexWrap="wrap">
          <OverviewTag
            icon={statusDetails.icon}
            label={statusDetails.label}
            value={statusDetails.value}
            colorScheme={statusDetails.colorScheme}
          />
          <OverviewTag
            icon={FaCalendar}
            label="Last Updated"
            value={lastUpdated}
            colorScheme="purple"
          />
        </Flex>
      </VStack>
    </Box>
  );
};

ConnectionCard.propTypes = {
  connection: PropTypes.shape({
    email: PropTypes.string.isRequired,
    expiration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    expires_at: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  type: PropTypes.oneOf(['gmail', 'calendar']).isRequired,
};

export default ConnectionCard; 
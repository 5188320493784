// /Users/kylearchie/Documents/GitHub/lysto-fe/src/views/lysto/Inbox/index.jsx
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuthData } from "auth-context";
import InboxTable from "./InboxTable";
import PaginationControls from "../Shared/PaginationControls";
import PageHeader from "../Shared/PageHeader";
import TableSkeleton from "../Shared/TableSkeleton";
import { useFetchInbox } from "../hooks/useFetchInbox";
import { useDebounce } from "use-debounce";

const ITEMS_PER_PAGE = 10;

const Inbox = () => {
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const userID = userData?.user_ID || "";
  const role = userData?.role || "";
  const navigate = useNavigate();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  // Sorting state
  const [sortConfig, setSortConfig] = useState({ 
    key: 'last_activity_date', 
    direction: 'descending' 
  });

  // Filter states
  const [currentStatusFilter, setCurrentStatusFilter] = useState("to do");
  const [searchQuery, setSearchQuery] = useState("");

  // Memoize the statuses array to prevent unnecessary re-renders
  const statuses = useMemo(() => [currentStatusFilter], [currentStatusFilter]);

  // Debounce the search query by 300ms
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  // Fetch inbox items
  const { combinedItems = [], loading, error, hasMore } = useFetchInbox(
    accountID, 
    userID, 
    role,
    statuses,
    currentPage,
    ITEMS_PER_PAGE
  );

  // Filter inbox items based on currentStatusFilter and debouncedSearchQuery
  const filteredInboxItems = useMemo(() => {
    let filtered = combinedItems;

    // Status Filter
    if (currentStatusFilter !== "all") {
      filtered = filtered.filter((item) => item.status === currentStatusFilter);
    }

    // Search Filter
    if (debouncedSearchQuery.trim() !== "") {
      const query = debouncedSearchQuery.toLowerCase();
      filtered = filtered.filter(
        (item) =>
          (item.subject && item.subject.toLowerCase().includes(query)) ||
          (item.task_type && item.task_type.toLowerCase().includes(query)) ||
          (item.task_subtype && item.task_subtype.toLowerCase().includes(query)) ||
          (item.status && item.status.toLowerCase().includes(query))
      );
    }

    return filtered;
  }, [combinedItems, currentStatusFilter, debouncedSearchQuery]);

  // Sort inbox items
  const sortedInboxItems = useMemo(() => {
    if (!sortConfig.key) return filteredInboxItems;

    const sorted = [...filteredInboxItems].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle different data types
      if (typeof aValue === "string") aValue = aValue.toLowerCase();
      if (typeof bValue === "string") bValue = bValue.toLowerCase();

      if (typeof aValue === "number" && typeof bValue === "number") {
        return aValue - bValue;
      }
      if (Date.parse(aValue) && Date.parse(bValue)) {
        return new Date(aValue) - new Date(bValue);
      }
      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue);
      }
      return 0;
    });

    if (sortConfig.direction === "descending") {
      sorted.reverse();
    }
    return sorted;
  }, [filteredInboxItems, sortConfig]);

  // Calculate totalPages based on totalCount
  const totalPages = useMemo(() => {
    return Math.ceil(filteredInboxItems.length / ITEMS_PER_PAGE) || 1;
  }, [filteredInboxItems.length]);

  // Get current page's inbox items
  const currentInboxItems = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return sortedInboxItems.slice(startIndex, endIndex);
  }, [currentPage, sortedInboxItems]);

  // Generate search suggestions based on unique subjects and filter them based on the search query
  const searchSuggestions = useMemo(() => {
    if (!combinedItems || debouncedSearchQuery.trim() === "") return [];

    // Create a map to ensure unique subjects
    const uniqueSubjectsMap = new Map();
    
    // Filter items that match the search query
    combinedItems.forEach((item) => {
      if (item.subject && 
          item.subject.toLowerCase().includes(debouncedSearchQuery.toLowerCase()) &&
          !uniqueSubjectsMap.has(item.subject)) {
        uniqueSubjectsMap.set(item.subject, item.id);
      }
    });

    // Convert the map entries to an array of suggestion objects
    return Array.from(uniqueSubjectsMap.entries())
      .map(([subject, id]) => ({
        id,
        label: subject,
      }))
      .slice(0, 10); // Limit to 10 suggestions
  }, [combinedItems, debouncedSearchQuery]);

  // Handle sorting
  const handleSort = (key) => {
    setSortConfig((prevState) => {
      if (prevState.key === key) {
        // Toggle direction
        return {
          key,
          direction: prevState.direction === "ascending" ? "descending" : "ascending",
        };
      } else {
        // New sort
        return { key, direction: "ascending" };
      }
    });
  };

  // Calculate total pages - remove since we're using hasMore
  const handleNextPage = () => {
    if (hasMore) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  /**
   * Handles the navigation to the inbox item details page.
   * @param {Object} item - The inbox item object.
   */
  const handleViewDetails = useCallback(
    (item) => {
      navigate(`/admin/inbox/${item.id}`);
    },
    [navigate]
  );

  /**
   * Handles suggestion selection by navigating to the selected item's detail view.
   * @param {string|number} itemId - The ID of the selected inbox item.
   */
  const handleSuggestionSelect = useCallback(
    (itemId) => {
      navigate(`/admin/inbox/${itemId}`);
    },
    [navigate]
  );

  const segmentOptions = [
    { label: "To Do", value: "to do" },
    { label: "Completed", value: "completed" },
    { label: "All", value: "all" },
  ];

  const handleStatusChange = (newStatus) => {
    setCurrentStatusFilter(newStatus);
  };

  const handleSearchChange = (newQuery) => {
    setSearchQuery(newQuery);
  };

  // Reset to first page if filters or search changes
  useEffect(() => {
    setCurrentPage(1);
  }, [currentStatusFilter, debouncedSearchQuery, sortConfig]);

  if (loading) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
        <TableSkeleton rowCount={10} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} p={4} color="red.500">
        <Text>Failed to load inbox items. Please try again later. {error}</Text>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
      <PageHeader
        title="Inbox"
        segmentOptions={segmentOptions}
        selectedSegment={currentStatusFilter}
        onSegmentChange={handleStatusChange}
        searchPlaceholder="Search inbox..."
        onSearchChange={handleSearchChange}
        searchSuggestions={searchSuggestions}
        searchQuery={searchQuery}
        onSuggestionSelect={handleSuggestionSelect}
      />

      {currentInboxItems.length > 0 ? (
        <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
          <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} bg="white">
            <InboxTable
              inboxItems={currentInboxItems}
              onViewDetails={handleViewDetails}
              onSort={handleSort}
              sortConfig={sortConfig}
              searchQuery={debouncedSearchQuery}
            />
          </Box>
        </SimpleGrid>
      ) : (
        <Box p={4}>
          <Text>No inbox items available.</Text>
        </Box>
      )}

      {/* Pagination Controls */}
      {currentInboxItems.length > 0 && (
        <PaginationControls
          currentPage={currentPage}
          hasNextPage={hasMore}
          onPrevious={handlePreviousPage}
          onNext={handleNextPage}
          disableNext={!hasMore}
          disablePrevious={currentPage === 1}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};

export default Inbox;
import React from "react";
import PropTypes from "prop-types";
import {
  VStack,
  Box,
  Text,
  Spinner,
  Flex,
} from '@chakra-ui/react';
import { ActivityItem } from "../Activities";
import PaginationControls from '../Shared/PaginationControls';

const ITEMS_PER_PAGE = 5;

const AccountActivity = ({ account }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  
  // Sort activities in reverse chronological order
  const sortedActivities = React.useMemo(() => {
    if (!account?.activities) return [];
    
    return [...account.activities].sort((a, b) => {
      const dateA = new Date(a.activity_date);
      const dateB = new Date(b.activity_date);
      return dateB - dateA; // Most recent first
    });
  }, [account?.activities]);

  // Calculate total pages
  const totalPages = React.useMemo(() => {
    return Math.ceil(sortedActivities.length / ITEMS_PER_PAGE) || 1;
  }, [sortedActivities.length]);

  // Get current page's activities
  const currentActivities = React.useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return sortedActivities.slice(startIndex, endIndex);
  }, [sortedActivities, currentPage]);

  // Pagination handlers
  const handlePreviousPage = () => {
    setCurrentPage(prev => Math.max(1, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(totalPages, prev + 1));
  };

  if (!account) {
    return (
      <Box>
        <Text fontSize="md" fontWeight="bold" mb={4}>Account Activities</Text>
        <Flex justify="center" align="center" minH="200px">
          <Spinner size="lg" />
        </Flex>
      </Box>
    );
  }

  if (!account.activities?.length) {
    return (
      <Box>
        <Text fontSize="md" fontWeight="bold" mb={4}>Account Activities</Text>
        <Text color="gray.500" textAlign="center" py={8}>
          No activities found for this account.
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <Text fontSize="md" fontWeight="bold" mb={4}>Account Activities</Text>
      
      {/* Activities List */}
      <VStack spacing={4} align="stretch" mb={4}>
        {currentActivities.map((activity) => (
          <ActivityItem key={activity.id} activity={activity} />
        ))}
      </VStack>

      {/* Pagination Controls */}
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPrevious={handlePreviousPage}
        onNext={handleNextPage}
      />
    </Box>
  );
};

AccountActivity.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number,
    activities: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      activity_type: PropTypes.string,
      activity_date: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      activity_data: PropTypes.object,
    })),
  }),
};

export default AccountActivity; 
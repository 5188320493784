// src/views/lysto/Meetings/MeetingNotes.js

import React from 'react';
import { Box, Divider, useColorModeValue } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; // For GitHub Flavored Markdown

const MeetingNotes = ({ notes }) => {
  const borderColor = useColorModeValue("gray.200", "gray.600");

  if (!notes || typeof notes !== 'string') {
    return null;
  }

  return (
    <Box
      maxHeight="600px"
      overflowY="auto"
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="md"
      p={4}
    >
      <ReactMarkdown 
        remarkPlugins={[remarkGfm]}
        components={{
          h1: ({...props}) => (
            <Box as="h1" fontSize="2xl" fontWeight="bold" my={4} {...props} />
          ),
          h2: ({...props}) => (
            <Box as="h2" fontSize="xl" fontWeight="bold" my={3} {...props} />
          ),
          h3: ({...props}) => (
            <Box as="h3" fontSize="lg" fontWeight="bold" my={2} {...props} />
          ),
          p: ({...props}) => (
            <Box as="p" color="gray.600" fontSize="sm" mb={2} {...props} />
          ),
          ul: ({...props}) => (
            <Box as="ul" pl={4} mb={2} {...props} />
          ),
          li: ({...props}) => (
            <Box as="li" color="gray.600" fontSize="sm" {...props} />
          ),
          hr: ({...props}) => (
            <Divider my={6} borderColor="gray.200" {...props} />
          ),
        }}
      >
        {notes}
      </ReactMarkdown>
    </Box>
  );
};

export default MeetingNotes;

// src/components/Contacts/ContactDealsSection.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import DealTag from './DealTag'; // Import the DealTag component

/**
 * ContactDealsSection Component
 * Displays a list of deals associated with a contact.
 *
 * @param {Object} props
 * @param {Array} props.deals - Array of deal objects.
 * @returns {JSX.Element}
 */
const ContactDealsSection = ({ deals }) => {
  const navigate = useNavigate();

  /**
   * Handles navigation to the deal details page.
   * @param {Object} deal - The deal object.
   */
  const handleViewDetails = (deal) => {
    navigate(`/admin/deals/${deal.id}`);
  };

  return (
    <Box>
      <Text fontSize="md" fontWeight="semibold" mb={3}>
        Deals
      </Text>
      {deals.length === 0 ? (
        <Text color="gray.500">No deals associated with this contact.</Text>
      ) : (
        <Wrap spacing={4}>
          {deals.map((deal) => (
            <WrapItem key={deal.id}>
<DealTag
  dealName={deal.deal_name}
  onClick={() => handleViewDetails(deal)}
/>
            </WrapItem>
          ))}
        </Wrap>
      )}
    </Box>
  );
};

ContactDealsSection.propTypes = {
  deals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      deal_name: PropTypes.string,
      last_activity_date: PropTypes.string,
    })
  ),
};

ContactDealsSection.defaultProps = {
  deals: [],
};

export default ContactDealsSection;

// src/components/OpportunityAmountsChart.js

import React, { useMemo } from "react";
import { Box, Text } from "@chakra-ui/react";
import Chart from "react-apexcharts";

const filterDealsBySegment = (deals, segment) => {
  if (!segment || segment === "All") return deals;

  return deals.filter(deal => {
    const locations = deal.extra_fields?.No_of_Locations__c;
    if (locations === null || locations === undefined) return false;
    
    switch (segment) {
      case "SMB":
        return locations >= 1 && locations <= 20;
      case "Mid-Market":
        return locations > 20 && locations <= 200;
      case "Enterprise":
        return locations > 200;
      default:
        return true;
    }
  });
};

/**
 * OpportunityAmountsChart Component
 * Displays a stacked bar chart showing opportunity amounts over time.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.deals - The deals data from the parent.
 * @param {string} props.primaryColor - Primary color for the chart.
 * @param {number} props.selectedYear - Selected year for filtering.
 * @param {number} props.selectedQuarter - Selected quarter for filtering (optional).
 * @param {string} props.selectedSegment - Selected segment for filtering.
 * @returns {JSX.Element} The rendered OpportunityAmountsChart component.
 */
const OpportunityAmountsChart = ({ deals, selectedYear, selectedQuarter, selectedSegment, primaryColor }) => {
  const chartData = useMemo(() => {
    if (!deals?.currentPeriod?.length) return null;

    // Filter deals by segment first
    const filteredDeals = filterDealsBySegment(deals.currentPeriod, selectedSegment);

    // Filter deals by year and quarter
    const periodDeals = filteredDeals.filter(deal => {
      if (!deal?.close_date) return false;
      const date = new Date(deal.close_date);
      if (isNaN(date.getTime())) return false;

      const dealYear = date.getFullYear();
      if (dealYear !== selectedYear) return false;

      if (selectedQuarter) {
        const dealQuarter = Math.floor(date.getMonth() / 3) + 1;
        return dealQuarter === selectedQuarter;
      }
      return true;
    });

    // Group deals by month
    const monthlyDeals = periodDeals.reduce((acc, deal) => {
      const date = new Date(deal.close_date);
      const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      const monthLabel = date.toLocaleString('default', { month: 'short' });
      
      if (!acc[monthKey]) {
        acc[monthKey] = {
          monthLabel,
          deals: []
        };
      }
      acc[monthKey].deals.push(deal);
      return acc;
    }, {});

    // Sort months and process data
    const processedData = Object.entries(monthlyDeals)
      .sort(([a], [b]) => a.localeCompare(b))
      .reduce((acc, [, month]) => {
        const wonAmount = month.deals
          .filter(deal => deal.is_won)
          .reduce((sum, deal) => sum + (Number(deal.amount) || 0), 0);
        const lostAmount = month.deals
          .filter(deal => !deal.is_won)
          .reduce((sum, deal) => sum + (Number(deal.amount) || 0), 0);
        const totalAmount = wonAmount + lostAmount;
        
        acc.data[0].push(Math.round(wonAmount / 1000)); // Convert to thousands
        acc.data[1].push(Math.round(lostAmount / 1000)); // Convert to thousands
        acc.options.push(month.monthLabel);
        acc.annotations.push(totalAmount ? `$${Math.round(totalAmount / 1000)}k` : '$0k');
        
        return acc;
      }, {
        title: `Opportunity Amounts Over Time ${selectedQuarter ? `(Q${selectedQuarter} ${selectedYear})` : `(${selectedYear})`}${selectedSegment !== "All" ? ` - ${selectedSegment}` : ""}`,
        labels: ["Won", "Lost"],
        options: [],
        data: [[], []],
        annotations: []
      });

    // Ensure we have at least one data point
    if (processedData.options.length === 0) {
      processedData.options = ['No Data'];
      processedData.data = [[0], [0]];
      processedData.annotations = ['$0k'];
    }

    return processedData;
  }, [deals, selectedYear, selectedQuarter, selectedSegment]);

  if (!chartData) {
    return (
      <Box
        bg="white"
        borderRadius="lg"
        p={6}
        boxShadow="sm"
        border="1px solid"
        borderColor="gray.100"
        height="450px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text>No data available for the selected period</Text>
      </Box>
    );
  }

  const chartOptions = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 4,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["transparent"],
    },
    xaxis: {
      categories: chartData.options,
      labels: {
        style: {
          colors: Array(chartData.options.length).fill("#718096"),
          fontSize: "12px",
        },
        rotate: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#718096",
          fontSize: "12px",
        },
        formatter: (value) => `$${value.toFixed(2)}k`
      },
      min: 0,
    },
    colors: [primaryColor || "#1A5BF6", "#E2E8F0"],
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
      labels: {
        colors: "#718096",
      },
    },
    grid: {
      borderColor: "#E2E8F0",
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    tooltip: {
      theme: "light",
      shared: true,
      intersect: false,
      y: {
        formatter: function (val) {
          return `$${val}k`;
        },
      },
    },
    annotations: {
      position: 'front',
      points: chartData.annotations.map((value, index) => ({
        x: chartData.options[index],
        y: chartData.data[0][index] + chartData.data[1][index],
        marker: {
          size: 0,
        },
        label: {
          borderColor: "transparent",
          offsetY: 0,
          style: {
            color: "#718096",
            background: "transparent",
          },
          text: value,
        },
      })),
    },
  };

  const series = chartData.labels.map((label, index) => ({
    name: label,
    data: chartData.data[index],
  }));

  return (
    <Box
      bg="white"
      borderRadius="lg"
      p={4}
      boxShadow="sm"
      border="1px solid"
      borderColor="gray.100"
    >
      <Text fontSize="xl" fontWeight="700" mb={3} color="gray.700">
        {chartData.title}
      </Text>
      <Chart
        options={chartOptions}
        series={series}
        type="bar"
        height={350}
      />
    </Box>
  );
};

export default OpportunityAmountsChart;
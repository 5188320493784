// src/views/lysto/Contacts/ContactDetailsPage.jsx

import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Spinner, Center } from "@chakra-ui/react";
import { useAuthData } from "auth-context";
import GoBackHeader from "../Shared/GoBackHeader";
import useFetchContactById from "./hooks/useFetchContactById";
import ContactDetail from "./ContactDetail";

const ContactDetailsPage = () => {
  const { contactId } = useParams();
  const navigate = useNavigate();
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";

  const { contact, isLoading, isError } = useFetchContactById(accountID, contactId);

  const handleGoBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (isError) {
    return (
      <Center h="100vh">
        <Box>Error fetching contact details.</Box>
      </Center>
    );
  }

  if (!contact) {
    return (
      <Center h="100vh">
        <Box>Contact not found.</Box>
      </Center>
    );
  }

  return (
    <Box pt={10}>
      <GoBackHeader
        onGoBack={handleGoBack}
        title={contact.name || "Contact Details"}
      />
      <ContactDetail contact={contact} />
    </Box>
  );
};

export default ContactDetailsPage;

// src/components/Win Loss Analysis/OpportunityCountChart.js
import React, { useMemo } from 'react';
import { Box, Text, Center } from "@chakra-ui/react";
import ApexCharts from 'react-apexcharts';

const filterDealsBySegment = (deals, segment) => {
  if (!segment || segment === "All") return deals;

  return deals.filter(deal => {
    const locations = deal.extra_fields?.No_of_Locations__c;
    if (locations === null || locations === undefined) return false;
    
    switch (segment) {
      case "SMB":
        return locations >= 1 && locations <= 20;
      case "Mid-Market":
        return locations > 20 && locations <= 200;
      case "Enterprise":
        return locations > 200;
      default:
        return true;
    }
  });
};

const OpportunityCountChart = ({ deals, selectedYear, selectedQuarter, selectedSegment, primaryColor, secondaryColor }) => {
  const opportunityCountData = useMemo(() => {
    if (!deals?.currentPeriod || !Array.isArray(deals.currentPeriod)) {
      return {
        title: `Opportunity Count ${selectedQuarter ? `(Q${selectedQuarter} ${selectedYear})` : `(${selectedYear})`}${selectedSegment !== "All" ? ` - ${selectedSegment}` : ""}`,
        labels: ['Won', 'Lost'],
        options: ['No Data'],
        data: [[0], [0]]
      };
    }

    // Filter deals by segment first
    const filteredDeals = filterDealsBySegment(deals.currentPeriod, selectedSegment);

    // Filter deals by year and quarter
    const periodDeals = filteredDeals.filter(deal => {
      if (!deal?.close_date) return false;
      const date = new Date(deal.close_date);
      if (isNaN(date.getTime())) return false;

      const dealYear = date.getFullYear();
      if (dealYear !== selectedYear) return false;

      if (selectedQuarter) {
        const dealQuarter = Math.floor(date.getMonth() / 3) + 1;
        return dealQuarter === selectedQuarter;
      }
      return true;
    });

    // Group deals by month
    const monthlyDeals = periodDeals.reduce((acc, deal) => {
      const date = new Date(deal.close_date);
      const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      const monthLabel = date.toLocaleString('default', { month: 'short' });
      
      if (!acc[monthKey]) {
        acc[monthKey] = {
          monthLabel,
          won: 0,
          lost: 0
        };
      }
      
      if (deal.is_won) {
        acc[monthKey].won++;
      } else if (deal.is_closed) {
        acc[monthKey].lost++;
      }
      
      return acc;
    }, {});

    // Convert to arrays for the chart
    const monthData = Object.entries(monthlyDeals)
      .sort(([a], [b]) => a.localeCompare(b))
      .reduce((acc, [, data]) => {
        acc.options.push(data.monthLabel);
        acc.wonCounts.push(data.won);
        acc.lostCounts.push(data.lost);
        return acc;
      }, { options: [], wonCounts: [], lostCounts: [] });

    return {
      title: `Opportunity Count ${selectedQuarter ? `(Q${selectedQuarter} ${selectedYear})` : `(${selectedYear})`}${selectedSegment !== "All" ? ` - ${selectedSegment}` : ""}`,
      labels: ['Won', 'Lost'],
      options: monthData.options.length > 0 ? monthData.options : ['No Data'],
      data: [
        monthData.wonCounts.length > 0 ? monthData.wonCounts : [0],
        monthData.lostCounts.length > 0 ? monthData.lostCounts : [0]
      ]
    };
  }, [deals, selectedYear, selectedQuarter, selectedSegment]);

  const { title, labels, options, data } = opportunityCountData;
  const [wonCounts, lostCounts] = data;
  const negativeLostCounts = lostCounts.map(count => -Math.abs(count));

  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        borderRadiusApplication: "end",
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Math.abs(Math.round(val));
          },
          style: {
            fontSize: '12px',
            colors: ['#fff'],
          },
          background: {
            enabled: true,
            foreColor: '#304758',
            padding: 4,
            borderRadius: 4,
            borderWidth: 1,
            borderColor: '#fff',
          }
        },
      },
    },
    colors: [primaryColor, secondaryColor],
    xaxis: {
      categories: options,
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return Math.abs(value);
        },
      },
      title: {
        text: 'Count',
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.abs(Math.round(val));
      },
      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
      background: {
        enabled: true,
        foreColor: '#304758',
        padding: 4,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: '#fff',
      }
    },
    tooltip: {
      y: {
        formatter: (value) => {
          return Math.abs(value);
        },
      },
    },
    fill: {
      opacity: 1,
    },
  };

  const series = [
    {
      name: labels[0],
      data: wonCounts,
    },
    {
      name: labels[1],
      data: negativeLostCounts,
    },
  ];

  return (
    <Box bg="white" p={4} borderRadius="md" boxShadow="sm">
      <Text fontSize="lg" fontWeight="bold" mb={4}>
        {title}
      </Text>
      {wonCounts.some(count => count > 0) || lostCounts.some(count => count > 0) ? (
        <ApexCharts options={chartOptions} series={series} type="bar" height={350} />
      ) : (
        <Center h="350px">
          <Text color="gray.500">No opportunity count data available for the selected period</Text>
        </Center>
      )}
    </Box>
  );
};

export default OpportunityCountChart;
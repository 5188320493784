// src/components/DealCountByStageAndSourceBarCard.js
import React from "react";
import { Box, Text, Select } from "@chakra-ui/react";
import BarCard from "../BarCard";
import { CHART_COLORS } from "../../constants";
import { useDealCountByStageAndSource } from "../../../hooks/useDealCountByStageAndSource";

/**
 * Helper function to capitalize the first letter of a string.
 *
 * @param {string} str - The string to capitalize.
 * @returns {string} The capitalized string.
 */
const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * DealCountByStageAndSourceBarCard Component
 * Displays deal count data by stage and source in a BarCard.
 *
 * @returns {JSX.Element} The rendered component.
 */
export default function DealCountByStageAndSourceBarCard({ 
  accountID, 
  toast, 
  selectedYear,
  selectedQuarter,
  selectedSegment 
}) {
  const {
    data,
    loading,
    error,
    leadSourceOptions,
    selectedLeadSource,
    setSelectedLeadSource
  } = useDealCountByStageAndSource(accountID, selectedYear, selectedQuarter, selectedSegment, toast);

  // Prevent rendering BarCard if data isn't ready or if there's an error
  if (loading) {
    return (
      <Box textAlign="center" py={10}>
        <Text>Loading deal count statistics...</Text>
      </Box>
    );
  }

  if (error || !data || !data.datasets || !leadSourceOptions || leadSourceOptions.length === 0) {
    return (
      <Box textAlign="center" py={10}>
        <Text color="red.500">
          {error
            ? "Failed to load deal count statistics."
            : "No deal count data available."}
        </Text>
      </Box>
    );
  }

  // Create the dropdown component
  const dropdown = (
    <Select
      width="200px"
      value={selectedLeadSource}
      onChange={(e) => setSelectedLeadSource(e.target.value)}
    >
      {leadSourceOptions.map((source) => (
        <option key={source} value={source}>
          {capitalizeFirstLetter(source)}
        </option>
      ))}
    </Select>
  );

  // Add colors to the datasets
  const barData = {
    ...data,
    datasets: data.datasets.map(dataset => ({
      ...dataset,
      backgroundColor: CHART_COLORS.slice(0, data.labels?.length || 0)
    }))
  };

  return (
    <Box pb={4} w="100%">
      <BarCard
        barData={barData}
        showLabels={false}
        additionalControls={dropdown}
      />
    </Box>
  );
}

import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 6 * 60 * 60 * 1000, // Data is considered fresh for 6 hours (6 * 60 * 60 * 1000 ms)
      cacheTime: 7 * 60 * 60 * 1000, // Cache is kept slightly longer than staleTime (7 hours)
      refetchOnWindowFocus: false, // Don't refetch on window focus
      retry: 1, // Retry failed requests only once
    },
  },
});
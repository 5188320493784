import React from 'react';
import { Box, Text } from "@chakra-ui/react";
import Chart from "react-apexcharts";

const filterDealsBySegment = (deals, segment) => {
  if (!segment || segment === "All") return deals;

  return deals.filter(deal => {
    const locations = deal.extra_fields?.No_of_Locations__c;
    if (locations === null || locations === undefined) return false;
    
    switch (segment) {
      case "SMB":
        return locations >= 1 && locations <= 20;
      case "Mid-Market":
        return locations > 20 && locations <= 200;
      case "Enterprise":
        return locations > 200;
      default:
        return true;
    }
  });
};

const WinRateByAge = ({ deals, selectedYear, selectedQuarter, selectedSegment, primaryColor, secondaryColor }) => {
  const chartData = React.useMemo(() => {
    if (!deals?.currentPeriod) {
      return {
        categories: [],
        wonDeals: [],
        lostDeals: [],
        winRates: []
      };
    }

    // Filter deals by segment first
    const filteredDeals = filterDealsBySegment(deals.currentPeriod, selectedSegment);

    // Filter deals by year and quarter
    const periodDeals = filteredDeals.filter(deal => {
      if (!deal?.close_date) return false;
      const date = new Date(deal.close_date);
      if (isNaN(date.getTime())) return false;

      const dealYear = date.getFullYear();
      if (dealYear !== selectedYear) return false;

      if (selectedQuarter) {
        const dealQuarter = Math.floor(date.getMonth() / 3) + 1;
        return dealQuarter === selectedQuarter;
      }
      return true;
    });

    // Define age ranges in days
    const ranges = [
      { min: 0, max: 7, label: '< 1 week' },
      { min: 7, max: 30, label: '1-4 weeks' },
      { min: 30, max: 90, label: '1-3 months' },
      { min: 90, max: 180, label: '3-6 months' },
      { min: 180, max: Infinity, label: '> 6 months' }
    ];

    // Group deals by age range
    const ageStats = ranges.reduce((acc, range) => {
      acc[range.label] = {
        wonDeals: 0,
        lostDeals: 0,
        totalDeals: 0
      };
      return acc;
    }, {});

    periodDeals.forEach(deal => {
      const createdDate = new Date(deal.created_at);
      const closedDate = new Date(deal.close_date);
      if (isNaN(createdDate.getTime()) || isNaN(closedDate.getTime())) return;

      const ageDays = Math.floor((closedDate - createdDate) / (1000 * 60 * 60 * 24));
      const range = ranges.find(r => ageDays >= r.min && ageDays < r.max);
      
      if (range) {
        ageStats[range.label].totalDeals += 1;
        if (deal.is_won) {
          ageStats[range.label].wonDeals += 1;
        } else {
          ageStats[range.label].lostDeals += 1;
        }
      }
    });

    // Process data
    const processedData = ranges.map(range => ({
      category: range.label,
      ...ageStats[range.label],
      winRate: ageStats[range.label].totalDeals > 0
        ? (ageStats[range.label].wonDeals / ageStats[range.label].totalDeals) * 100
        : 0
    }));

    return {
      categories: processedData.map(d => d.category),
      wonDeals: processedData.map(d => d.wonDeals),
      lostDeals: processedData.map(d => d.lostDeals),
      winRates: processedData.map(d => Math.round(d.winRate))
    };
  }, [deals, selectedYear, selectedQuarter, selectedSegment]);

  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 6
      }
    },
    colors: [primaryColor, secondaryColor],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.abs(val);
      }
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    yaxis: {
      title: {
        text: undefined
      }
    },
    tooltip: {
      shared: true,
      intersect: false
    },
    xaxis: {
      categories: chartData.categories,
      labels: {
        formatter: function (val) {
          return Math.abs(Math.round(val));
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    annotations: {
      position: 'front',
      points: chartData.categories.map((category, index) => ({
        x: chartData.wonDeals[index] + chartData.lostDeals[index],
        y: category,
        marker: {
          size: 0
        },
        label: {
          borderColor: 'transparent',
          style: {
            color: '#666',
            background: 'transparent'
          },
          offsetX: 10,
          text: `${chartData.winRates[index]}%`
        }
      }))
    }
  };

  const series = [
    {
      name: 'Won',
      data: chartData.wonDeals
    },
    {
      name: 'Lost',
      data: chartData.lostDeals
    }
  ];

  return (
    <Box bg="white" p={4} borderRadius="md" boxShadow="sm">
      <Text fontSize="lg" fontWeight="bold" mb={4}>
        Win Rate by Deal Age {selectedQuarter ? `(Q${selectedQuarter} ${selectedYear})` : `(${selectedYear})`}
        {selectedSegment !== "All" ? ` - ${selectedSegment}` : ""}
      </Text>
      {chartData.categories.length > 0 ? (
        <Chart
          options={chartOptions}
          series={series}
          type="bar"
          height={350}
        />
      ) : (
        <Text textAlign="center" py={8} color="gray.500">
          No deal age data available for {selectedQuarter ? `Q${selectedQuarter} ${selectedYear}` : selectedYear}
          {selectedSegment !== "All" ? ` in ${selectedSegment} segment` : ""}
        </Text>
      )}
    </Box>
  );
};

export default WinRateByAge;
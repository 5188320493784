// src/views/lysto/Shared/Tags.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from '@chakra-ui/react';
import BaseTag from './BaseTag';
import { MdTrackChanges } from 'react-icons/md';
import { FaExpandAlt, FaExternalLinkAlt } from 'react-icons/fa';
import HoverBox from './HoverBox';

export const OverviewTag = ({ icon: IconComponent, label, value, colorScheme = "gray" }) => {
  const isWebsite = label === "Website" && value !== "N/A";
  
  return (
    <BaseTag
      colorScheme={colorScheme}
      icon={IconComponent}
      tooltip={`${label}: ${value}`}
    >
      <Text fontSize="sm" fontWeight="medium">
        {label}: {isWebsite ? (
          <a 
            href={value.startsWith('http') ? value : `https://${value}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <HoverBox
              icon={IconComponent}
              hoverIcon={FaExternalLinkAlt}
              tooltipLabel="Open Website"
              onClick={(e) => e.preventDefault()} // Prevent double navigation
            >
              <Text
                as="span"
                fontWeight="semibold"
                color="blue.500"
                _hover={{ textDecoration: 'underline' }}
              >
                {value}
              </Text>
            </HoverBox>
          </a>
        ) : (
          <Text as="span" fontWeight="semibold">{value}</Text>
        )}
      </Text>
    </BaseTag>
  );
};

export const AttendeeTag = ({ attendee }) => {
  const getStatusStyles = () => {
    switch (attendee.response_status) {
      case 'accepted':
        return {
          colorScheme: 'green',
          statusText: 'Accepted'
        };
      case 'declined':
        return {
          colorScheme: 'red',
          statusText: 'Declined'
        };
      case 'tentative':
        return {
          colorScheme: 'purple',
          statusText: 'Tentative'
        };
      case 'needsAction':
      default:
        return {
          colorScheme: 'yellow',
          statusText: 'Pending'
        };
    }
  };

  const { colorScheme, statusText } = getStatusStyles();
  const role = attendee.organizer ? 'Organizer' : attendee.optional ? 'Optional' : 'Attendee';
  const displayName = attendee.name || attendee.email?.split('@')[0] || 'Unknown';
  const tooltipText = `${role} • ${statusText} • ${attendee.email}`;

  return (
    <BaseTag
      colorScheme={colorScheme}
      tooltip={tooltipText}
      mr={3}
      mb={3}
    >
      <Flex align="center">
        <Text fontSize="sm" fontWeight="medium">
          {displayName}
        </Text>
      </Flex>
    </BaseTag>
  );
};

export const DealTag = ({ deal, onClick }) => (
  <BaseTag
    icon={MdTrackChanges}
    hoverIcon={FaExpandAlt}
    colorScheme="gray"
    onClick={onClick}
    tooltip="View Deal Details"
  >
    <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
      {deal.name || 'Unnamed Deal'}
    </Text>
  </BaseTag>
);

OverviewTag.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  colorScheme: PropTypes.string,
};

AttendeeTag.propTypes = {
  attendee: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    response_status: PropTypes.string,
    organizer: PropTypes.bool,
    optional: PropTypes.bool,
  }).isRequired,
};

DealTag.propTypes = {
  deal: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default {
  OverviewTag,
  AttendeeTag,
  DealTag,
};
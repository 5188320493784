import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { Video, Link as LinkIcon, ExternalLink } from 'lucide-react';
import BaseTag from '../Shared/BaseTag';

const ExternalLinksSection = ({ meetingLink, recordingUrl }) => {
  return (
    <Box>
      <Text fontSize="md" fontWeight="semibold" color="gray.700" mb={4}>
        External Links
      </Text>
      <Flex gap={4} flexWrap="wrap">
        {meetingLink && (
          <a 
            href={meetingLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            role="button"
          >
            <BaseTag
              icon={Video}
              hoverIcon={ExternalLink}
              colorScheme="blue"
              tooltip="Open Meeting Link"
              onClick={(e) => e.preventDefault()} // Prevent double navigation
            >
              <Text fontSize="sm" fontWeight="medium">
                Google Meet
              </Text>
            </BaseTag>
          </a>
        )}
        {recordingUrl && (
          <a 
            href={recordingUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            role="button"
          >
            <BaseTag
              icon={LinkIcon}
              hoverIcon={ExternalLink}
              colorScheme="purple"
              tooltip="View Recording"
              onClick={(e) => e.preventDefault()} // Prevent double navigation
            >
              <Text fontSize="sm" fontWeight="medium">
                Recording
              </Text>
            </BaseTag>
          </a>
        )}
      </Flex>
    </Box>
  );
};

export default ExternalLinksSection;